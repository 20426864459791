//
// Item List
// --------------------------------------------------

.has-arrow() {
	a {
    &::after {
      .cb-glyphs();
      position: absolute;
      display:  block;
      bottom: 28px;
      right: 24px;
      color: @blue-accent2-corporate-color;
      width: 1em;
      height: 1em;
      line-height: 1em;
      content: "\e916";
    }
    &.cb-target-blank {
      &::after {
        content: "\e932";
      }
    }
  }
	.cb-item-meta {
		width: calc(100% - 66px);
	}
}

.cb-item-list {
	margin-bottom: 48px;
	.cb-item-list  {
		margin-bottom: 0!important;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		li {
			padding: 0;
			margin: 0;
			position: relative;
			.cb-item-icon  {
				line-height: 2em;
				.cb-glyph {
					float: left;
				}
				.cb-item-type {
					display: inline;
					margin-left: 12px;
					text-transform: uppercase;
					font-size: @font-size-small;
			    line-height: @line-height-small-base;
			    text-transform: uppercase;
				}
			}
			.cb-item-date {
				display: none;
				text-transform: uppercase;
				font-size: @font-size-small;
		    line-height: @line-height-small;
		    text-transform: uppercase;
		    padding: 20px 24px 0 24px;
		    li {
		    	width: 99%;
				 	color: @cb-base-font-color;
		    }
			}

			.cb-item-meta {
				li {
				 	color: @cb-base-font-color;
				 	font-size: 0.75rem;
					 line-height: 1.5em;
				}

				&.cb-item-meta-key {
					li {
						&:first-of-type {
							border:1px solid @gray4-corporate-color; 
							padding:0 8px
						}
						&:nth-of-type(2) {
							margin-left: 8px;
						}
						&:nth-of-type(2):not(:last-of-type){
							&::after{
								content: '';
								border-right: 1px solid @gray4-corporate-color;
								padding-left: 8px;
								margin-right: 8px;
								display: inline-block;
								width: 1px;
								position: relative;
								top: 1px;
								height: 12px;
							}
						}
					}
				}

				&:not(.cb-item-meta-key) {
					li:not(:last-child){
						&::after{
							content: '';
							border-right: 1px solid @gray4-corporate-color;
							padding-left: 8px;
							margin-right: 8px;
							display: inline-block;
							width: 1px;
							position: relative;
							top: 1px;
							height: 12px;
						}
					}
				}
			}
			.cb-item-title {
				.roboto-slab-bold();
			}
		}
	}
	&.cb-item-list-simple {
		background-color: @white-color;
		li {
			width: 100%;
			a {
		    position: relative;
				width: calc(100% - 40px);
		    display: block;
		    top: 1px;
		    left: 1px;
		    color: @blue-accent1-corporate-color;
		    &:hover,
		    &:active{
		    	color: @blue-accent1-corporate-color;
		    }
		    &::after {
		      .cb-glyphs();
		      position: absolute;
		      display:  block;
		      top: calc(50% - 9px);
		      right: -24px;
		      color: @blue-accent1-corporate-color;
		      width: 1em;
		      height: 1em;
		      line-height: 1em;
		      content: "\e916";
		    }
		    &.cb-target-blank {
		      &::after {
		        content: "\e932";
		      }
		    }
		  }

			border-bottom: solid 1px @gray-corporate-border;
  		.border-box();
			&:first-child {
				border-top: solid 4px @gray1-corporate-color;
			}
		}

		// simple attriblues
		.cb-item-icon {
			display: none;
		}
		.cb-item-image {
			display: none;
		}
		.cb-item-text {
			padding: 12px 0 11px 16px;
			.cb-item-title {
				padding: 0;
				margin: 0;
		    font-size: @font-size-base;
		    line-height: @line-height-base;
		    color: @blue-accent1-corporate-color;
			}

			.cb-item-des {
				display: none;
			}
			.cb-item-meta {
				display: none;
			}
		}
		li {
			&:first-child {
				.cb-item-text {
					padding-top: 8px;
				}
			}
		}
	}

	&.cb-item-list-showcase {
		& > ul {
			& > li {
		    border: 1px solid @gray-corporate-border;
  			.border-box();
		    margin: 0 15px 24px;
		    // desktop, 3
		    width: calc(33.333% - 30px);

		    // oversized, 4
		    @media (min-width: @screen-lg-min) {
		    	width: calc(25% - 30px);
		    }
		    // tablet, 2
		   	@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
					width: calc(50% - 20px);
					margin: 0 10px 24px;
		    	min-height: 336px;
		    }
		    // mobile, 1
		   	@media (max-width: @screen-xs-max) {
			    width: calc(100% - 15px);
			    margin: 0 7.5px 24px;
			    min-height: 0;
		    }

		    background: @white-color;
		    min-height: 312px;
		    a {
					width: 100%;
					height: 100%;
					display: inherit;
					position: relative;
		    }
			}
		}

		li {
			.cb-item-icon  {
				// (24 - 32(glyph) )/ 2 ) - 1 (border)
				padding: 19px 24px 0 24px;
			}

			.cb-item-image {
				margin-top: 20px;
				width: 100%;
				height: 144px;

		    // oversized, 4
		    @media (min-width: @screen-lg-min) {
					height: 120px;
		    }
		    // tablet, 2
		   	@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
		   		height: 144px;
		   	}
		    // mobile, 1
		   	@media (max-width: @screen-xs-max) {
		   		height: auto;
		    }
				overflow: hidden;
				img {
					width: 100%;
				}
			}

			.cb-item-date {
				li {
					line-height: 24px;
				}
			}
			.cb-item-date + .cb-item-text,
			.cb-item-image + .cb-item-text {
				padding-top: 24px;
			}

			.cb-item-text {
				// 24 - 4(glyph)
				padding: 20px 24px 24px 24px;
				.cb-item-title {
					margin: 0;
					font-size: @font-size-large;
					line-height: @line-height-large-base;
					color: @cb-base-font-color;
				}
				.cb-item-des {
					margin: 0;
					font-size: @font-size-small;
					line-height: @line-height-small-base;
					color: @cb-base-font-color;
				}
			}

			.cb-item-meta {
				width: calc(100% - 20px);
				padding: 0 24px 24px 24px;
				li {
					line-height: 2em;
				}
			}

			// Video
			&.cb-type-video {
				.cb-item-image {
					position: relative;
					.cb-play {
						position: absolute;
						top: calc(50% - 32px);
						left: calc(50% - 32px);
						&.cb-glyph-circular {
							&::after {
								background-color: @black-color;
								opacity: 0.6;
							}
						}
					}
				}
				.cb-item-des {
					display: none;
				}
			}

			// Article
			&.cb-type-article {
				.has-arrow();
				.cb-item-des {
					display: none;
				}

				.cb-item-meta {
					display: none;
				}
				.cb-item-title {
					margin-bottom: 24px;
				}
			}

			// Event
			&.cb-type-event {
				.has-arrow();
				.cb-item-date{
					display: block;
				}
				.cb-item-image,
				.cb-item-des {
					display: none;
				}
			}

			// Resource
			&.cb-type-resource {
				a {
			    &::after {
			      .cb-glyphs();
			      position: absolute;
			      display:  block;
			      bottom: 28px;
			      right: 24px;
			      color: @blue-accent2-corporate-color;
			      width: 1em;
			      height: 1em;
			      line-height: 1em;
			      content: "\e914";
			    }
			    &.batch-download {
			      &::after {
			        content: "\e902";
			      }
						.cb-item-meta {
							display: none;
						}
			    }
			    &.series {
			      &::after {
			        content: "\e943";
			      }
						.cb-item-meta {
							display: none;
						}
			    }
			  }
				.cb-item-meta {
					width: calc(100% - 66px);
				}
				.cb-item-image {
					display: none;
				}
				.cb-item-text {
					.cb-item-title {
						margin-bottom: 24px;
					}
				}
			}
		  &.cb-no-img {
		  	.cb-item-image {
		  		display: none;
		  	}
		  }
		  &.cb-no-des {
		  	.cb-item-des {
		  		display: none;
		  	}
		  	.cb-item-title {
		  		.roboto-slab-light();
		  		font-size: 1.4375rem;
		  		line-height: 1.0434782608695652em;
		  		font-weight: 300;
		  	}
		  }
		}
	}

	&.cb-item-list-rich{
		& > ul {
			& > li {
		    border-bottom: 1px solid @gray-corporate-border;
  			.border-box();
		    width: 100%;
		    padding: 24px 24px 23px 16px;
		    background: @white-color;
		    a {
					width: 100%;
					height: 100%;
					display: inherit;
					position: relative;
		    }
				&:first-child,
				&.cb-add-top-border {
		    	border-top: 4px solid @blue-accent1-corporate-color;
		    	padding-top: 20px;
		    }
		    .cb-item-icon {
					.cb-item-type {
						display: none;
					}
		    }
		    &.cb-batch-download-open {
		    	padding-bottom: 0;
		    }
			}
		}
		.cb-item-text {
			padding: 0;
			width: calc(100% - 32px);
			padding-left: 12px;
			display: inline-block;
			.cb-item-title {
				margin: 0;
				padding: 0;

				font-size: @font-size-large;
				line-height: @line-height-large-base;
				color: @cb-base-font-color;
			}
			.cb-item-des {
				margin: 0;
				font-size: @font-size-small;
				line-height: @line-height-small-base;
				color: @cb-base-font-color;
			}
		}
		.cb-item-meta {
			width: 100%;
			margin-top: 24px;
			padding: 0 0 0 44px;
			li {
				line-height: 2em;
			}
		}

		a {
			.cb-item-meta {
				position: relative;
		    &::after {
		      .cb-glyphs();
		      position: absolute;
		      display:  block;
		      right: 0;
		      color: @blue-accent2-corporate-color;
		      width: 1em;
		      height: 1em;
		      line-height: 24px;
		      content: "\e916";
		    }
			}
			.cb-target-blank {
				.cb-item-meta {
			    &::after {
			      content: "\e932";
			    }
				}
			}
		}

		// Video
		.cb-type-video {
			.cb-item-meta {
				&::after {
					content: "";
				}
			}
		}

		// Related
		.cb-type-related {
			.cb-item-meta {
				position: relative;
				min-height: 24px;
		    &::after {
		      content: "\e932";
		    }

				li {
			    &.cb-related-site {
			    	position: absolute;
			    	right: 28px;
			    }
				 	&:nth-last-child(2){
						&::after{
					    border-right: 0;
					    padding-left: 0;
					    margin-right: 0;
				 		}
				 	}
				}
			}
			.cb-glyph {
				&.cb-glyph-circular {
					&.cb-north-east {
						&::before{
							left: calc((1em / 2) - 1px);
							top: calc((1em / 2) + 1px);
						}
					}
				}
			}
		}

		// Event
		.cb-type-event {
			.cb-item-date {
				display: inline-block;
				width: calc(100% - 44px);
				padding: 0 0 0 12px;
				li {
					line-height: 24px;
				}
				margin-bottom: 24px;
			}
			.cb-item-text {
				padding-left: 44px;
				display: block;
			}
		}

		// Resource
		.cb-type-resource {
			.cb-item-meta {
				position: relative;
		    &::after {
		      content: "\e914";
		    }
		    &.series {
		    	&::after {
		      	content: "\e943";
		    	}
		    }
		    &.batch-download {
		    	&::after {
		      	content: "\e902";
		    	}
		    }
			}
			// batch download
			.cb-batch-resource {
				&::before {
					content: '';
				}
			  .cb-item-download {
			  	margin-top: 24px;
			  	padding-left: 44px;
			  	a {
			  		font-size: 0.75rem;
			  		line-height: 2em;
			  		float: left;
			  		width: auto;
			  		&.cb-see-more {
			  			float: right;
			  			&.open {
			          &::after {
			            content: "See Less -";
			          }
			        }
			        &::after{
			          content: "See More +";
			        }
			  		}
			  	}
			  }
				.cb-item-list {
					&.cb-item-list-rich {
					  width: calc(100% + 40px);
					  float: left;
						border-top: solid 1px @gray-corporate-border;
						margin-left: -16px;
  					.border-box();
					  & > ul {
					  	& > li {
					  		background-color: @digital-field-gray;
					  		padding-left: 24px;
					  		&:first-child {
					  			border-top: 0;
					  			padding-top: 24px;
					  		}
					  		&:last-child {
					  			border-bottom: 0;
					  		}
								.cb-item-meta {
									&::after {
		      					content: "\e914";
									}
								}
					  	}
					  }
					  &.collapse{
			        display: none;
			        visibility: hidden;
			        &.in{
			          display: block;
			          visibility: visible;
			          a {
			          	&:hover,
			          	&:active {
			          		text-decoration: none;
			          	}
			          }
			        }
			      }
					}
				}
			}
		}

		// Base color border top
		&.cb-border-top-base {
			& > ul {
				& > li {
					&:first-child,
					&.cb-add-top-border {
						border-top: 4px solid @gray1-corporate-color;
					}
					
				}
			}
		}
		&.cb-item-list-condensed{
			.cb-type-event {
				.cb-item-date {
					margin-bottom: 0;
				}
			}
		}
	}

	a {
		&:hover,
		&:active,
		&:focus{
			text-decoration: none;
			.cb-item-text {
				color: @cb-base-font-color;
				.cb-item-title {
					text-decoration: underline;
				}
			}
		}
	}

	// icon color
	.cb-glyph-circular {
		&::after {
			background-color: @gray1-corporate-color;
		}
	}

	.cb-batch-resource {
		.cb-glyph-circular {
			&::after {
				background-color: @blue-accent2-corporate-color;
			}
		}
	}

	a {
		.cb-glyph-circular {
			&::after {
				background-color: @blue-accent2-corporate-color;
			}
		}
	}
}

// modal with resource
.cb-item-list-modal {
  .modal-header{
    padding-bottom: 0;
  }
  .modal-body{
    padding-top: 0;
  }
  .modal-content button.close {
    float: left;
  }
}