//
// CB Login Module
// --------------------------------------------------

.cb-login-module {
	&:not(.cb-no-border) {
		padding: 24px;
		border: 1px solid @gray-corporate-border;
		background: @white-color;
	}
	color: @cb-base-font-color;
	.cb-login-module-header {
		display: flex;
		align-items: center;
		h2, h3, h4 {
			font-family: @font-family-RobotoSlab !important;
			font-weight: 300 !important;
			font-size: 1.438rem;
			line-height: 1.0434782608695652em;
			margin: 0 0 0 8px;
			flex: 1;
		}
		.cb-glyph-multi {
			min-width: 64px;
		}
		.cb-glyph {
			&.cb-glyph-circular {
				&::after {
			    background-color: @blue-accent1-corporate-color;
			  }
			}
		}
		margin-bottom: 24px;
	}
	.cb-login-module-body {
		margin-bottom: 24px;
		.cb-item-list {
			margin-bottom: 0;
		}
	}
	.cb-login-module-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			margin-bottom: 0!important;
		}
		&> a {
			display: flex;
			align-items: center;
			&:hover,
			&:focus {
				text-decoration: none!important;
				span:not(.cb-glyph) {
					text-decoration: underline;
				}
			}
			&:first-of-type {
				span {
					&:not(.cb-glyph) {
						margin-right: 24px;
					}
					&.cb-glyph {
						margin-right: 8px;
					}
				}
			}
		}
	}
	&.cb-logged-in {
		.cb-login-module-header {
			h2, h3 {
				flex: 1;
			}
			@media (max-width: @screen-xs-max) { // mobile
				.cb-glyph-multi,
				.cb-glyph {
					display: none;
					visibility: hidden;
				}
			}
		}
		.cb-login-module-footer {
			a {
				display: flex;
				align-items: center;
				&:hover,
				&:focus {
					text-decoration: none!important;
					span:not(.cb-glyph) {
						text-decoration: underline;
					}
				}
				&:first-of-type {
					span {
						&:not(.cb-glyph) {
							margin-right: 24px;
							margin-left: 8px;
						}
						&.cb-glyph {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}


// mobile
@media (max-width: @screen-xs-max) {
	.cb-login-module-footer {
		p {
			text-align: center;
			width: 100%;
		}
	}
	.cb-login-module-cta {
		&.cb-cta-card {
			margin-top: 24px;
		}
	}
}


// glyph color stand alone module

.col-xs-12 {
	&> .cb-login-module {
		.cb-glyph-multi {
	 		.glyph-multi-bk-color-imp (@white-color, @yellow-corporate-color, @blue-accent1-corporate-color);
	 	}
	}
}
.cb-login-module-cta {
	.cb-glyph-multi {
 		.glyph-multi-bk-color-imp (@white-color, @yellow-corporate-color, @blue-accent1-corporate-color);
 	}
}

