//
// Glyphicons
// --------------------------------------------------

[class^="cb-icon-"],
[class*=" cb-icon-"],
.glyphicon-cb,
.cb-glyph:not(.cb-glyph-numeric) {
  .cb-glyphs();
}

.cb-glyph-numeric {
  display: inline-block;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  .border-box();
  font-weight: 700;
  font-family: @font-family-Roboto;
  line-height: 1.82em!important;
  text-align: center;
  font-size: @font-size-base;
  border: solid .125em @cb-base-font-color;
  &::before {
    content: attr(data-cb-number);
  }
  &.cb-glyph-circular {
    color: @white-color;
    border-color: @blue-accent2-corporate-color;
    background-color: @blue-accent2-corporate-color;
  }
}

.cb-glyph,
.cb-glyph-numeric {
  // size
  &.cb-glyph-xs {
    font-size: @font-size-base;
  }
  &.cb-glyph-sm {
    font-size: calc(unit(@font-size-base, rem) * 2)!important;
  }
  &.cb-glyph-md {
    font-size: calc(unit(@font-size-base, rem) * 3)!important;
  }
  &.cb-glyph-lg {
    font-size: calc(unit(@font-size-base, rem) * 4)!important;
  }
  &.cb-glyph-xl {
    font-size: calc(unit(@font-size-base, rem) * 6)!important;
  }

  // color
  &.cb-glyph-corporate {
    .glyph-color(@blue-corporate-color);
  }
  &.cb-glyph-corporate2  {
    .glyph-color(@blue-accent1-corporate-color);
  }
  &.cb-glyph-k-12 {
    .glyph-color(@k-12-color);
  }
  &.cb-glyph-higher-education {
    .glyph-color(@higher-education-color);
  }
  &.cb-glyph-access {
    .glyph-color(@access-color);
  }
}


// type
.cb-glyph-circular:not(.cb-glyph-numeric) {
	position: relative;
	display: block;
	height: 2em;
	width: 2em;
  &::before {
  	position: absolute;
  	z-index: 2;
  	left: calc(1em / 2);
  	top: calc(1em / 2);
  	color: @white-color;
  }
  &::after {
  	content: " ";
  	position: absolute;
  	z-index: 1;
  	display: block;
  	border-radius: 50%;
  	height: 2em;
  	width: 2em;
  	background-color: @blue-accent2-corporate-color;
  }

  &.cb-glyph-corporate {
	  &::after {
	  	background-color: @blue-corporate-color;
	  }
  }

  &.cb-glyph-corporate2  {
    &::after {
      background-color: @blue-accent1-corporate-color;
    }
  }

  &.cb-glyph-k-12 {
	  &::after {
	  	background-color: @k-12-color;
	  }
  }

  &.cb-glyph-higher-education {
	  &::after {
	  	background-color: @higher-education-color;
	  }
  }

  &.cb-glyph-access {
	  &::after {
	  	background-color: @access-color;
	  }
  }
}


// Glyphs
.cb-icon-icn_online-tool,
.cb-online-tool {
  &::before {
    content: "\e936";
  }
}

.cb-icon-icn_download,
.cb-icon-icn_download_med,
.cb-icon-icn_download_small,
.cb-icon-icn_download_res,
.cb-download {
  &::before {
    content: "\e914";
  }
}

.cb-icon-icn_download_bulk,
.cb-batch-download {
  &::before {
    content: "\e902";
  }
}

.cb-icon-icn_local-menu,
.cb-menu-local {
  &::before {
    content: "\e925";
  }
}

.cb-icon-icn_filter,
.cb-filter {
  &::before {
    content: "\e91d";
  }
}

.cb-icon-icn_sort,
.cb-sort {
  &::before {
    content: "\e947";
  }
}

.cb-sort-asc {
  &::before {
    content: "\e950";
  }
}
.cb-sort-desc {
  &::before {
    content: "\e913";
  }
}

.cb-icon-icn_arrow-up,
.cb-icon-double_arrow_round,
.cb-icon-double_arrow,
.cb-up {
  &::before {
    content: "\e950";
  }
}

.cb-icon-icn_arrow-down,
.cb-down {
  &::before {
    content: "\e913";
  }
}

.cb-icon-icn_arrow-left,
.cb-left {
  &::before {
    content: "\e921";
  }
}

.cb-icon-icn_arrow-right,
.cb-right {
  &::before {
    content: "\e940";
  }
}


.cb-icon-icn_double-left-arrow,
.cb-dbl-left {
  &::before {
    content: "\e910";
  }
}

.cb-icon-icn_double-right-arrow,
.cb-dbl-right {
  &::before {
    content: "\e911";
  }
}

.cb-icon-icn_email,
.cb-mail {
  &::before {
    content: "\e928";
  }
}

.cb-icon-icn_fax,
.cb-fax {
  &::before {
    content: "\e91c";
  }
}

.cb-icon-icn_home,
.cb-home {
  &::before {
    content: "\e91f";
  }
}

.cb-icon-icn_location,
.cb-location {
  &::before {
    content: "\e926";
  }
}


.cb-icon-icn_phone,
.cb-icon-icn_phone_right,
.cb-phone-alt {
  &::before {
    content: "\e938";
  }
}

.cb-icon-icn_resources,
.cb-resources {
  &::before {
    content: "\e93f";
  }
}

.cb-icon-icn_calendar,
.cb-icon-icn_calendar_circle,
.cb-cal-full {
  &::before {
    content: "\e907";
  }
}

.cb-icon-round-arrow,
.cb-east {
  &::before {
    content: "\e916";
  }
}

.cb-icon-calendar,
.cb-cal-empty {
  &::before {
    content: "\e906";
  }
}

.cb-icon-note,
.cb-note {
  &::before {
    content: "\e935";
  }
}

.cb-icon-light,
.cb-lightbulb {
  &::before {
    content: "\e922";
  }
}

.cb-icon-icn_question-mark,
.cb-icon-ssd,
.cb-question {
  &::before {
    content: "\e93c";
  }
}

.cb-icon-megaphone,
.cb-megaphone {
  &::before {
    content: "\e92a";
  }
}

.cb-icon-expand,
.cb-icon-icn_plus_round,
.cb-plus {
  &::before {
    content: "\e93a";
  }
}

.cb-icon-collapse,
.cb-minus {
  &::before {
    content: "\e92c";
  }
}

.cb-icon-chatbubble,
.cb-chat-bubble {
  &::before {
    content: "\e909";
  }
}

.cb-icon-facebook,
.cb-icon-icn_facebook,
.cb-facebook {
  &::before {
    content: "\e91b";
  }
}

.cb-icon-linkedin,
.cb-icon-icn_linkedin,
.cb-linkedin {
  &::before {
    content: "\e923";
  }
}

.cb-icon-twitter,
.cb-icon-icn_twitter,
.cb-twitter {
  &::before {
    content: "\e94f";
  }
}

.cb-icon-youtube,
.cb-icon-icn_youtube,
.cb-youtube {
  &::before {
    content: "\e956";
  }
}

.cb-icon-icn_instagram,
.cb-instagram {
  &::before {
    content: "\e920";
  }
}

.cb-icon-icn_compose,
.cb-compose {
  &::before {
    content: "\e90d";
  }
}

.cb-icon-icn_out,
.cb-upload {
  &::before {
    content: "\e951";
  }
}

.cb-icon-icn_list,
.cb-list {
  &::before {
    content: "\e924";
  }
}

.cb-icon-icn_user,
.cb-icon-icn_signin,
.cb-user {
  &::before {
    content: "\e952";
  }
}

.cb-icon-icn_settings,
.cb-settings {
  &::before {
    content: "\e944";
  }
}

.cb-icon-icn_error,
.cb-error {
  &::before {
    content: "\e918";
  }
}

.cb-icon-icn_book,
.cb-book {
  &::before {
    content: "\e905";
  }
}

.cb-icon-icn_tag,
.cb-tag {
  &::before {
    content: "\e94c";
  }
}

.cb-icon-icn_check,
.cb-check {
  &::before {
    content: "\e90b";
  }
}

.cb-icon-icn_menu_trigger,
.cb-menu {
  &::before {
    content: "\e92b";
  }
}

.cb-icon-icn_exclamation,
.cb-icon-icn_round_alerts,
.cb-icon-icn_deadline,
.cb-exclamation {
  &::before {
    content: "\e919";
  }
}

.cb-icon-icn_draft-in,
.cb-draft-in {
  &::before {
    content: "\e915";
  }
}

.cb-icon-icn_new-window,
.cb-new-window {
  &::before {
    content: "\e930";
  }
}

.cb-icon-icn_no-draft,
.cb-no-draft {
  &::before {
    content: "\e931";
  }
}

.cb-icon-Nav_Links,
.cb-nav-links {
  &::before {
    content: "\e92d";
  }
}

.cb-icon-icn_quote_right,
.cb-quote-right {
  &::before {
    content: "\e93e";
  }
}

.cb-icon-icn_quote_left,
.cb-quote-left {
  &::before {
    content: "\e93d";
  }
}

.cb-icon-icn_close,
.cb-x-mark {
  &::before {
    content: "\e955";
  }
}

.cb-icon-circle-o,
.cb-circle{
  &::before {
    content: "\e90c";
  }
}

.cb-icon-circle,
.cb-disc {
  &::before {
    content: "\e912";
  }
}

.cb-icon-icn_arrow_e,
.cb-east {
  &::before {
    content: "\e916";
  }
}

.cb-icon-icn_arrow_ne,
.cb-north-east {
  &::before {
    content: "\e932";
  }
}

.cb-icon-icn_creditcard,
.cb-creditcard {
  &::before {
    content: "\e90f";
  }
}

.cb-icon-icn_educator,
.cb-educator {
  &::before {
    content: "\e917";
  }
}

.cb-icon-icn_series,
.cb-series {
  &::before {
    content: "\e943";
  }
}

.cb-icon-icn_ringing_bell,
.cb-bell {
  &::before {
    content: "\e904";
  }
}


.cb-icon-icn_pending,
.cb-pending {
  &::before {
    content: "\e937";
  }
}

.cb-icon-icn_authenticated,
.cb-signed-in {
  &::before {
    content: "\e946";
  }
}

.cb-icon-icn_matrix,
.cb-matrix {
  &::before {
    content: "\e929";
  }
}

.cb-icon-icn_search,
.cb-search {
  &::before {
    content: "\e942";
  }
}

.cb-icon-icn_shopping_cart,
.cb-cart {
  &::before {
    content: "\e908";
  }
}

.cb-icon-icn_global,
.cb-globe {
  &::before {
    content: "\e91e";
  }
}

.cb-icon-Acorn,
.cb-acorn {
  &::before {
    content: "\e957";
  }
}

.cb-batch-upload {
  &::before {
    content: "\e903";
  }
}

.cb-needs-score {
  &::before {
    content: "\e92e";
  }
}
.cb-test-scored {
  &::before {
    content: "\e94e";
  }

}
.cb-save-date {
  &::before {
    content: "\e941";
  }
}
.cb-north-west {
  &::before {
    content: "\e933";
  }
}
.cb-west {
  &::before {
    content: "\e953";
  }
}
.cb-south-west {
  &::before {
    content: "\e94a";
  }
}
.cb-south-east {
  &::before {
    content: "\e949";
  }
}

.cb-barchart {
  &::before {
    content: "\e901";
  }
}
.cb-print {
  &::before {
    content: "\e93b";
  }
}

.cb-chat {
  &::before {
    content: "\e90a";
  }
}

.cb-window {
  &::before {
    content: "\e954";
  }
}

.cb-bag {
  &::before {
    content: "\e900";
  }
}

.cb-expand-window {
  &::before {
    content: "\e91a";
  }
}

.cb-task-complete {
  &::before {
    content: "\e94d";
  }
}

.cb-new-doc {
  &::before {
    content: "\e92f";
  }
}

.cb-sound {
  &::before {
    content: "\e948";
  }
}

.cb-computer {
  &::before {
    content: "\e90e";
  }
}

.cb-share {
  &::before {
    content: "\e945";
  }
}

.cb-lock {
  &::before {
    content: "\e927";
  }
}
.cb-phone {
  &::before {
    content: "\e939";
  }
}
.cb-disc-alt {
  &::before {
    content: "\e958";
  }
}
.cb-box-fill {
  &::before {
    content: "\e959";
  }
}
.cb-box {
  &::before {
    content: "\e95a";
  }
}
.cb-play {
  &::before {
    content: "\e95b";
  }
}
.cb-check-alt {
  &::before {
    content: "\e95c";
  }
}

.cb-check-circle {
  &::before {
    content: "\e95d";
  }
}