// Feature Preset

// sm: small
// md: medium
// lg: large


// font size
.size19-24 (){
	font-size: 1.1875em;
	line-height: 1.263157894736842em;
}
.size16-24 () {
	font-size: 1em;
	line-height: 1.5em;
}
.size23-24 (){
	font-size: 1.4375em;
	line-height: 1.0434782608695652em;
}
.size28-48 (){
	font-size: 1.75em;
	line-height: 1.7142857142857142em;
}
.size36-48 (){
	font-size: 2.25em;
	line-height: 1.3333333333333333em
}

// Medium text Block
.cb-md-txt (){
	.cb-block-title {
		.size23-24 ();
		margin-bottom: 24px;
		// -------tablet + mobile
		@media (max-width: @screen-sm-max) {
			.size19-24 ();
			margin-bottom: 0;
		}

		// mobile
		@media (max-width: @screen-xs-max) {
			border-top: 0;
			padding-top: 24px;
		}
	}
	.cb-block-desc {
		.size19-24 ();
		// -------tablet + mobile
		@media (max-width: @screen-sm-max) {
			.size16-24 ();
		}
	}
}


// ------------------------------------------ Key Benefit
	.cb-key-benefit-variations (@w1, @w2) {
		.cb-key-benefit {
			.cb-block-img {
				width: @w1;
				.cb-glyph {
					&.cb-glyph-circular {
						font-size: calc(@w1 / 2);
					}
				}
				.cb-glyph-numeric {
					font-size: calc(@w1 / 2);
				}
				.cb-glyph-multi {
					font-size: @w1;
				}
			}
			// always normal
			.cb-block-title {
				width: calc(100% - (@w1 + @w2));
			}
			.cb-block-desc {
				width: calc(100% - (@w1 + @w2));
			}
		}
	}
	.cb-key-benefit-grid (@w1, @w2, @grid) {
		.cb-panel-grid-@{grid} {
			.cb-key-benefit-variations (@w1, @w2);
		}
	}
	.cb-key-benefit-size (@w1, @w2, @size) {
		.cb-@{size}-block {
			.cb-key-benefit-variations (@w1, @w2);
		}
	}
	.cb-key-benefit-panel () {
		// Font size
		//oversized + Desktop
		@media (min-width: @screen-md-min) and (max-width: @screen-md-max), (min-width: @screen-lg-min) {
			.cb-key-benefit-grid(96px, 24px, 2);
			.cb-key-benefit-grid(64px, 16px, 3);
		}

		// tablet + mobile
		@media (max-width: @screen-sm-max) {
			.cb-key-benefit-grid(48px, 16px, 2);
			.cb-key-benefit-grid(48px, 16px, 3);
		}
	}
	.cb-key-benefit-loop (@grid, @i) when (@i > 0) {
		.cb-panel-grid-@{grid} {
			.row {
				[class*="col-sm-6"]{
					&:nth-last-child(@{i}) {
						.cb-key-benefit {
					    margin-bottom: 0;
					  }
					}
				}
			}
		}
		.cb-key-benefit-loop(@grid, (@i - 1));
	}

// ------------------------------------------ Procedures
	.cb-procedures-variations (@w1, @size) {
		@w2: 16px;
		.cb-procedures {
			.cb-block-img {
				width: @w1;
				.cb-glyph-numeric {
					font-size: calc(@w1 / 2);
				}
			}
			// always normal
			.cb-block-title {
				width: calc(100% - (@w1 + @w2));
			}
			.cb-block-desc {
				.size16-24 ();
				width: calc(100% - (@w1 + @w2));
			}
			& when (@size = sm) {
				.cb-block-title {
					border: 0;
					padding: 0;
					margin: 0;
				}
				.cb-block-desc {
					width: 100%;
					border-top: 1px solid @gray-corporate-border;
					margin-top: 12px;
					padding-top: 11px;
				}
			}
		}
	}
	.cb-procedures-grid (@w1, @grid, @size) {
		.cb-panel-grid-@{grid} {
			.cb-procedures-variations (@w1, @size);
		}
	}
	.cb-procedures-size (@w1, @size) {
		.cb-@{size}-block {
			.cb-procedures-variations (@w1, @size);
		}
	}
	.cb-procedures-panel () {
		// Font size
		//oversized + Desktop
		@media (min-width: @screen-md-min) and (max-width: @screen-md-max), (min-width: @screen-lg-min) {
			.cb-procedures-grid(64px, 2, lg);
			.cb-procedures-grid(48px, 3, md);
			.cb-procedures-grid(48px, 4, sm);
		}

		// tablet + mobile
		@media (max-width: @screen-sm-max) {
			.cb-procedures-grid(48px, 2, md);
			.cb-procedures-grid(48px, 3, md);
			.cb-procedures-grid(48px, 4, md);
		}
	}

	.cb-procedures-loop (@grid, @i) when (@i > 0) {
		.cb-panel-grid-@{grid} {
			.row {
				[class*="col-sm-6"]{
					&:nth-last-child(@{i}) {
						.cb-procedures {
					    margin-bottom: 0;
					  }
					}
				}
			}
		}
		.cb-procedures-loop(@grid, (@i - 1));
	}

// ------------------------------------------ Testimonial
	.cb-testimonial-variations (@w1, @w2) {
		.cb-testimonial {
			.cb-block-img {
				width: @w1;
				.cb-glyph {
					font-size: calc(@w1 / 2);
				}
				.cb-glyph-multi {
					font-size: @w1;
				}
			}
			// always normal
			.cb-block-title {
				width: calc(100% - (@w1 + @w2));
			}
			.cb-block-desc {
				width: calc(100% - (@w1 + @w2));
				padding-left: @w2;
			}
		}
	}
	.cb-testimonial-grid (@w1, @w2, @grid) {
		.cb-panel-grid-@{grid} {
			.cb-testimonial-variations (@w1, @w2);
		}
	}
	.cb-testimonial-size (@w1, @w2, @size) {
		.cb-@{size}-block {
			.cb-testimonial-variations (@w1, @w2);
		}
	}
	.cb-testimonial-panel () {
		// Font size
		//oversized + Desktop
		@media (min-width: @screen-md-min) and (max-width: @screen-md-max), (min-width: @screen-lg-min) {
			.cb-testimonial-grid(64px, 24px, 2);
			.cb-testimonial-grid(48px, 24px, 3);
			.cb-testimonial-grid(32px, 16px, 4);
		}

		// tablet + mobile
		@media (max-width: @screen-sm-max) {
			.cb-testimonial-grid(32px, 16px, 2);
			.cb-testimonial-grid(32px, 16px, 3);
			.cb-testimonial-grid(32px, 16px, 4);
		}
	}
	.cb-testimonial-loop (@grid, @i) when (@i > 0) {
		.cb-panel-grid-@{grid} {
			.row {
				[class*="col-sm-6"]{
					&:nth-last-child(@{i}) {
						.cb-testimonial {
					    margin-bottom: 0;
					  }
					}
				}
			}
		}
		.cb-testimonial-loop(@grid, (@i - 1));
	}

// ------------------------------------------  Free Form

	.cb-free-form-panel () {
		// margin
		//oversized + Desktop
		@media (min-width: @screen-md-min) and (max-width: @screen-md-max), (min-width: @screen-lg-min) {
			.cb-free-form-loop(2, 2);
			.cb-free-form-loop(3, 3);
			.cb-free-form-loop(4, 4);
		}

		// col-sm: tablet
		@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
			.cb-free-form-loop(2, 2);
			.cb-free-form-loop(3, 2);
			.cb-free-form-loop(4, 2);
		}

		// col-xs: mobile
		@media (max-width: @screen-xs-max) {
			.cb-free-form-loop(2, 1);
			.cb-free-form-loop(3, 1);
			.cb-free-form-loop(4, 1);
		}
	}
	.cb-free-form-loop (@grid, @i) when (@i > 0) {
		.cb-panel-grid-@{grid} {
			.row {
				[class*="col-sm-6"],
				[class*="col-xs-6"]{
					&:nth-last-child(@{i}) {
						& > .cb-free-form {
					    margin-bottom: 0;
					  }
					}
				}
			}
		}
		.cb-free-form-loop(@grid, (@i - 1));
	}

	//  Custom Logic
	.cb-free-form-panel-custom () {
		.cb-panel-grid-2 {
			.row {
				// tablet
				@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
					[class*="col-sm-12"] {
						&:nth-last-child(1){
							& > .cb-free-form {
						    margin-bottom: 0;
						  }
						}
						&:nth-last-child(2){
							& > .cb-free-form {
						    margin-bottom: 48px;
						  }
						}
					}
				}
			}
		}
	}
