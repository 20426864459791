//
// Utils
// --------------------------------------------------

//APRICOT-1515
body {
  -ms-overflow-style: scrollbar;
}

.tmp-element,
.cb-tmp-element{
  position: absolute;
  left: -9999px;
}

ul {
  &.no-bullet,
  &.cb-no-bullet {
    list-style: none;
  }
}


// -------- Text

.cb-base-font-size{
	font-size: @font-size-base !important;
	line-height: @line-height-base !important;
}
.cb-small-font-size {
  font-size: @font-size-small !important;
  line-height: @line-height-small !important;
}
.cb-reset-font,
.cb-base-font  {
  font-family: @font-family-base !important;
  font-size: @font-size-base !important;
  font-weight: 400 !important;
  line-height: @line-height-base !important;
  color: @cb-base-font-color !important;
}
.cb-small-font {
  font-family: @font-family-base !important;
  font-size: @font-size-small !important;
  line-height: @line-height-small !important;
  font-weight: 400 !important;
  color: @cb-base-font-color !important;
}
.cb-txt-uppercase{
  text-transform: uppercase !important;
}

.cb-line-through{
  text-decoration: line-through !important;
}

.cb-align-left,
.cb-text-align-left {
  text-align: left !important;
}

.cb-align-right,
.cb-text-align-right  {
  text-align: right !important;
}

.cb-align-center,
.cb-text-align-center {
  text-align: center !important;
}


a[data-target] {
  cursor: pointer;
}

.cb-focus {
  .cb-focus();
}
.cb-action-state {
  cursor: pointer;
}

@-moz-document url-prefix() {
  *:focus,
  *:active{
    outline-color: @blue-accent1-corporate-color;
  }
}

// no focus on mouse and touch
[data-cb-input-m="mouse"] {
  *:focus,
  *:active{
    .cb-no-focus();
  }
}


// margin, padding and Floats - No gutters( bootstrap 4.0.0)
// -------------------------

.cb-no-width {
  width: 100%!important;
}

// -------- Padding
.cb-no-padding {
  padding: 0!important;
}
.cb-no-padding-left {
  padding-left: 0!important;
}
.cb-no-padding-top {
  padding-top: 0!important;
}
.cb-no-padding-bottom {
  padding-bottom: 0!important;
}
.cb-no-padding-right {
  padding-right: 0!important;
}

// -------- margin
.cb-no-margin {
  margin: 0!important;
}

.cb-no-margin-top {
  margin-top: 0!important
}

.cb-no-margin-bottom{
  margin-bottom: 0!important
}
.cb-no-margin-left{
  margin-left: 0!important
}
.cb-no-margin-right{
  margin-right: 0!important
}

// -------- gutters
.cb-no-gutters {
  margin-right: 0;
  margin-left: 0;

  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// ---------- border
.cb-border-top {
  border-top: 1px solid @gray-corporate-border;
}
.cb-border-bottom {
  border-bottom: 1px solid @gray-corporate-border;
}
.cb-border-left {
  border-left: 1px solid @gray-corporate-border;
}
.cb-border-right {
  border-right: 1px solid @gray-corporate-border;
}

// ---------- box-shahow
.cb-box-shadow {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, .125);
  border: 0!important;
}

// mobile
@media (max-width: @screen-xs-max) {
  .cb-floats(xs);
  .cb-no-gutters(xs);
  .cb-no-width(xs);
  .cb-no-padding(xs);
  .cb-no-margin(xs);
  .cb-text-align(xs, left);
  .cb-text-align(xs, right);
  .cb-text-align(xs, center);
  .cb-font-color(xs, @white-color, white);
  .cb-font-color(xs, @cb-base-font-color, base);
  .cb-truncate(xs);
}

// Tablet
@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
  .cb-floats(sm);
  .cb-no-gutters(sm);
  .cb-no-width(sm);
  .cb-no-padding(sm);
  .cb-no-margin(sm);
  .cb-text-align(sm, left);
  .cb-text-align(sm, right);
  .cb-text-align(sm, center);
  .cb-font-color(sm, @white-color, white);
  .cb-font-color(sm, @cb-base-font-color, base);
  .cb-truncate(sm);
}

// desktop
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .cb-floats(md);
  .cb-no-gutters(md);
  .cb-no-width(md);
  .cb-no-padding(md);
  .cb-no-margin(md);
  .cb-text-align(md, left);
  .cb-text-align(md, right);
  .cb-text-align(md, center);
  .cb-font-color(md, @white-color, white);
  .cb-font-color(md, @cb-base-font-color, base);
  .cb-truncate(md);
}

// oversized
@media (min-width: @screen-lg-min) {
  .cb-floats(lg);
  .cb-no-gutters(lg);
  .cb-no-width(lg);
  .cb-no-padding(lg);
  .cb-no-margin(lg);
  .cb-text-align(lg, left);
  .cb-text-align(lg, right);
  .cb-text-align(lg, center);
  .cb-font-color(lg, @white-color, white);
  .cb-font-color(lg, @cb-base-font-color, base);
  .cb-truncate(lg);
}

.cb-no-visibility {
  visibility: hidden;
}