//
// Call to Action Cards
// --------------------------------------------------

.cb-cta-card {
	background-color: @white-color;
	padding: 24px;
	text-align: center;
	border: 1px solid @gray-corporate-border;
	.cb-cta-card-icon {
		display: inline-block;
		width: 64px;
		height: 64px;
	}
	h3, h4 {
		.roboto-slab();
		font-weight: 300;
		font-size: 1.438rem;
		margin-bottom: 24px;
		word-wrap: break-word;
	}
	&.cb-vertical-card {
		a {
			color: @cb-base-font-color;
			display: inline-block;
			h3, h4 {
				color: @cb-base-font-color;
				margin-bottom: 0;
			}
			&:hover {
				color: @cb-base-font-color;
				h3, h4 {
					color: @cb-base-font-color;
				}
			}
		}
	}
	&.cb-horizontal-card {
		text-align: left;
		a {
			display: flex;
		  align-items: center;
			color: @cb-base-font-color;
			h3, h4 {
				color: @cb-base-font-color;
				margin-left: 24px;
				font-size:  1.188rem;
				line-height: 24px;
				margin-bottom: 0;
				width: calc(100% - 88px); //64 + 24
			}
			&:hover {
				color: @cb-base-font-color;
				h3, h4 {
					color: @cb-base-font-color;
				}
			}
		}
	}
}