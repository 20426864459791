//
// CB Language localization
// --------------------------------------------------

@see-more: 'Ver más +';
@see-less: 'Ver menos -';

// Spanish
:lang(es) {
	// Accordion
	.cb-see-more {
	  .panel {
	    .panel-heading {
	      a {
	        &.collapsed::after {
	          content: @see-more;
	        }
	        &.cb-accordion-show::after {
	          content: @see-less;
	        }
	      }
	    }
	  }
	}

	// Item List
	.cb-item-list {
		&.cb-item-list-rich{
			// Resource
			.cb-type-resource {
				// batch download
				.cb-batch-resource {
				  .cb-item-download {
				  	a {
				  		&.cb-see-more {
				  			float: right;
				  			&.open {
				          &::after {
				            content: @see-less;
				          }
				        }
				        &::after{
				          content: @see-more;
				        }
				  		}
				  	}
				  }
				}
			}
		}
	}

	// Loader
	.cb-loader {
	  // size
	  &.cb-loader-lg {
		  &::after{
	      content: "cargando";
		  }
	  }
	}

	// Resources
	.cb-resource {
	  // resource Modules
	  &.cb-resource-bulk-detail {
	    .cb-resource-download{
	      .cb-resource-see-more{
	        &.open {
	          &::after {
	            content: @see-less;
	          }
	        }
	        &::after{
	          content: @see-more;
	        }
	      }
	    }
	  }
	}
}


