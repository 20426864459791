//
// Accordion
// Focus: custom
// --------------------------------------------------

.cb-accordion {
  .cb-accordion-controls {
    text-align: right;
    padding-bottom: 12px;
    .cb-accordion-title {
      display: inline;
      h2, .h2,
      h3, .h3,
      h4, .h4,
      h5, .h5 {
        line-height: 24px;
        display: inline;
        float: left;
        margin-bottom: 0;
      }
    }
    .cb-accordion-buttons {
      display: inline;
    }
    .btn {
      background-color: transparent;
      text-transform: capitalize;
      font-family: @font-family-Roboto;
      color: @cb-blue-link-color;
      margin: 0;
      padding: 0;
      &:hover {
        color: @cb-blue-dark-color;
        text-decoration: underline;
      }
      &:focus {
        .cb-focus();
      }
    }
    .cb-separator {
      margin: 0 15px;
      height: 18px;
      &::after {
        content: "|";
        color: @gray-corporate-border;
      }
    }

    @media (max-width: @screen-sm-max) {
      text-align: left;
      .cb-accordion-title {
        display: block;
        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5 {
          display: block;
          float: none;
          margin-bottom: 0;
        }
        margin-bottom: 12px;
      }
      .cb-accordion-buttons {
        display: block;
      }
    }
  }

  .panel-group .panel + .panel {
    margin-top: 0;
  }
  .panel {
    border: 0;
    border-top: 1px solid @gray-corporate-border;
    box-shadow: none;
    .border-box();
    &:first-of-type {
      border-top: 4px solid @gray1-corporate-color;
    }
    &:last-of-type {
      border-bottom: 1px solid @gray-corporate-border;
    }
  }
  .panel-heading {
    background-color: transparent;
    border: 0;
    min-height: 71px;
    padding: 0;
    a {
      width: calc(100% - 64px);
      display: block;
      position: relative;
      padding: 24px 0 23px 16px;
      &::after {
        .cb-glyphs();
        color: @cb-blue-link-color;
        right: -40px;
        position: absolute;
        top: 28px;
      }
      &.collapsed::after {
        content: "\e93a";
        color: @cb-blue-link-color;

      }
      &.cb-accordion-show::after {
        content: "\e92c";
        color: @cb-blue-link-color;
      }
      &:focus {
        .cb-focus();
      }
    }
    .panel-title.focus {
      outline-style: solid;
      outline-color: @blue-accent1-corporate-color;
      outline-width: 1px;
      a {
        &:focus {
          outline-width: 0;
        }
      }
    }
    .cb-panel-heading-content,
    p {
      font-size: @font-size-small;
      line-height: @line-height-small-base;
      color: @cb-base-font-color;
      margin-bottom: 24px;
      margin-top: 0px;
      padding: 0 16px;
      &.cb-panel-heading-content {
        margin-bottom: 24px!important;
        margin-top: -24px!important;
        width: calc(100% - 64px);
      }
    }
    .panel-title {
      &.cb-focus {
        .cb-focus();
        a {
          &:focus {
            outline-width: 0;
          }
        }
      }
    }
  }

  .panel {
    &:first-of-type {
      .panel-heading {
        min-height: auto;
        a {
          padding: 22px 0 22px 16px;
        }
      }
    }
  }
  .panel-body {
    background: -webkit-linear-gradient(top, #f0f0f0 0, #fff 24px, #fff);
    border-top: 0 !important;
    padding: 24px 16px 0 16px;
    font-size: @font-size-base;
    line-height: @line-height-base;
    p {
      // font-size: @font-size-small;
      // line-height: @line-height-small-base;
      margin-bottom: @line-height-small-base!important;
    }
    &.has-see-more{
      margin-bottom: 0;
    }
  }
}
.cb-see-more {
  .panel-group{
    margin-bottom: 0;
  }
  .panel {
    border: 0;
    &:last-of-type {
      border: 0;
    }
    .panel-heading {
      min-height: auto;
      a {
        width: 100%;
        display: block;
        position: relative;
        padding: 0 0 48px 0;
        font-size: @font-size-small;
        line-height: 1@line-height-small-base;
        color: @cb-blue-link-color;
        &::after {
          right: 16px;
          position: absolute;
          top: 2px;
        }
        &.collapsed::after {
          font-family: @font-family-Roboto;
          content: "See More +";
          color: @cb-blue-link-color;
        }
        &.cb-accordion-show::after {
          font-family: @font-family-Roboto;
          content: "See Less -";
          color: @cb-blue-link-color;
        }
        &:focus{
          outline: 0;
        }
        &.cb-accordion-show:focus::after,
        &.collapsed:focus::after {
          .cb-focus();
        }
      }
    }
    .panel-body {
      padding: 0;
      background-color: @digital-field-gray;
      margin-bottom: 0;
      .list-group{
        margin-bottom: 0;
        .list-group-item{
          h3,
          h4,
          .cb-list-group-item-header{
            font-size: @font-size-base;
            line-height: @line-height-base;
            margin: 0;
          }
          background-color: transparent;
          border-left: 0;
          border-right: 0;
          padding: 23px 16px 23px 40px;
        }
      }
    }
  }
}
.cb-accordion {
  .panel-body{
    .cb-see-more {
      .panel-body {
        .list-group{
          margin-bottom: 0;
          .list-group-item{
            &:last-of-type{
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

// Accordion Menu List
.cb-accordion {
  &.cb-accordion-menu {
    > h2, > .h2,
    > h3, > .h3,
    > h3, > .h3,
    > h4, > .h4,
    > h5, > .h5 {
      // .roboto-slab-bold();
      // font-size: 1.125rem;
      // line-height: 1.3333333333333333em;
      line-height: 24px;
      margin-bottom: 12px;
    }

    .panel-group {
      border-top: 4px solid @cb-base-font-color;
      @media (min-width: @screen-md) { //desktop, oversized
        margin-bottom: 36px;
      }
      @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { // tablet
        margin-bottom: 20px;
      }
    
      @media (max-width: @screen-xs-max) { // mobile
        margin-bottom: 24px;
      }
    }
    .panel {
      &:nth-of-type(1) {
        border-top: 0;
      }
    }
    .panel-heading {
      a {
        width: calc(100% - 48px);
        &::after {
          right: -32px;
          top: 28px;
        }
      }
    }
    .panel-body {
      // background: -webkit-linear-gradient(top, #f0f0f0 0, #fff 24px, #fff);
      padding: 24px 48px 24px 16px;
      .cb-accordion-menu-link {
        float: right;
        color: @cb-base-font-color;
        position: relative;
        &:hover,
        &:focus {
          color: @cb-base-font-color;
        }
        &::after {
          position: absolute;
          .cb-glyphs();
          display:  block;
          width: 1em;
          height: 1em;
          line-height: 1em;
          top: 6px;
          right: -32px;
          content: "\e916";
        }
      }
    }
  }
}

// no focus on mouse and touch
[data-cb-input-m="mouse"] {
  .cb-accordion {
    .panel-heading {
      a {
        &:focus {
          .cb-no-focus();
        }
      }
      .panel-title.focus {
        .cb-no-focus();
      }
      .panel-title {
        &.cb-focus {
          .cb-no-focus();
        }
      }
    }

    .cb-accordion-controls{
      .btn {
        &:focus {
          .cb-no-focus();
        }
        &:active {
          box-shadow: none;
        }
      }
    }
  }

  .cb-see-more {
    .panel {
      .panel-heading {
        a {
          &.cb-accordion-show:focus::after,
          &.collapsed:focus::after {
            .cb-no-focus();
          }
        }
      }
    }
  }
}