//
// Mobile Tables
// --------------------------------------------------

.cb-mobile-tbl {
  th {
    .roboto-bold();
  }
  td {
    width: 50%;
  }
  .cb-new-row {
    td, th {
      border-bottom-color: @gray2-corporate-color;
      padding-bottom: 24px;
    }
    &:last-of-type{
      td, th {
        border-bottom-color: @gray-corporate-border;
      }

    }
    &+tr {
      td, th {
        padding-top: 24px;
      }
    }
  }
}
.table-responsive {
  &.cb-no-border {
    border: 0;
    height: auto!important;
  }
}