//
// Apricot Variables
// --------------------------------------------------

//
// Apricot Variables
// --------------------------------------------------


@cb-image-path-aws: '//mango.collegeboard.org/cbmango1/prod/ui/all/1/';
@cb-image-path: '../images/';
@cb-font-path: '../fonts/';

@cb-icons-font-svg-id: 'cb_icons';
@cb-icons-multi-font-svg-id: 'cb_icons_multi';
@cb-icons-logo-font-svg-id: 'cb_icons_logo';

@cb-grid-gutter-width-xs: 15px;
@cb-grid-gutter-width-sm: 20px;
@cb-grid-gutter-width-md: 30px;
@cb-grid-gutter-width-lg: 30px;

@cb-glyph-icon-gutter: 6px;



//---------------- CB color Palette
@cb-blue-dark-color: #00539b;
@cb-blue-color: #0060b3;
@cb-blue-light-color: #006dcc;
@cb-blue-lighter-color: #007be6;
@cb-blue-link-color: @blue-accent1-corporate-color;
@cb-blue-link-color2: #0085cf;

@cb-link-hover-color: #0f67a2;

@cb-gray-bk-color: #eeeeee;
@cb-gray-light-border-color: #e5e2e0;

@cb-gray1-color: #333332;
@cb-gray2-color: #4d4c4b;
@cb-gray3-color: #666564;
@cb-gray4-color: #807f7d;
@cb-gray5-color: #999896;
@cb-gray6-color: #b3b1af;
@cb-gray7-color: #cccac8;
@cb-gray8-color: #ebe9e6;
@cb-gray9-color: #faf9f7;
@cb-gray10-color: #cccacb;
@cb-gray60-color: #999999;


//----------- brand colors

@black-corporate-color: #1e1e1e;

@gray1-corporate-color: #505050;
@gray2-corporate-color: #888888;
@gray3-corporate-color: #b2b2b2;
@gray4-corporate-color: #d9d9d9;

@gray-corporate-border: @gray4-corporate-color;

@digital-field-gray: #f0f0f0;

@orange-corporate-color: #e57200;
@yellow-corporate-color: #fedb00;
@yellow-highlight-color: #fdf4ba;

@blue-corporate-color: #006298;
@blue-accent1-corporate-color: #0077c8;
@blue-accent2-corporate-color: #009cde;
@blue-highlight-color: #e5f5fc;

// keep this one as duplicate
@k-12-color: #009cde;
@k-12-accent-color: #71c5e8;

@higher-education-color: #702f8a;
@higher-education-accent-color: #a05eb5;

@access-color: #3a913f;
@access-accent-color: #6cc24a;

//-----------


@cb-green-color: #00a97e;
@cb-green-light-color: #a6eddb;

@cb-yellow-color: #f9ee46;
@cb-yellow-light-color: #f9f5bb;

@cb-red-color: #c13145;
@cb-red-light-color: #ffc7ce;

@cb-turquoise1-color: #0f464d;
@cb-turquoise2-color: #005b66;
@cb-turquoise3-color: #017280;
@cb-turquoise4-color: #018a99;
@cb-turquoise5-color: #01a1b3;
@cb-turquoise6-color: #01b7cc;
@cb-turquoise7-color: #01cee6;
@cb-turquoise8-color: #30dff2;

@cb-lime-dark-color: #9cd771;
@cb-lime-color: #a7e679;
@cb-lime-light-color: #b0f27f;
@cb-lime-lighter-color: #b9ff86;

@cb-orange-darker-color: #e57300;
@cb-orange-dark-color: #ff8000;
@cb-orange-color: #ff9900;
@cb-orange-light-color: #ffaa00;
@cb-orange-lighter-color: #ffbb00;

@cb-base-font-color: @gray1-corporate-color;
@cb-disabled-font-color: #989795;

@cb-navigation-color: #7f7e7d;
@cb-active-navigation-color: @cb-blue-dark-color;
@cb-navigation-hover-bk-color: #eeedec;

@white-color: #ffffff;
@black-color: #000000;
@gray20-color: #333333;
@gray40-color: #666666;
@gray80-color: #cccccc;
@gray90-color: #e5e5e5;
@gray54-color: #8a8a8a;


@cb-program-band-sub-color: #f7f7f7;
@cb-program-band-border-color: #e9e9e9;

// --------------

@cb-gray-datepicker-border: #e6e5e3;
@cb-gray-datepicker-unavailable: #ecebe8;

// PopOver

@cb-popove-list-hover-color: #f5f4f2;
@cb-select-arrow-color: @black-color;

@cb-video-modal-bk-color: #808080;

//Fonts
@cb-base-em: 1;

//Vertical rhythm
@cb-scale-factor: 1.2;
@cb-grid-subdivs: 4;


@cb-border-radius-tooltip: 3px;

//Buttons

@cb-btn-font-size-large: 1.25rem; //20px;
@cb-btn-font-size-small: @font-size-small; //14px;

@cb-btn-padding-xsmall-vertical:    2px;
@cb-btn-padding-xsmall-horizontal:  8px;

@cb-btn-padding-small-vertical:    12px;
@cb-btn-padding-small-horizontal:  32px;

@cb-btn-padding-large-vertical:    12px;
@cb-btn-padding-large-horizontal:  52px;

@cb-btn-radius-large: 3px;
@cb-btn-radius-small: 3px;

//Primary Button

@cb-btn-primary-bk-color: @yellow-corporate-color;
@cb-btn-primary-font-color: @black-corporate-color;
@cb-btn-primary-font-family: @font-family-RobotoSlab;
@cb-btn-primary-font-weight: bold;
@cb-btn-primary-padding-large-vertical: 20px;
@cb-btn-primary-gradient-start-color: @yellow-corporate-color;
@cb-btn-primary-gradient-end-color: @yellow-corporate-color;
@cb-btn-primary-border-color: transparent; //Border: 0
@cb-btn-primary-hover-font-color: @black-corporate-color;
@cb-btn-primary-hover-bk-color: @yellow-corporate-color;
@cb-btn-primary-hover-border-color: transparent; //Border: 0
@cb-btn-primary-disabled-font-color: @cb-disabled-font-color;
@cb-btn-primary-disabled-bk-color: @cb-gray8-color;
@cb-btn-primary-disabled-border-color: transparent; //Border: 0

//Secondary Button
@cb-btn-secondary-bk-color:  @gray2-corporate-color;
@cb-btn-secondary-font-color: @white-color;
@cb-btn-secondary-font-family: @font-family-RobotoSlab;
@cb-btn-secondary-font-weight: bold;
@cb-btn-secondary-gradient-start-color: @white-color;
@cb-btn-secondary-gradient-end-color: @white-color;
@cb-btn-secondary-border-color: transparent; //Border: 0
@cb-btn-secondary-hover-font-color: @white-color;
@cb-btn-secondary-hover-bk-color:  @gray2-corporate-color;
@cb-btn-secondary-hover-border-color: transparent; //Border: 0
@cb-btn-secondary-disabled-font-color: @cb-btn-primary-disabled-font-color;
@cb-btn-secondary-disabled-bk-color: @cb-gray8-color;
@cb-btn-secondary-disabled-border-color: transparent; //Border: 0


@cb-btn-default-font-color: @black-corporate-color;
@cb-btn-default-font-family:  @font-family-RobotoSlab;
@cb-btn-default-font-weight: bold;
@cb-btn-default-bk-color: @cb-gray9-color;
@cb-btn-default-border-color: @gray4-corporate-color;

@cb-btn-default-hover-disabled-font-color: @font-family-RobotoSlab;
@cb-btn-default-hover-disabled-bk-color: @cb-gray-light-border-color;

@cb-btn-tags-font-color: @cb-btn-default-font-color;
@cb-btn-tags-font-family:  @font-family-Roboto;
@cb-btn-tags-font-weight: normal;
@cb-btn-tags-bk-color: @gray90-color;
@cb-btn-tags-border-color: @cb-btn-tags-bk-color;

@cb-btn-tags-hover-disabled-font-color: @white-color;
@cb-btn-tags-hover-disabled-bk-color: #bfbfbf;


//Forms
@cb-form-control-radius: 0;
@cb-form-control-bk-color: @digital-field-gray;
@cb-form-control-height: 48px;

@cb-form-control-vertical: 0;

@cb-form-input-border-color: @gray4-corporate-color;
@cb-form-input-border-hover-color: @gray4-corporate-color;
@cb-form-input-shadow-hover-color: #e7e6e5;

@cb-form-input-disabled-font-color: @cb-disabled-font-color;

@cb-success-glyph: cb-check;
@cb-error-glyph: cb-x-mark;
@cb-warning-glyph: cb-exclamation;


// ------ Fonts

@font-family-SerifaStd-Light:  "SerifaStd-Light", Georgia, "Times New Roman", Times, serif;
@font-family-SerifaStd-Roman:  "SerifaStd-Roman", Georgia, "Times New Roman", Times, serif;
@font-family-SerifaStd-Bold:  "SerifaStd-bold", Georgia, "Times New Roman", Times, serif;

@font-family-Helvetica-Bold:   "Helvetica Bold", Helvetica, Arial, sans-serif;
@font-family-Helvetica-Regular:  "Helvetica Regular", Helvetica, Arial, sans-serif;
@font-family-HelveticaNeue-Regular: HelveticaNeue,"Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-HelveticaNeue-Bold: HelveticaNeueBold,"Helvetica Neue Bold", Helvetica, Arial, sans-serif;

@font-family-RobotoSlab-Light:  "RobotoSlab-Light", "SerifaStd-Light", sans-serif;

@font-family-Roboto: "Roboto", sans-serif;
@font-family-Roboto-Bold: "Roboto Bold", sans-serif;
@font-family-Roboto-Italic: "Roboto Italic", sans-serif;
@font-family-RobotoSlab: "Roboto Slab", sans-serif;
@font-family-RobotoSlab-Bold: "Roboto Slab Bold", sans-serif;

@cb-font-family-h1:            @font-family-RobotoSlab-Bold;
@cb-font-family-h2:            @font-family-RobotoSlab-Bold;
@cb-font-family-h3:            @font-family-RobotoSlab-Bold;
@cb-font-family-h4:            @font-family-RobotoSlab-Bold;
@cb-font-family-h5:            @font-family-RobotoSlab-Bold;


@cb-line-height-h1: 1.7142857142857142em;
@cb-line-height-h2: 1.0434782608695652em;
@cb-line-height-h3: 1.263157894736842em;
@cb-line-height-h4: 1.5em;
@cb-line-height-h5: 1.5em;
@cb-line-height-h6: 1.7142857142857142em;


@cb-list-offset-left: 24px;

@cb-blockquote-icon-color: @gray4-corporate-color;
@cb-blockquote-color: @black-color;
@cb-blockquote-border-color: @cb-gray3-color;
@cb-blockquote-font-size: 1.5rem; //24px;
@cb-blockquote-line-height: 1.25em; //30px;

@cb-blockquote-mobile-font-size: 1.125rem; //18px;
@cb-blockquote-mobile-line-height: 1.333em; //24px;


@cb-table-bk-highlight-color: @gray-corporate-border;
@cb-table-bk-callout-color: @digital-field-gray;


@cb-table-padding: 11px 8px 12px 8px;
@cb-table-padding-condensed: @cb-table-padding;


@cb-notice-color: @black-color;
@cb-notice-title-font-size: 1.71428571em;
@cb-notice-title-line-height: 1.25em;
@cb-notice-font-size: 0.875em;
@cb-notice-line-height: 1.28571429em;

@cb-tip-title-font-size: 1.14285714em;
@cb-tip-title-line-height: 1.5em;
@cb-tip-font-size: 0.875em;
@cb-tip-line-height: 1.28571429em;


// Loader and progress bar
@cb-spinner-light-blue: #c1dcf5;
@cb-spinner-dark-blue: @blue-accent1-corporate-color;

@cb-spinner-white-radial: rgba(255, 255, 255, 0.3);

@cb-spinner-light-gray: #f1f1f2;
@cb-spinner-dark-gray: @gray1-corporate-color;

@cb-progress-border-radius: 0;
@cb-progress-bg: @cb-spinner-light-blue;
@cb-progress-bar-color: @cb-spinner-dark-blue;


//Carousel Controls
@cb-btn-blue: @blue-accent1-corporate-color;
@cb-btn-blue-rollover: @blue-accent2-corporate-color;


// photo gallery
@cb-gallery-range-1: 1146px;
@cb-gallery-range-2: 1048px;
@cb-gallery-range-3: 754px;
@cb-gallery-range-4: 656px;
@cb-gallery-range-5: 290px;

@layout-panel-mobile-img: 384px;