//
// Video Modals
// --------------------------------------------------

.cb-video-modal{
  button.close {
  	position: absolute;
    color: @white-color;
    font-size: 2rem;
    width: 32px;
    height: 32px;
    top: 16px;
    right: 16px;
    z-index: calc(@zindex-modal + 1);
    opacity: 1;
    &:hover {
    	color: @white-color;
    }
    &:focus,
    &.focus {
      .cb-focus();
    }
  }
  .modal-dialog {
    margin: 64px auto;
    width: auto;
    background-color: @cb-video-modal-bk-color;
  }
  .modal-content {
    border: 0;
    line-height: 0;
    background-color: @cb-video-modal-bk-color;
	  .modal-body{
	  	padding: 0;
      .cb-video{
        position:relative;
        padding-bottom:56.25%;
        height:0;
        overflow:hidden;
        iframe,
        object,
        embed {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background-color: @cb-video-modal-bk-color;
        }
      }
      .cb-video-info {
        padding: 18px;
        h3, h4{
          margin-top: 0;
          font-size: @font-size-large;
          line-height: 1.3333333333333333em;
          margin-bottom: 18px;
        }
        p {
          font-size: @font-size-small;
          line-height: 1.4285714285714286em;
        }
      }
	  }
  }

  // mobile
  @media (max-width: @screen-sm-min) {
    .modal-dialog {
      margin: 56px auto;
    }
  }

  @media (max-width: @screen-sm-min) and (orientation: portrait) {
    .modal-content {
      .modal-body{
        .cb-video-info {
          h3, h4 {
            margin-bottom: 0;
          }
          p {
            display: none;
            visibility: hidden;
          }
        }
      }
    }
  }
}