//
// Color palette
// --------------------------------------------------

.cb-blue-dark-bg{
	background-color: @cb-blue-dark-color;
}
.cb-blue-bg{
	background-color: @cb-blue-color;
}
.cb-blue-light-bg{
	background-color: @cb-blue-light-color;
}
.cb-blue-lighter-bg{
	background-color: @cb-blue-lighter-color;
}
.cb-blue-link-bg{
	background-color: @cb-blue-link-color;
}

.cb-gray1-bg{
	background-color: @cb-gray1-color;
}
.cb-gray2-bg{
	background-color: @cb-gray2-color;
}
.cb-gray3-bg{
	background-color: @cb-gray3-color;
}
.cb-gray4-bg{
	background-color: @cb-gray4-color;
}
.cb-gray5-bg{
	background-color: @cb-gray5-color;
}
.cb-gray6-bg{
	background-color: @cb-gray6-color;
}
.cb-gray7-bg{
	background-color: @cb-gray7-color;
}
.cb-gray8-bg{
	background-color: @cb-gray8-color;
}
.cb-gray9-bg{
	background-color: @cb-gray9-color;
}

.cb-green-bg{
	background-color: @cb-green-color;
}
.cb-green-light-bg{
	background-color: @cb-green-light-color;
}

.cb-yellow-bg{
	background-color: @cb-yellow-color;
}
.cb-yellow-light-bg{
	background-color: @cb-yellow-light-color;
}

.cb-red-bg{
	background-color: @cb-red-color;
}
.cb-red-light-bg{
	background-color: @cb-red-light-color;
}

.cb-turquoise1-bg{
	background-color: @cb-turquoise1-color;
}
.cb-turquoise2-bg{
	background-color: @cb-turquoise2-color;
}
.cb-turquoise3-bg{
	background-color: @cb-turquoise3-color;
}
.cb-turquoise4-bg{
	background-color: @cb-turquoise4-color;
}
.cb-turquoise5-bg{
	background-color: @cb-turquoise5-color;
}
.cb-turquoise6-bg{
	background-color: @cb-turquoise6-color;
}
.cb-turquoise7-bg{
	background-color: @cb-turquoise7-color;
}
.cb-turquoise8-bg{
	background-color: @cb-turquoise8-color;
}

.cb-lime-dark-bg{
	background-color: @cb-lime-dark-color;
}
.cb-lime-bg{
	background-color: @cb-lime-color;
}
.cb-lime-light-bg{
	background-color: @cb-lime-light-color;
}
.cb-lime-lighter-bg{
	background-color: @cb-lime-lighter-color;
}

.cb-orange-darker-bg{
	background-color: @cb-orange-darker-color;
}
.cb-orange-dark-bg{
	background-color: @cb-orange-dark-color;
}
.cb-orange-bg{
	background-color: @cb-orange-color;
}
.cb-orange-light-bg{
	background-color: @cb-orange-light-color;
}
.cb-orange-lighter-bg{
	background-color: @cb-orange-lighter-color;
}

//----------- brand background-colors
.cb-white-bg{
	background-color: @white-color;
}

.cb-black-corporate-bg{
	background-color: @black-corporate-color;
}

.cb-blue-corporate-bg{
	background-color: @blue-corporate-color;
}
.cb-accent1-blue-corporate-bg{
	background-color: @blue-accent1-corporate-color;
}
.cb-accent2-blue-corporate-bg{
	background-color: @blue-accent2-corporate-color;
}

.cb-yellow-corporate-bg{
	background-color: @yellow-corporate-color;
}

.cb-orange-corporate-bg{
	background-color: @orange-corporate-color;
}

.cb-gray1-corporate-bg{
	background-color: @gray1-corporate-color;
}
.cb-gray2-corporate-bg{
	background-color: @gray2-corporate-color;
}
.cb-gray3-corporate-bg{
	background-color: @gray3-corporate-color;
}
.cb-gray4-corporate-bg{
	background-color: @gray4-corporate-color;
}
.cb-digital-gray-bg {
	background-color: @digital-field-gray;
}

.cb-k-12-bg,
.k-12-program-bg,
.cb-k-12-program-bg{
	background-color: @k-12-color;
}
.cb-k-12-accent-bg{
	background-color: @k-12-accent-color;
}

.cb-higher-education-bg,
.higher-education-program-bg,
.cb-higher-education-program-bg{
	background-color: @higher-education-color;
}
.cb-higher-education-accent-bg{
	background-color: @higher-education-accent-color;
}

.cb-access-bg,
.access-program-bg,
.cb-access-program-bg{
	background-color: @access-color;
}
.cb-access-accent-bg{
	background-color: @access-accent-color;
}

//----------- End brand colors

//------- Font

.cb-blue-dark-color{
	color: @cb-blue-dark-color;
}
.cb-blue-color{
	color: @cb-blue-color;
}
.cb-blue-light-color{
	color: @cb-blue-light-color;
}
.cb-blue-lighter-color{
	color: @cb-blue-lighter-color;
}
.cb-blue-link-color{
	color: @cb-blue-link-color;
}

.cb-gray1-color{
	color: @cb-gray1-color;
}
.cb-gray2-color{
	color: @cb-gray2-color;
}
.cb-gray3-color{
	color: @cb-gray3-color;
}
.cb-gray4-color{
	color: @cb-gray4-color;
}
.cb-gray5-color{
	color: @cb-gray5-color;
}
.cb-gray6-color{
	color: @cb-gray6-color;
}
.cb-gray7-color{
	color: @cb-gray7-color;
}
.cb-gray8-color{
	color: @cb-gray8-color;
}
.cb-gray9-color{
	color: @cb-gray9-color;
}
.cb-green-color{
	color: @cb-green-color;
}
.cb-green-light-color{
	color: @cb-green-light-color;
}

.cb-yellow-color{
	color: @cb-yellow-color;
}
.cb-yellow-light-color{
	color: @cb-yellow-light-color;
}

.cb-red-color{
	color: @cb-red-color;
}
.cb-red-light-color{
	color: @cb-red-light-color;
}

.cb-turquoise1-color{
	color: @cb-turquoise1-color;
}
.cb-turquoise2-color{
	color: @cb-turquoise2-color;
}
.cb-turquoise3-color{
	color: @cb-turquoise3-color;
}
.cb-turquoise4-color{
	color: @cb-turquoise4-color;
}
.cb-turquoise5-color{
	color: @cb-turquoise5-color;
}
.cb-turquoise6-color{
	color: @cb-turquoise6-color;
}
.cb-turquoise7-color{
	color: @cb-turquoise7-color;
}
.cb-turquoise8-color{
	color: @cb-turquoise8-color;
}

.cb-lime-dark-color{
	color: @cb-lime-dark-color;
}
.cb-lime-color{
	color: @cb-lime-color;
}
.cb-lime-light-color{
	color: @cb-lime-light-color;
}
.cb-lime-lighter-color{
	color: @cb-lime-lighter-color;
}

.cb-orange-darker-color{
	color: @cb-orange-darker-color;
}
.cb-orange-dark-color{
	color: @cb-orange-dark-color;
}
.cb-orange-color{
	color: @cb-orange-color;
}
.cb-orange-light-color{
	color: @cb-orange-light-color;
}
.cb-orange-lighter-color{
	color: @cb-orange-lighter-color;
}

//----------- brand colors
.cb-white-color{
	color: @white-color;
}

.cb-black-corporate-color{
	color: @black-corporate-color;
}

.cb-blue-corporate-color{
	color: @blue-corporate-color;
}
.cb-accent1-blue-corporate-color{
	color: @blue-accent1-corporate-color;
}
.cb-accent2-blue-corporate-color{
	color: @blue-accent2-corporate-color;
}

.cb-yellow-corporate-color{
	color: @yellow-corporate-color;
}

.cb-orange-corporate-color{
	color: @orange-corporate-color;
}

.cb-gray1-corporate-color,
.cb-base-color{
	color: @gray1-corporate-color;
}
.cb-gray2-corporate-color{
	color: @gray2-corporate-color;
}
.cb-gray3-corporate-color{
	color: @gray3-corporate-color;
}
.cb-gray4-corporate-color{
	color: @gray4-corporate-color;
}
.cb-digital-gray-color {
	color: @digital-field-gray;
}
.cb-k-12-color,
.k-12-program-color,
.cb-k-12-program-color{
	color: @k-12-color;
}
.cb-k-12-accent-color{
	color: @k-12-accent-color;
}
.cb-higher-education-color,
.higher-education-program-color,
.cb-higher-education-program-color{
 color: @higher-education-color;
}
.cb-higher-education-accent-color{
 color: @higher-education-accent-color;
}
.cb-access-color,
.access-program-color,
.cb-access-program-color{
color: @access-color;
}
.cb-access-accent-color{
color: @access-accent-color;
}


//----------- brand border color
.cb-white-border-color{
	border-color: @white-color!important;
}

.cb-black-corporate-border-color{
	border-color: @black-corporate-color!important;
}

.cb-blue-corporate-border-color{
	border-color: @blue-corporate-color!important;
}
.cb-accent1-blue-corporate-border-color{
	border-color: @blue-accent1-corporate-color!important;
}
.cb-accent2-blue-corporate-border-color{
	border-color: @blue-accent2-corporate-color!important;
}

.cb-yellow-corporate-border-color{
	border-color: @yellow-corporate-color!important;
}

.cb-orange-corporate-border-color{
	border-color: @orange-corporate-color!important;
}

.cb-gray1-corporate-border-color,
.cb-base-border-color{
	border-color: @gray1-corporate-color!important;
}
.cb-gray2-corporate-border-color{
	border-color: @gray2-corporate-color!important;
}
.cb-gray3-corporate-border-color{
	border-color: @gray3-corporate-color!important;
}
.cb-gray4-corporate-border-color{
	border-color: @gray4-corporate-color!important;
}
.cb-digital-gray-border-color {
	border-color: @digital-field-gray!important;
}
.cb-k-12-border-color,
.cb-k-12-program-border-color{
	border-color: @k-12-color!important;
}
.cb-k-12-accent-border-color{
	border-color: @k-12-accent-color!important;
}
.cb-higher-education-border-color,
.cb-higher-education-program-border-color{
	border-color: @higher-education-color!important;
}
.cb-higher-education-accent-border-color{
	border-color: @higher-education-accent-color!important;
}
.cb-access-border-color,
.cb-access-program-border-color{
	border-color: @access-color!important;
}
.cb-access-accent-border-color{
	border-color: @access-accent-color!important;
}


// ---------- gradient effect
.cb-white-gradient-top {
	background: @white-color;
	background: linear-gradient(180deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 20%);
}

.cb-gray-gradient,
.cb-gray-gradient-bottom {
	background: @gray4-corporate-color;
  background: linear-gradient(0deg,@gray4-corporate-color 0,@digital-field-gray 20%,@digital-field-gray 100%);
}
.cb-gray-gradient-top {
	background: @digital-field-gray;
	background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(240,240,240,1) 20%);
}
.cb-corporate-gray-gradient-top {
	background: @gray1-corporate-color;
	background: linear-gradient(180deg, rgba(64,64,64,1) 0%, rgba(80,80,80,1) 20%);
}
.cb-corporate-blue-gradient-top {
	background: @blue-corporate-color;
	background: linear-gradient(180deg, rgba(1,86,133,1) 0%, rgba(0,98,152,1) 20%);
}
.cb-corporate-accent-blue-gradient-top {
	background: @blue-accent1-corporate-color;
	background: linear-gradient(180deg, rgba(0,105,177,1) 0%, rgba(0,119,200,1) 20%);
}
.cb-k-12-gradient-top {
	background: @blue-accent2-corporate-color;
	background: linear-gradient(180deg, rgba(0,133,200,1) 0%, rgba(0,156,222,1) 20%);
}
.cb-higher-education-gradient-top {
	background: @higher-education-color;
	background: linear-gradient(180deg, rgba(99,42,121,1) 0%, rgba(112,47,138,1) 20%);
}
.cb-access-gradient-top {
	background: @access-color;
	background: linear-gradient(180deg, rgba(52,131,57,1) 0%, rgba(58,145,63,1) 20%);
}

//----------- program data attr, body class

// ------------------------------------ corporate
[data-cb-program="corporate"],
.cb-corporate-program,
.cb-blue-corporate-color {
	.cb-glyph-multi {
		.glyph-multi-bk-color(@white-color, @black-color, @blue-accent2-corporate-color);
	}
  .cb-nav-card{
    h2, .h2,
    h3, .h3{
      border-bottom-color: @blue-corporate-color;
    }
  }
  .cb-hero {
  	&:not(.cb-hero-light) {
			background-color: @blue-corporate-color;
  	}
		@media (max-width: @screen-xs-max) {
			background-color: @white-color;
		  .content {
				h1, .h1,
				h2, .h2,
				h3, .h3,
				h4, .h4{
					&:first-child {
				    border-top-color: @blue-corporate-color;
					}
				}
			}
		}
		&.cb-hero-light{
		  .content {
				h1, .h1,
				h2, .h2,
				h3, .h3,
				h4, .h4{
					&:first-child {
				    border-top-color: @blue-corporate-color;
					}
				}
			}
		}
  }
  .cb-welcome-mat {
  	&.cb-wm-standard,
  	&.cb-wm-article,
  	&.cb-wm-section,
  	&.cb-wm-sub-section{
  		background-color: @blue-corporate-color;
  	}
  }
  .cb-program-color{
  	color: @blue-corporate-color !important;
  }
  .cb-program-bg{
  	background-color: @blue-corporate-color !important;
  }
  .cb-panel-title {
    border-top-color: @blue-corporate-color;
    color: @blue-corporate-color;
  }
  .cb-key-benefit {
		.cb-block-img {
			.cb-glyph {
				color: @blue-corporate-color;
				&.cb-glyph-circular {
					&::after {
						background-color: @blue-corporate-color;
					}
				}
			}
		}
  }
	.cb-procedures {
		.cb-block-img {
			.cb-glyph-numeric {
				color: @blue-corporate-color;
				border-color: @blue-corporate-color;
			}
		}
	}
	.cb-testimonial-full,
	.cb-testimonial-half,
	.cb-testimonial {
		.cb-block-img {
		  .cb-glyph {
		  	&::after {
					background-color: @blue-corporate-color;
		  	}
		  }
		}
	}
	.cb-comparison-block {
		.cb-comparison-vs {
			&.hidden-xs {
				color: @blue-corporate-color;
			}
		  &.hidden-sm {
		    background-color: @blue-corporate-color;
		  }
		}
		&.cb-comparison-sec{
			// mobile
			@media (max-width: @screen-xs-max) {
				border-top-color: @blue-corporate-color;
			}
		}
	}
	.cb-media-full {
		.cb-block-img {
			.cb-glyph {
				&::after{
					background-color: @blue-corporate-color;
				}
		  }
		}
		.cb-block-content{
			background-color: @blue-corporate-color;
		}
	}
	.cb-media-half {
		.cb-block-img {
		  .cb-glyph {
		  	&::after {
					background-color: @blue-corporate-color;
		  	}
		  }
		}
	}
}

// ------------------------------------ k-12
[data-cb-program="k-12"],
.cb-k-12-program,
.cb-k-12-color {
	.cb-glyph-multi {
		.glyph-multi-bk-color (@white-color, @black-color, @k-12-color);
	}
  .cb-nav-card{
    h2, .h2,
    h3, .h3{
      border-bottom-color: @k-12-color;
    }
  }
  .cb-hero {
  	&:not(.cb-hero-light) {
			background-color: @k-12-color;
		}
		@media (max-width: @screen-xs-max) {
			background-color: @white-color;
		  .content {
				h1, .h1,
				h2, .h2,
				h3, .h3,
				h4, .h4{
					&:first-child {
				    border-top-color: @k-12-color;
					}
				}
			}
		}
		&.cb-hero-light{
		  .content {
				h1, .h1,
				h2, .h2,
				h3, .h3,
				h4, .h4{
					&:first-child {
				    border-top-color: @k-12-color;
					}
				}
			}
		}
  }
  .cb-welcome-mat {
  	&.cb-wm-standard,
  	&.cb-wm-article,
  	&.cb-wm-section,
  	&.cb-wm-sub-section {
  		background-color: @k-12-color;
  	}
  }
  .cb-program-color{
  	color: @k-12-color !important;
  }
  .cb-program-bg{
  	background-color: @k-12-color !important;
  }
  .cb-panel-title {
    border-top-color: @k-12-color;
    color: @k-12-color;
  }
  .cb-key-benefit {
		.cb-block-img {
			.cb-glyph {
				color: @k-12-color;
				&.cb-glyph-circular {
					&::after {
						background-color: @k-12-color;
					}
				}
			}
		}
  }
	.cb-procedures {
		.cb-block-img {
			.cb-glyph-numeric {
				color: @k-12-color;
				border-color: @k-12-color;
			}
		}
	}
	.cb-testimonial-full,
	.cb-testimonial-half,
	.cb-testimonial {
		.cb-block-img {
		  .cb-glyph {
		  	&::after {
					background-color: @k-12-color;
		  	}
		  }
		}
	}
	.cb-comparison-block {
		.cb-comparison-vs {
			&.hidden-xs {
				color: @k-12-color;
			}
		  &.hidden-sm {
		    background-color: @k-12-color;
		  }
		}
		&.cb-comparison-sec{
			// mobile
			@media (max-width: @screen-xs-max) {
				border-top-color: @k-12-color;
			}
		}
	}
	.cb-media-full {
		.cb-block-img {
			.cb-glyph {
				&::after{
					background-color: @k-12-color;
				}
		  }
		}
		.cb-block-content{
			background-color: @k-12-color;
		}
	}
	.cb-media-half {
		.cb-block-img {
		  .cb-glyph {
		  	&::after {
					background-color: @k-12-color;
		  	}
		  }
		}
	}
}

// ------------------------------------ access
[data-cb-program="access"],
.cb-access-program,
.cb-access-color {
	.cb-glyph-multi {
		.glyph-multi-bk-color (@white-color, @black-color, @access-color);
	}
  .cb-nav-card{
    h2, .h2,
    h3, .h3{
      border-bottom-color: @access-color;
    }
  }
  .cb-hero {
  	&:not(.cb-hero-light) {
			background-color: @access-color;
		}
		@media (max-width: @screen-xs-max) {
			background-color: @white-color;
			.content {
				h1, .h1,
				h2, .h2,
				h3, .h3,
				h4, .h4{
					&:first-child {
				    border-top-color: @access-color;
					}
				}
			}
		}
		&.cb-hero-light{
		  .content {
				h1, .h1,
				h2, .h2,
				h3, .h3,
				h4, .h4{
					&:first-child {
				    border-top-color: @access-color;
					}
				}
			}
		}
  }
  .cb-welcome-mat {
  	&.cb-wm-standard,
  	&.cb-wm-article,
  	&.cb-wm-section,
  	&.cb-wm-sub-section {
  		background-color: @access-color;
  	}
  }
  .cb-program-color{
  	color: @access-color !important;
  }
  .cb-program-bg{
  	background-color: @access-color !important;
  }
  .cb-panel-title {
    border-top-color: @access-color;
    color: @access-color;
  }
  .cb-key-benefit {
		.cb-block-img {
			.cb-glyph {
				color: @access-color;
				&.cb-glyph-circular {
					&::after {
						background-color: @access-color;
					}
				}
			}
		}
  }
	.cb-procedures {
		.cb-block-img {
			.cb-glyph-numeric {
				color: @access-color;
				border-color: @access-color;
			}
		}
	}
	.cb-testimonial-full,
	.cb-testimonial-half,
	.cb-testimonial {
		.cb-block-img {
		  .cb-glyph {
		  	&::after {
					background-color: @access-color;
		  	}
		  }
		}
	}
	.cb-comparison-block {
		.cb-comparison-vs {
			&.hidden-xs {
				color: @access-color;
			}
		  &.hidden-sm {
		    background-color: @access-color;
		  }
		}
		&.cb-comparison-sec{
			// mobile
			@media (max-width: @screen-xs-max) {
				border-top-color: @access-color;
			}
		}
	}
	.cb-media-full {
		.cb-block-img {
			.cb-glyph {
				&::after{
					background-color: @access-color;
				}
		  }
		}
		.cb-block-content{
			background-color: @access-color;
		}
	}
	.cb-media-half {
		.cb-block-img {
		  .cb-glyph {
		  	&::after {
					background-color: @access-color;
		  	}
		  }
		}
	}
}

// ------------------------------------ higher-education
[data-cb-program="higher-education"],
.cb-higher-education-program,
.cb-higher-education-color {
	.cb-glyph-multi {
		.glyph-multi-bk-color (@white-color, @black-color, @higher-education-color);
	}
  .cb-nav-card{
    h2, .h2,
    h3, .h3{
      border-bottom-color: @higher-education-color;
    }
  }
  .cb-hero {
  	&:not(.cb-hero-light) {
			background-color: @higher-education-color;
		}
		@media (max-width: @screen-xs-max) {
			background-color: @white-color;
			.content {
				h1, .h1,
				h2, .h2,
				h3, .h3,
				h4, .h4{
					&:first-child {
				    border-top-color: @higher-education-color;
					}
				}
			}
		}
		&.cb-hero-light{
		  .content {
				h1, .h1,
				h2, .h2,
				h3, .h3,
				h4, .h4{
					&:first-child {
				    border-top-color: @higher-education-color;
					}
				}
			}
		}
  }
  .cb-welcome-mat {
  	&.cb-wm-standard,
  	&.cb-wm-article,
  	&.cb-wm-section,
  	&.cb-wm-sub-section {
  		background-color: @higher-education-color;
  	}
  }
  .cb-program-color{
  	color: @higher-education-color !important;
  }
  .cb-program-bg{
  	background-color: @higher-education-color !important;
  }
  .cb-panel-title {
    border-top-color: @higher-education-color;
    color: @higher-education-color;
  }
  .cb-key-benefit {
		.cb-block-img {
			.cb-glyph {
				color: @higher-education-color;
				&.cb-glyph-circular {
					&::after {
						background-color: @higher-education-color;
					}
				}
			}
		}
  }
	.cb-procedures {
		.cb-block-img {
			.cb-glyph-numeric {
				color: @higher-education-color;
				border-color: @higher-education-color;
			}
		}
	}
	.cb-testimonial-full,
	.cb-testimonial-half,
	.cb-testimonial {
		.cb-block-img {
		  .cb-glyph {
		  	&::after {
					background-color: @higher-education-color;
		  	}
		  }
		}
	}
	.cb-comparison-block {
		.cb-comparison-vs {
			&.hidden-xs {
				color: @higher-education-color;
			}
		  &.hidden-sm {
		    background-color: @higher-education-color;
		  }
		}
		&.cb-comparison-sec{
			// mobile
			@media (max-width: @screen-xs-max) {
				border-top-color: @higher-education-color;
			}
		}
	}
	.cb-media-full {
		.cb-block-img {
			.cb-glyph {
				&::after{
					background-color: @higher-education-color;
				}
		  }
		}
		.cb-block-content{
			background-color: @higher-education-color;
		}
	}
	.cb-media-half {
		.cb-block-img {
		  .cb-glyph {
		  	&::after {
					background-color: @higher-education-color;
		  	}
		  }
		}
	}
}
//----------- End brand colors