
// @fontface decleration
//
//Usage:
// @font-face {
//   .fontface(FontName1, FontFile1, fontsvgid);
// }
.fontface(@fontname, @fontfile, @fontsvgid) {
  font-family: '@{fontname}';
  src: url('@{cb-font-path}@{fontfile}.eot');
  src: url('@{cb-font-path}@{fontfile}.eot?#iefix') format('embedded-opentype'),
       url('@{cb-font-path}@{fontfile}.woff') format('woff'),
       url('@{cb-font-path}@{fontfile}.ttf') format('truetype'),
       url('@{cb-font-path}@{fontfile}.svg#@{fontsvgid}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fontface2(@fontname, @fontfile, @fontsvgid, @weight, @style: normal) {
  font-family: '@{fontname}';
  src: url('@{cb-font-path}@{fontfile}.eot');
  src: local('@{fontname}'), local('@{fontsvgid}'),
      url('@{cb-font-path}@{fontfile}.eot?#iefix') format('embedded-opentype'),
      url('@{cb-font-path}@{fontfile}.woff2') format('woff'),
      url('@{cb-font-path}@{fontfile}.woff') format('woff'),
      url('@{cb-font-path}@{fontfile}.ttf') format('truetype'),
      url('@{cb-font-path}@{fontfile}.svg#@{fontsvgid}') format('svg');
  font-weight: @weight;
  font-style: @style;
}


// Better font-rendering on OSX

// .dark-on-light {
//   .font-smoothing(off);
// }

// .light-on-dark {
//   .font-smoothing();
// }
.font-smoothing(@value: on) {
  & when (@value = on) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
   & when (@value = off) {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

.serifaStd-light(){
  font-family: @font-family-SerifaStd-Light;
}
.serifaStd-roman(){
  font-family: @font-family-SerifaStd-Roman;
}
.serifaStd-bold(){
  font-family: @font-family-SerifaStd-Bold;
}
.helvetica-bold(){
  font-family: @font-family-Helvetica-Bold;
  font-weight: 700;
}
.helvetica-regular(){
  font-family: @font-family-Helvetica-Regular;
}
.helveticaNeue-bold(){
  font-family: @font-family-HelveticaNeue-Bold;
  font-weight: 700;
}
.helveticaNeue-regular(){
  font-family: @font-family-HelveticaNeue-Regular;
}
//------ Brand
.roboto(){
  font-family: @font-family-Roboto;
}
.roboto-bold(){
  // font-family: @font-family-Roboto;
  // font-weight: bold;
  font-family: @font-family-Roboto-Bold;
}
.roboto-italic(){
  font-family: @font-family-Roboto-Italic;
}
.roboto-slab(){
  font-family: @font-family-RobotoSlab;
}
.roboto-slab-bold(){
  // font-family: @font-family-RobotoSlab;
  // font-weight: bold;
  font-family: @font-family-RobotoSlab-Bold;
}
.roboto-slab-light() {
  font-family: @font-family-RobotoSlab-Light;
}