//
// Resource
// --------------------------------------------------

.tagSize(){
  @size: 32px;

  display: block;
  overflow: visible;
  width: calc(@size + @cb-glyph-icon-gutter);
  height: calc(@size + @cb-glyph-icon-gutter);
}

.cb-resource {
  padding: 0;
  margin: 0;
  list-style: none;
  .cb-glyph-circular {
    &::before {
      color: @cb-blue-link-color;
    }
    &::after {
      background-color: transparent;
    }
  }
  // icon effect
  &.cb-resource-module {
    &:hover {
      .cb-glyph-circular {
        &::before {
          color: @white-color;
        }
        &::after {
          background-color: @cb-blue-link-color;
        }
      }
    }
  }

  .cb-resource-item {
    &:hover {
      & > .cb-resource-ico:first-child {
        .cb-glyph-circular {
          &::before {
            color: @white-color;
          }
          &::after {
            background-color: @cb-blue-link-color;
          }
        }
      }
    }
  }

  .cb-resource-ico-sm{
    font-size: @font-size-base;
  }

  .cb-resource-ico-md {
    font-size: @font-size-base;
  }

  // Standard icon
  .cb-resource-ico-std{
    .cb-glyphs();
    color : @cb-blue-link-color;

    &::before {
      content: "\e914";
    }
  }

  // Bulk icon, download
  .cb-resource-ico-download{
    .cb-glyphs();

    &::before {
      content: "\e902";
    }
  }

  // Bulk icon, view
  .cb-resource-ico-view{
    .cb-glyphs();

    &::before {
      content: "\e914";
    }
  }

  // Educators icon
  .cb-resource-ico-edu {
    .cb-glyphs();
    color: @cb-base-font-color;

    &::before {
      content: "\e917";
    }
  }

  // Series icon
  .cb-resource-ico-series {
    .cb-glyphs();
    color: @cb-base-font-color;

    &::before {
      content: "\e943";
    }
  }

  // resource title
  .cb-resource-title{
    margin: 0;
		.roboto-bold();
    font-size: 1rem;
    line-height: 1.125em;
    color: @cb-blue-link-color;
  }

  // info line
  .cb-resource-info{
    width: 100%;
    margin-top: 6px;
    margin-bottom: 18px;
    line-height: 0.8em;
    display: inline-block;
    span:not([class^="cb-resource-ico-"]),
    span:not([class^="cb-glyph"]) {
			.roboto-bold();
      font-size: 0.75rem;
      line-height: 1.5em;
      text-transform: capitalize;
    }
    span[class^="cb-resource-ico-"],
    span[class^="cb-glyph"] {
      font-size: 0.6875rem;
      &::before{
        padding-left: 3px;
      }
    }

    span[class^="cb-glyph"],
    span[class^="cb-resource-ico-"] {
      .cb-glyphs();
    }
    span{
      &:not(:last-child){
        &::after {
          content: '';
          border-right: 1px solid @gray-corporate-border;
          padding-left: 8px;
          display: inline-block;
          width: 1px;
          position: relative;
          top: 1px;
          height: 11px;
        }
      }
      &:not(:first-child){
        padding-left: 3px;
      }
    }
  }

  // description paragraph
  .cb-resource-desc {
    margin: 0;
    margin-top: 6px;
    .roboto();
    font-size: 0.8125rem;
    line-height: 1.5em;
  }

  // resource items
  .cb-resource-item {
    display: inline-block;
    width: 100%;
    line-height: 1em;
    border-bottom: 1px solid @gray-corporate-border;
    .cb-resource-ico,
    .cb-resource-body{
      float: left;
    }
    &:first-of-type {
      border-top: 4px solid @blue-corporate-color;
      padding-top: 20px;
    }
    &:last-child {
      border-bottom: 0;
    }
    &:first-child:last-child {
      border-bottom: 1px solid @gray-corporate-border;
    }
    &:not(:first-of-type) {
      padding-top: 12px;
    }
    .cb-resource-body {
      font-size: @font-size-large;
      padding-right: 20px;
    }
  }

  .cb-resource-download{
    margin-top: 6px;
    margin-bottom: 18px;
    line-height: 0.8em;
    display: inline-block;
    .cb-resource-download-all,
    .cb-resource-see-more {
      .roboto-bold();
      font-size: 0.75rem;
      line-height: 1.5em;
      color: @cb-blue-link-color;
      float: left;
    }
    .cb-resource-download-all{
      &::after {
        content: '';
        border-right: 1px solid @gray-corporate-border;
        padding-right: 8px;
      }
    }
    a:first-child:last-child{
      &::after {
        border-right: 0;
        padding-right: 0;
      }
    }
  }

  // Small Resources
  .cb-resource-ico {
    width: calc(32px + @cb-glyph-icon-gutter);
  }
  .cb-resource-ico-std{
    font-size: @font-size-large;
   .tagSize();
  }
  .cb-resource-body {
    width: calc(100% - (32px + @cb-glyph-icon-gutter));
  }


  // resource Modules
  &.cb-resource-module {
    border: 1px solid @gray-corporate-border;
    border-top: 4px solid @blue-corporate-color;
    padding: 26px 20px 18px 20px;
    display: inline-block;

    .cb-resource-title-block{
      width: 100%;
      float: left;
      .cb-resource-ico{
        float: left;
        width: calc(32px + @cb-glyph-icon-gutter);
      }
      .cb-resource-title{
        width: calc(100% - (32px + @cb-glyph-icon-gutter));
        float: right;
      }
    }
    .cb-resource-desc {
      width: 100%;
      float: left;
    }
    .cb-resource-info {
      margin-bottom: 0;
    }
  }

  // resource Modules
  &.cb-resource-bulk-detail {
    .cb-resource-ico-download{
      color: @cb-base-font-color;
    }
    .cb-resource-item {
      border-top: 1px solid @gray-corporate-border;
      padding-top: 18px;
    }
    .cb-resource-title{
      color: @cb-base-font-color;
    }
    .cb-resource-info{
      width: auto !important;
      float: left;
    }
    .cb-resource-download{
      width: auto;
      float: right;
      .cb-resource-see-more{
        display: block;
        margin-left: 8px;
        &.open {
          &::after {
            content: "See Less -";
          }
        }
        &::after{
          content: "See More +";
        }
      }
    }
    .cb-resource-download-list {
      margin-bottom: 0;
      float: left;
      margin-top: 18px;
      background-color: @digital-field-gray;
      border-top: 1px solid @gray-corporate-border;
      padding-left: calc(32px + @cb-glyph-icon-gutter);
      &.collapse{
        display: none;
        visibility: hidden;
        margin-top: 0;
        width: 100%;
        &.in{
          display: block;
          visibility: visible;
        }
      }
      .cb-resource-item {
        border: 0;
        border-bottom: 1px solid @gray-corporate-border;
        &:last-child {
          border-bottom: 0;
        }
        .cb-resource-title{
          color: @cb-blue-link-color;
        }
        .cb-resource-ico {
          width: calc(32px + @cb-glyph-icon-gutter);
        }
        .cb-resource-body {
          width: calc(100% - (32px + @cb-glyph-icon-gutter));
        }
      }
    }
  }

  //inline resource
  &.cb-resource-inline{
    .roboto-bold();
    &:hover{
      cursor: pointer;
    }
  }

  a[class^="cb-resource-ico"] {
    &:hover,
    &:focus{
      text-decoration: none;
    }
  }
}

div[class*="cb-resource"][class*="cb-resource-module"]:not([class*="col-xs-"]):not([class*="col-sm-"]):not([class*="col-md-"]):not([class*="col-lg-"]){
  width: 100%;
}

// nested resources
.cb-resource-item {
  .cb-resource {
    .cb-resource-item {
      &:first-child:last-child {
        border-bottom: 0;
      }
      .cb-resource-info,
      .cb-resource-download {
        margin-bottom: 15px;
      }
    }
    > .cb-resource-item {
        padding-top: 0;
      }

    &.cb-resource-bulk-detail {
      .cb-resource-item {
        border-top: 0;
      }
    }
  }
}
// modal with resource
.cb-resource-modal {
  .modal-header{
    padding-bottom: 0;
  }
  .modal-body{
    padding-top: 0;
  }
  .cb-resource-bulk-detail {
    .cb-resource-item {
      border-top: 0 !important;
      .cb-resource-body{
        width: calc(100% - 78px);
      }
    }
    & > .cb-resource-item {
      padding-top: 0;
      margin-bottom: 0;
    }
    .cb-resource-download,
    .cb-resource-info{
      margin-bottom: 18px;
    }
  }
  .modal-content button.close {
    float: left;
  }
}

// For glyph update
.cb-resource-ico {
  a {
    font-size: 0;
    &.cb-resource-ico-download,
    &.cb-resource-ico-view,
    &.cb-glyph {
      &::before {
        font-size: @font-size-base;
      }
    }
    &.cb-glyph-circular {
      width: calc (unit(@font-size-base, rem) * 2);
      height: 32px;
      &::after{
        font-size: @font-size-base;
      }
    }
  }
}

// -------- resource download plugin
.cb-resource {
  &.cb-resource-plugin {
    &.cb-resource-bulk-detail {
      span.cb-resource-ico-download{
        color: @cb-base-font-color;
      }
      a.cb-resource-ico-download{
        color: @cb-blue-link-color;
      }
    }
    &.cb-resource-no-plugin,
    .cb-resource-no-plugin {
      .cb-resource-download-all {
        display: none;
        visibility: hidden;
      }
      .cb-resource-ico-download {
        color: @cb-base-font-color !important;
      }
    }
    .cb-resource-bulk-detail {
      position: relative;
      .cb-loader {
        &.cb-loader-md{
          display: none;
          position: absolute;
          left: 1px;
          top: 1px;
        }
      }
    }
  }
}
