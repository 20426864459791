//
// Tables
// --------------------------------------------------
.table{
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        font-size: @font-size-base;
        line-height: @line-height-base;
        border: 1px solid @table-border-color;
        vertical-align: top;
        padding: @cb-table-padding;
        min-width: 80px;
        p {
          font-size: @font-size-base;
          line-height: @line-height-base;
          margin-bottom: 0;
        }
        span {
          &:not(.cb-glyph):not(.cb-glyph-multi):not(.cb-select) {
            font-size: @font-size-base;
            line-height: @line-height-base;
            margin-bottom: 0;
          }
          &.cb-select {
            span {
              line-height: 3em!important;
            }
          }
        }
        a:not(.btn):not(.dropdown-toggle):not([role="menuitem"]) {
          text-decoration: underline;
        }

        //  Sort
        &.cb-table-sort {
          vertical-align: middle;
          padding: 5px 10px;
          border-left-width: 0;
          border-right-width: 0;
          background: @white-color;
          a {
            text-decoration: none!important;
            display: block;
            &:hover,
            &:focus,
            &:active {
              text-decoration: none!important;
            }
            .cb-sort-desc,
            .cb-sort-asc {
              color: @cb-blue-link-color;
            }
            .cb-sort {
              color: @black-color;
            }
          }
          &:hover {
            background-color: @blue-highlight-color;
            cursor: pointer;
          }
          &:first-of-type {
            border-left-width: 1px;
          }

          &:last-of-type {
            border-right-width: 1px;
          }
        }
        &.cb-table-cell-highlight {
          background-color: @yellow-highlight-color;
        }
        .cb-table-thumbnail {
          width: 72px;
          height: 72px;
          .border-box();
          border: 1px solid @table-border-color;
          display: inline-block;
          background-color: @gray3-corporate-color;
          position: relative;
          overflow: hidden;
          float: left;
          margin-right: 8px;
          img {
            width: 100%;
            z-index: 2;
            position: absolute;
          }
          &::before {
            content: "?";
            .roboto-bold();
            color: @table-border-color;
            font-size: 1.5rem;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &+p {
            display: inline-block;
            float: left;
          }
        }
        .btn {
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  > thead {
    background: @gray1-corporate-color;
    color: @digital-field-gray;
    > tr {
      > th,
      > td {
        .roboto-bold();
        border-bottom-width: 1px;
      }
    }
    &.cb-table-headspan {
      > tr {
        &+ tr {
          > th,
          > td {
            .roboto();
            font-weight: 400;
            min-width: 50px;
            border-bottom: 0;
          }
        }
      }
    }
  }

  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 1px solid @table-border-color;
      }
    }
  }

  > tbody {
    + tbody {
      border-top: 0;
    }
    td,
    th {
      &.cb-table-header-cell {
        .roboto-slab-bold();
        border-bottom-width: 1px;
        background: @digital-field-gray;
        h3 {
          margin-bottom: 0;
          font-size: @font-size-base;
          line-height: @line-height-base;
        }
      }
    }
  }

  > caption {
    .roboto-bold();
    color: @cb-base-font-color;
    font-family: @cb-font-family-h3;
    font-size: @font-size-h3;
    line-height: @cb-line-height-h3;
    padding: 23px 16px 24px;
    border: 1px solid @table-border-color;
    border-bottom: 0;
  }

  tr {
    &.cb-row-striped{
      td,
      th{
        background-color: @table-bg-accent;
      }
    }
  }

  // Zebra-striping-reverse
  &.cb-table-striped-reverse {
    > tbody > tr:nth-child(even) {
      > td,
      > th {
        background-color: @table-bg-accent;
      }
    }
  }

  td,
  th{
    // This is leftover from old versions
    &.cb-callout{
      background: @cb-table-bk-callout-color;
      color: @cb-base-font-color;
      .roboto-bold();
      span {
        .roboto();
        color: @gray40-color;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.7142857142857142em;
      }
    }
    // accordion cell
    &.cb-table-exp {
      background: @digital-field-gray;
      text-align: center;
      min-width: 32px;
      width: 32px;
      &.cb-open {
        border-bottom: 0;
      }
      a {
        color: @cb-base-font-color;
        text-decoration: none !important;
        .cb-glyph {
          font-size: @font-size-base;
        }
        &:hover,
        &:focus,
        &:active {
          text-decoration: none!important;
        }

      }
    }
    //  Select  row
    &.cb-table-select {
      background:  @digital-field-gray;
      text-align: center;
      min-width: 32px;
      width: 32px;
      font-size: @font-size-base;
      .checkbox {
        label {
          padding: 0;
          input[type=checkbox] {
            margin: 0;
            &+span {
              margin: 0;
              &::before {
                background-color: @white-color;
                right: -1px;
                width: 15px;
                height: 15px;
              }
            }
            &:checked {
              &+span {
                &::before {
                  color: @blue-accent1-corporate-color;
                }
              }
            }
          }
        }
      }
    }
    .cb-table-exp-cell {
      display: none;
      &.open {
        display: block;
      }
    }
  }

  tbody {
    tr {
      &.cb-table-exp-row {
        display: none;
        td,
        th {
          &.cb-table-exp {
            border-top: 0;
          }
        }
        &.cb-open {
          display: table-row;
        }
      }
      &:last-of-type {
        th,
        td {
          &.cb-table-exp {
            &.cb-open {
              border-bottom: 1px solid @table-border-color;
            }
          }
        }
      }

      // Selected
      &.cb-selected {
        background-color: @blue-highlight-color;
        td,
        th {
          background-color: @blue-highlight-color;
          &.cb-table-select {
            background-color: @cb-btn-blue;
          }
        }
      }
    }
  }
  thead {
    // Selected
    .cb-selected {
      td,
      th {
        &.cb-table-select {
          background-color: @cb-btn-blue;
        }
      }
    }
  }

  &.cb-no-table-border{
    > caption {
      border: 0;
      padding: 24px;
    }
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-right: 0;
          padding-top: 12px;
          &:first-child {
            border-left: 0;
          }
        }
        &:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
  &.table-condensed{
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          font-size: @font-size-small;
          line-height: @line-height-small-base;
          p,
          span {
            font-size: @font-size-small;
            line-height: @line-height-small-base;
          }
          &.cb-callout{
            span {
              font-size: 0.75rem;
            }
          }
        }
      }
    }

    > caption {
        padding: @cb-table-padding;
    }
  }

  // -------------- Apricot Style, 3.2.0
  [data-toggle="tooltip"]:not(.cb-no-table-tip) {
    font-size: 0.75rem !important;
    line-height: 1em !important;
    display: inline-block;
    float: right;
    &::before {
      color: @white-color;
    }
    &::after{
      background-color: @gray3-corporate-color;
    }
    &:hover {
      &::after {
        background-color: @blue-accent1-corporate-color;
      }
    }
  }
  thead {
    [data-toggle="tooltip"]:not(.cb-no-table-tip) {
      &::before {
        color: @gray1-corporate-color;
      }
      &::after{
        background-color: @white-color;
      }
      &:hover {
        &::before {
          background-color: @blue-accent1-corporate-color;
          color: @white-color;
        }
      }
    }
  }

  // ------  Border combinations
  &.cb-no-border,
  .cb-no-border {
    border: 0;
    td,th {
      border: 0;
    }
  }

  &.cb-no-border-left,
  .cb-no-border-left {
    border-left-width: 0;
    td,th {
      border-left-width: 0;
    }
  }

  &.cb-no-border-top,
  .cb-no-border-top {
    border-top-width: 0;
    td,th {
      border-top-width: 0;
    }
  }

  &.cb-no-border-bottom,
  .cb-no-border-bottom {
    border-bottom-width: 0;
    td,th {
      border-bottom-width: 0;
    }
  }

  &.cb-no-border-right,
  .cb-no-border-right {
    border-right-width: 0;
    td,th {
      border-right-width: 0;
    }
  }

  &.cb-no-border-left-right,
  .cb-no-border-left-right {
    border-left-width: 0;
    border-right-width: 0;
    td,th {
      border-left-width: 0;
      border-right-width: 0;
    }
  }

  // -------------- Vertical text alignment classes
  &.cb-vertical-default{
    .table-vertical-align(baseline);
  }
  .cb-vertical-default {
    .cell-vertical-align(baseline);
  }

  &.cb-vertical-top{
    .table-vertical-align(top);
  }
  .cb-vertical-top {
    .cell-vertical-align(top);
  }

  &.cb-vertical-bottom{
    .table-vertical-align(bottom);
  }
  .cb-vertical-bottom {
    .cell-vertical-align(bottom);
  }

  &.cb-vertical-text-top{
    .table-vertical-align(text-top);
  }
  .cb-vertical-text-top {
    .cell-vertical-align(text-top);
  }

  &.cb-vertical-text-bottom{
    .table-vertical-align(text-bottom);
  }
  .cb-vertical-text-bottom {
    .cell-vertical-align(text-bottom);
  }
}

// -------------- Apricot Style, 3.2.0

.cb-table-header {
  margin: 12px 0;
  h3 {
    display: inline-block;
    margin-bottom: 0;
  }
  .cb-table-header-actions {
    display: inline-block;
  }
}
.cb-table-record-summary {
  font-style: italic;
  display: inline-block;
  margin-bottom: 0;
}
.cb-table-action-bar {
  padding: 24px 16px;
  background-color: @digital-field-gray;
  .cb-select-container {
    span.cb-select>span {
      background-color: @white-color;
    }
  }
  .cb-table-action-bar-actions {
    display: inline-block;
    .cb-dropdown {
      span{
        .roboto-slab-bold();
      }
    }
  }
  .cb-table-action-bar-btn {
    font-size: 0;
    text-align: right;
    .btn-secondary-group {
      margin-top: 0;
      display: inline-block;
    }
  }
  &+.cb-filter-container {
    &.in {
      border-top: 1px solid @table-border-color;
      border-bottom: 0 !important;
    }
  }
  input[type="text"] {
    background-color: @white-color;
  }
}
.cb-table-sub-header {
  margin-bottom: 0;
  .roboto-bold();
}
.cb-table-download,
.cb-table-print {
  .roboto-slab-bold();
  color: @cb-base-font-color;
  display: inline-block;
  .cb-glyph {
    margin-left: 8px;
  }
}
.table-striped {
  > tbody > tr{
    &:nth-of-type(even) {
      background-color: @white-color;
    }
    &:nth-of-type(odd) {
      background-color: @table-bg-accent;
    }
  }
}