//
// Logo Glyphicons
// --------------------------------------------------

.cb-glyph-logo {
	position: relative;
	display: inline-block;
	background-image: none!important;

  .cb-glyphs-logo();
	&::before {
	  position: absolute;
	  top: 0;
	  left: 0;
	}

	// Logo
	&.cb-logo-acorn {
		.glyph-logo-content("\e901");
	}
	&.cb-logo {
		.glyph-logo-content("\e902");
	}
	&.cb-programs-access {
		.glyph-logo-content("\e903");
	}
	&.cb-programs-accuplacer {
		.glyph-logo-content("\e904");
	}
	&.cb-programs-ap {
		.glyph-logo-content("\e905");
	}
	&.cb-programs-clep {
		.glyph-logo-content("\e906");
	}
	&.cb-programs-css-profile {
		.glyph-logo-content("\e907");
	}
	&.cb-programs-powerfaids {
		.glyph-logo-content("\e908");
	}
	&.cb-programs-pre-ap {
		.glyph-logo-content("\e909");
	}
	&.cb-programs-psat-8-9 {
		.glyph-logo-content("\e90a");
	}
	&.cb-programs-psat-10 {
		.glyph-logo-content("\e90b");
	}
	&.cb-programs-psat-nmsqt {
		.glyph-logo-content("\e90c");
	}
	&.cb-programs-sat {
		.glyph-logo-content("\e90d");
	}
	&.cb-programs-springboard {
		.glyph-logo-content("\e90e");
	}
	&.cb-search {
		.glyph-logo-content("\e90f");
	}
	&.cb-programs-college-planning {
		.glyph-logo-content("\e900");
	}
}