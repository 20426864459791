//
// Menu Bar
// --------------------------------------------------

.cb-menu-bar {
  position: relative;
  border-bottom: 1px solid @gray-corporate-border;
  .border-box();
  background-color: @gray1-corporate-color;

  .cb-desktop-menu-bar {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        list-style-image: none;
        background: none;
        text-align: center;
        line-height: 1.25em;
        flex-grow: 1;
        cursor: pointer;
        position: relative;
        border-left: solid 1px @gray2-corporate-color;
        &:last-child {
          border-right: solid 1px @gray2-corporate-color;
        }
        a {
          .roboto-bold();
          color: @white-color;
          text-decoration: none;
          padding: 1.5em 0.25em;
          display: inline-block;
          line-height: 1.5em;
          &:hover,
          &:active {
            text-decoration: underline;
          }
        }
        &:hover {
          a {
            text-decoration: underline;
          }
        }
        &.active {
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .cb-mobile-menu-bar{
    height: 56px;
    .row {
      margin: 0;
    }
    .dropdown {
      float: left;
      margin-top: 17px;
      .cb-down,
      .cb-up {
        margin-left: 10px;
        color: @white-color;
      }
      .dropdown-toggle {
        color: @white-color;
        &:hover,
        &:focus {
          color: @white-color;
          border-bottom: 3px solid @white-color;
        }
      }
    }
  }

  &.sticky-menu-bar {
    position: fixed;
    z-index: 904;
    left: 0;
    top: 0;
    background: @gray1-corporate-color;
    width: 100%;
    border-left: 0;
    border-right: 0;
  }
}
