//
// Multiselector
// --------------------------------------------------

.cb-multiselector {
	display: flex;
	flex-flow: wrap;
	flex-direction: row;
	height: 250px;
	label {
		font-size: 0.75rem;
		line-height: 1em;
		margin-bottom: 12px;
	}
	.cb-multiselector-sel {
		width: calc(50% - 36px);
		select {
			height: calc(100% - 30px);
		}
	}
	.cb-multiselector-nav {
		width: 72px;
		padding-left: 20px;
		padding-top: 31px;
		.cb-glyph {
			&+ .cb-glyph {
				margin-top: 12px;
			}
			&:hover,
			&:focus {
				&.cb-glyph-circular {
					&::after {
    				background-color: @blue-accent1-corporate-color;
					}
				}
			}
		}
	}
	// Accessibility
	&.cb-multiselector-acc{
		.cb-multiselector-sel {
			option {
				&:hover,
				&:focus {
					background-color: @gray4-corporate-color;
					outline-width: medium;
					outline: auto !important;
					outline-style: dashed;
				}
			}
		}
	}

  // mobile
  @media (max-width: @screen-xs-max) {
  	flex-direction: column;
  	height: auto;
		.cb-multiselector-sel {
			width: 100%;
			height: 250px;
		}
		.cb-multiselector-nav {
			width: 100%;
			height: 68px;
			padding: 18px 0 0;
			.cb-glyph {
				float: left;
				&.cb-right {
  				&::before {
						content: "\e913";
					}
				}
				&.cb-left {
  				&::before {
						content: "\e950";
					}
				}
				&:last-of-type {
					margin-left: 12px;
					margin-top: 0;
				}
			}
		}
  }
}

.cb-multiselector-dropdowns {
	p {
		margin-bottom: 24px;
	}
	.cb-multiselector-source {
		width: calc(50% - 36px);
		// mobile
  	@media (max-width: @screen-xs-max) {
  		width: 100%;
  	}
	}
}