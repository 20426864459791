//
// CB Datepicker, jQuery UI
// --------------------------------------------------

.cb-date-picker {
  .form-control {
    width: 85%;
    display: inline-block;
  }
  .date-picker-control:link,
  .date-picker-control:visited {
    background: none;
    outline: 0;
    span {
      .cb-glyphs();
      color: @cb-blue-link-color2;
      font-size: @font-size-base;
      &::before {
        content: "\e907";
      }
    }
  }

  input[type="date"]{
    border: 1px solid @input-border;
    border-radius: @cb-form-control-radius;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @input-color;
    width: 93%;
  }
}

.ui-widget {
  .helveticaNeue-regular();
  font-size: @font-size-base !important;
}

.ui-datepicker {
  width: 288px;
  &.ui-widget-content {
    border-radius: 0 !important;
    background: none;
    background-color: @white-color;
    border-color: @cb-gray-datepicker-border;
    color: @cb-base-font-color;
    padding: 0;
    margin: 0;
    border-bottom: 0;
  }
  .ui-datepicker-header {
    background: none;
    background-color: @white-color;
    color: @cb-base-font-color;
    border: 0;
    padding: 16px 0;
    a {
      border-radius: 0;
    }
  }
  .ui-corner-all {
    background: none;
    a {
      background: none;
      &:hover,
      &:focus {
        background: none;
        border: 0;
      }
    }
    .ui-datepicker-prev,
    .ui-datepicker-next{
      top: auto;

    }
    .ui-state-hover {
      background: none;
      border: 0;
      .ui-icon {
        background: none;
      }
      &.ui-datepicker-next-hover {
        top: auto;
        // right: 1px;

        right: 41px;
      }
      &.ui-datepicker-prev-hover {
        top: auto;
        // left: 31px;
        left: 41px;
      }
    }
    .ui-icon {
      display: block;
      background: none;
      .cb-glyphs();
      color: @cb-base-font-color;
      width: 16px;
      height: 16px;
      font-size: 0;
      -webkit-font-smoothing: antialiased;
      text-indent: 0;
      &.ui-icon-circle-triangle-e {
        &::before {
          font-size: @font-size-base;
          content: "\e940";
        }
      }
      &.ui-icon-circle-triangle-w {
        &::before {
          font-size: @font-size-base;
          content: "\e921";
        }
      }
      &:hover {
        color: @cb-blue-light-color;
        cursor: pointer;
      }
    }
    &.ui-datepicker-prev {
      left: 41px;
    }
    &.ui-datepicker-next {
      right: 41px;
    }
    &.year {
      &.ui-datepicker-prev,
      &.ui-datepicker-prev-hover {
        // left: 2px;
        left: 6px;
      }
      &.ui-datepicker-next,
      &.ui-datepicker-next-hover {
        // right: 2px;
        right: 6px;
      }
      .ui-icon-circle-triangle-e {
        &::before {
          content: "\e911";
        }
      }
      .ui-icon-circle-triangle-w {
        &::before {
          content: "\e910";
        }
      }
    }
    .ui-state-hover {
      &.year {
        span {
          &::before {
            color: @cb-blue-light-color !important;
          }
        }
      }
    }
  }
  table {
    margin: 0;
    font-size: 0.75rem;
    &.ui-datepicker-calendar {
      thead {
        th,
        td {
          border: 1px solid @cb-gray-datepicker-border;
          border-left: 0;
          border-right: 0;
          border-spacing: 0;
          padding: 0;
          height: 24px;
        }
      }
      tbody {
        td {
          border-spacing: 0;
          padding: 0;
          border: 1px solid @cb-gray-datepicker-border;
          width: 40px;
          height: 40px;
          display: table-cell;
          &:first-child {
            border-left: 0px;
          }
          a {
            border: 0;
            background: @white-color;
            color: @cb-base-font-color;
            text-align: center;
            padding: 0;
            line-height: 40px;
            width: 40px;
            height: 40px;
            &:hover,
            &:focus,
            .ui-state-hover {
              background-color: @cb-blue-link-color2;
              color: @white-color;
              font-weight: 700;
            }
          }
          &.ui-datepicker-unselectable,
          &.ui-state-disabled {
            background-color: @cb-gray-datepicker-unavailable;
            opacity: 1;
          }
          &.ui-datepicker-current-day {
            background-color: @cb-blue-light-color;
            font-weight: 700;
          }
          .ui-state-default{
              border: 0;
              background: none;
              text-align: center;
              font-weight: 400;
              color: @cb-base-font-color;
          }
          &.ui-datepicker-days-cell-over {
            a {
              background: @cb-blue-link-color2;
              color: @white-color;
            }
          }
          &.ui-datepicker-current-day,
          &.ui-datepicker-today {
            background-color: @cb-blue-dark-color;
            color: @white-color;
            a {
              background: none;
              color: @white-color;
              font-weight: 700;
            }
            .ui-state-default{
              color: @white-color;
              font-weight: 700;
            }
          }
          &.ui-datepicker-current-day {
            background-color: @cb-blue-link-color2;
          }
        }
      }
    }
  }
}

.ui-datepicker-trigger {
  margin-left: 12px;
  margin-top: -20px;
  outline: 0;
  width: 0 !important;
  height: 0;
  border: 0;
  border-image-width: 0;
  font-size: 0;
  color: @white-color;
  &::before {
    color: @cb-blue-link-color2;
    .cb-glyphs();
    font-size: @font-size-base;
    content: "\e907";
  }
  &:hover {
    color: @cb-blue-dark-color;
    cursor: pointer;
  }
}
