//
// Anchor Menu
// --------------------------------------------------

.cb-anchor-menu {
  position: relative;
  border: 1px solid @gray-corporate-border;
  border-right: 0;
  border-left: 0;
  .border-box();
  .row {
    margin-left: 0;
    @media (min-width: @screen-sm-min) {
    }
    @media (min-width: @screen-md-min) {
      margin-right: 0;
    }
    @media (min-width: @screen-lg-min) {
      margin-right: -30px;
    }
  }
  .cb-desktop-anchor {
    ul {
      margin: 0;
      padding: 7px 0;
      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        list-style-image: none;
        background: none;
        float: left;
        white-space: nowrap;
        &:not(.cb-cta-btn){
          display: table;
          padding: 12px 20px;
          &:first-child {
            padding-left: 0;
          }
          a{
            display: table-cell;
            vertical-align: middle;
            .roboto-bold();
            font-size: @font-size-small;
            line-height: @line-height-small-base;
            &:focus{
              .cb-focus();
            }
          }
        }
      }
    }
  }
  .cb-mobile-anchor{
    padding-top: 7px;
    padding-bottom: 7px;
    height: 62px;
    .row {
      margin-right: 0;
    }
    .dropdown {
      float: left;
      margin-top: 12px;
      .cb-down,
      .cb-up {
        margin-left: 10px;
      }
    }
  }

  .cb-cta-btn {
    float: right !important;
    display: none;
    transition: visibility 0s, opacity 1s ease;
    margin-left: 10px !important;
    .btn {
      border: 0;
      font-size: @font-size-small;
      line-height: @line-height-small-base;
      .roboto-slab-bold();
      background: @yellow-corporate-color;
      color: @black-corporate-color;
      box-shadow: none;
    }
  }
  &.sticky-anchor-menu {
    position: fixed;
    z-index: 904;
    left: 0;
    top: 0;
    background: @white-color;
    width: 100%;
    border-left: 0;
    border-right: 0;
    .cb-cta-btn {
      display: block !important;
    }
  }
  &.cb-anchor-btn-show{
    .cb-cta-btn {
      display: block !important;
    }
  }
}

.cb-anchor-no-focus{
  outline: 0 !important;
}
