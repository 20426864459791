// Alerts

.cb-alert-variant(@background; @border; @text-color; @text-header; @glyph) {
  background-color: @background;
  border: 0;
  border-top: 4px solid @border;

  &.alert-dismissible{
    border: 1px solid @border;
    border-top: 4px solid @border;
  }
  color: @text-color;
  &.cb-alert-heading {
    h2, h3, h4{
      color: @text-header;
      .roboto-bold();
      font-size: 1.1875rem;
      line-height: 1.6842105263157894em;
      position: relative;
      padding-left: 48px;
      margin-top: 0;
      margin-bottom: 16px;
      z-index: 1;
      &::before {
        .cb-glyphs();
        font-size: 1rem;
        content: @glyph;
        color: @white-color;
        position: absolute;
        z-index: 2;
        left: 8px !important;
        top: 8px !important;
      }
      &::after {
        content: " ";
        position: absolute;
        top:0;
        left:  0;
        z-index: 1;
        display: block;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        background-color: @border;
      }
    }
    p{
      padding-left: 48px;
    }
  }

  hr {
    border-top-color: darken(@border, 5%);
  }
  .alert-link {
    color: @cb-blue-link-color;
  	&:hover{
    	color: @link-hover-color;
  	}
  }
  p, ul {
    font-size: 0.875rem;
    line-height: 1.7142857142857142em;
  }
  .close{
    z-index: 2;
    right: -10px;
    top: -10px;
    width: 32px;
    height: 32px;
    &::before {
      .cb-glyphs();
      font-size: 1rem;
      content: "\e955";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}