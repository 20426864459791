//
// Layout Panels
// --------------------------------------------------

// No longer soppurted in 3.4.0
.cb-panel-gray:not(.cb-layout-panel) {
	background-color: @digital-field-gray;
	padding-top: 48px;
	padding-bottom: 48px;
	@media (max-width: @screen-xs-max) { // mobile
		padding-top: 36px;
		padding-bottom: 36px;
	}
	&+ .cb-panel-gray  {
		padding-top: 0;
	}
}
.cb-panel-white:not(.cb-layout-panel) {
	background-color: @white-color;
	padding-top: 48px;
	padding-bottom: 48px;
	@media (max-width: @screen-xs-max) { // mobile
		padding-top: 36px;
		padding-bottom: 36px;
	}
	&+ .cb-panel-white  {
		padding-top: 0;
	}
}


// Apricot 3.4.0
.cb-layout-panel {
	padding: 48px 0;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	&.cb-layout-panel-condensed {
		padding: 0;
	}
	.cb-panel {
		padding: 0!important;
	}
	
	.cb-panel-info {
		margin: 48px 0;
		.cb-panel-title {
			border: 0;
			padding: 0;
			color: inherit;
			.roboto-slab();
			font-weight: 700;
			font-size: 2rem;
			line-height: 1.125em;
			text-align: center;
			&+.cb-panel-desc {
				margin-top: 12px;
			}
		}
		// New
		&.cb-title-border {
			text-align: center;
			.cb-panel-title {
				border-top: 1px solid @cb-base-font-color;
				display: inline-block;
				padding: 12px 0 0 0;
			}
		}
		.cb-panel-desc {
			.roboto();
			font-size: 1.188rem;
			line-height: 1.263157894736842em;
			@media (max-width: @screen-xs-max) {
				font-size: 1.1875rem;
				line-height: 1.263em;
			}
			font-weight: 400;
			text-align: center;
			margin-bottom: 0;
		}
	}

	// New
	.cb-panel-preamble {
		text-align: center;
		margin: 48px 0 6px 0;
		p {    
			.roboto();
			font-size: 1.1875rem;
			line-height: 1.6666;
			letter-spacing: .05em;
			margin: 0;
		}
		& + .cb-panel-info {
			margin-top: 0;
		}
	}

	.cb-panel-content {
		margin-top: 48px;
		&.cb-panel-media {
			margin-bottom: 48px;
			img {
				max-width: 100%;
			}
		}
	}

	.cb-panel-cta {
		margin-bottom: 48px;
		text-align: center;
	}

	.cb-align-left,
	&.cb-align-left {
		.cb-panel-cta,
		.cb-panel-preamble,
		.cb-panel-info {
			* {
				text-align: left;
			}
			text-align: left;
		}
	}
	.cb-align-right,
	&.cb-align-right {
		.cb-panel-cta,
		.cb-panel-preamble,
		.cb-panel-info {
			* {
				text-align: right;
			}
			text-align: right;
		}
	}

	&.cb-layout-panel-img {
		@media (min-width: @screen-sm-min) { // everything except mobile
			min-height: 800px;
			.cb-testimonial-full {
				min-height: 608px;
			}
		}
		@media (max-width: @screen-xs-max) { // mobile
			min-height: @layout-panel-mobile-img;
			.cb-testimonial-full {
				min-height: 288px;
				padding-top: 0;
				margin-top: -24px;
			}

			&.cb-base-color-xs {
				.cb-testimonial-full {
					.cb-block-title,
					.cb-block-desc {
						color: @cb-base-font-color;
					}
				}
			}
			&.cb-layout-panel-stacked {
				&.cb-layout-panel-img {
					position: relative;
					div {
						position: static;
					}
					.cb-testimonial-full {
						min-height: auto;
						margin: -48px 0 -24px 0;
						.col-xs-12 {
							padding: 0;
						}
						.cb-block-img {
							position: absolute;
							top: 24px;
							left: 24px;
							.cb-glyph,
							.cb-glyph-multi {
								font-size: 4rem;
							}
						}
					}
				}
			}
		}
	}

	&:not(.cb-layout-panel-img) {
		@media (min-width: @screen-sm-min) { // everything except mobile
			.cb-testimonial-full {
				min-height: auto;
				margin-bottom: 48px;
			}
		}
		@media (max-width: @screen-xs-max) { // mobile
			.cb-testimonial-full {
				margin-bottom: 24px;
			}
		}
	}
	&.cb-white-color {
		* {
			color: @white-color;
			border-color: @white-color;
			&.cb-procedures {
				.cb-block-img {
					.cb-glyph-numeric {
						color:  @white-color;
						border-color: @white-color;
					}
				}
			}
			&.btn-secondary {
				border-color: @white-color;
			}
			&.cb-feature-list {
				ul {
					li {
						border-color: @white-color;
						a {
							color: @white-color;
							&:hover,
							&:focus {
								color: @white-color;
							}
							&::after {
								color: @white-color;
							}
						}
						p {
							&::after {
								color: @white-color;
							}
						}

					}
				}
			}
		}
		.cb-item-list {
			&.cb-item-list-showcase {
				.cb-item-type {
					color: @cb-base-font-color;
				}
				a {
					.cb-item-type {
						color: @cb-blue-link-color;
					}

				}
			}
		}
		.cb-panel-info {
			&.cb-title-border {
				.cb-panel-title {
					border-top: 1px solid @white-color;
				}
			}
		}
	}

	.cb-feature-list {
		&:not(.cb-no-accent) {
			&.cb-feature-list-no-border {
				ul {
					li {
						border: 0!important;
						margin: 1px;
						margin-top: 0;
					}
				}
				&.cb-feature-list-row {
					ul {
						li {
							width: calc(50% - 3px);
							&:nth-child(1),
							&:nth-child(2)  {
								margin-top: 1px;
							}
							&:nth-child(even) {
								margin-left: 0;
							}
							&:nth-last-child(1):nth-child(odd) {
								margin-left: 1px;
							}
						}
						// mobile
						@media (max-width: @screen-xs-max) {
							flex-direction: column;
							li {
								width: 100%;
								margin: 1px!important;
								margin-top: 0!important;
								
								&:nth-child(1){
									margin-top: 1!important;
								}
							}
						}
			
					}
					
				}
				&.cb-feature-list-column {
					ul {
						li {
							&:nth-child(1){
								margin-top: 1px;
							}
						}
					}
				}
			}
		}
	}

	.cb-testimonial-half {
		margin-bottom: 48px;
	}

	.cb-item-list {
		&.cb-item-list-showcase {
			&>ul {
				display: flex;
				justify-content: center;
			}
		}
	}

	&:not(.cb-layout-grid) {
		@media (min-width: @screen-md) { //desktop, oversized
			.cb-panel-info {
				margin-left: 16.66666667%;
				width: 66.66666667%;
			}
		}
	}

	&.cb-layout-panel-img {
		.cb-layout-center {
			min-height: 800px;
			@media (max-width: @screen-xs-max) {
				min-height: 384px;
			}
			display: flex;
			align-items: center;
		}
	}

	.cb-flex-row {
		display: flex;
		align-items: center;
	}

	@media (min-width: @screen-sm-min) { // everything except mobile
		.cb-free-form {
			.cb-block-img {
				margin-bottom: 0;
			}
		}
		.cb-testimonial-half {
			.cb-block-img {
				margin-bottom: 0;
			}
		}
	}

	@media (max-width: @screen-xs-max) { // mobile

		&.cb-layout-panel-stacked {
			padding-top: calc(48px + @layout-panel-mobile-img);
			background-size: auto @layout-panel-mobile-img;
			&.cb-layout-panel-condensed {
				padding-top: @layout-panel-mobile-img;
			}
		}

		&.cb-xs-white-color {
			* {
				color: @white-color;
				&.cb-procedures {
					.cb-block-img {
						.cb-glyph-numeric {
							color:  @white-color;
							border-color: @white-color;
						}
					}
				}
				&.btn-secondary {
					border-color: @white-color;
				}
			}
		}
		&.cb-base-color-xs {
			* {
				color: @cb-base-font-color;
				&.cb-procedures {
					.cb-block-img {
						.cb-glyph-numeric {
							color: @cb-base-font-color;
							border-color: @cb-base-font-color;
						}
					}
				}
				&.btn-secondary {
					color: @black-corporate-color;
					border-color: @black-corporate-color;
				}
			}
		}

		.cb-panel-preamble {
			margin-top: 24px;
		}
		.cb-panel-preamble,
		.cb-title-border {
			text-align: left!important;
		}
		.cb-panel-info {
			margin: 24px 0;
			.cb-panel-title,
			.cb-panel-desc {
				text-align: left;
			}
		}
		
		.cb-panel-content {
			margin-top: 24px;
			&.cb-panel-media {
				margin-bottom: 24px;
			}
		}
		.cb-flex-row {
			display: block;
			.cb-panel-content {
				&.cb-panel-media {
					margin-bottom: 0;
				}
			}
		}
	
		.cb-panel-cta {
			text-align: left;
			margin-bottom: 24px;
		}
		
		.cb-key-benefit,
		.cb-procedures,
		.cb-comparison-block,
		.cb-testimonial,
		.cb-free-form,
		.cb-testimonial-half,
		.cb-feature-list {
			margin-bottom: 24px;
		}

		.cb-item-list {
			&.cb-item-list-showcase {
				&>ul {
					&>li{
						margin: 0 0 24px 0;
						width: 100%;
						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}


// Grid overwrite for mobile
@media (max-width: @screen-xs-max) { // mobile
	.cb-layout-panel {
		padding: 24px 0;
		.container {
			padding-left: 24px;
			padding-right: 24px;
		}
		.row {
				margin-left: -12px;
				margin-right: -12px;
		}
		.col-xs-1, 
		.col-xs-10, 
		.col-xs-11, 
		.col-xs-12, 
		.col-xs-2, 
		.col-xs-3, 
		.col-xs-4, 
		.col-xs-5, 
		.col-xs-6, 
		.col-xs-7, 
		.col-xs-8, 
		.col-xs-9 {
			padding-left: 12px;
			padding-right: 12px;
		}
	}
}



// Program Color mixin
.programColor (@class, @accent) {
	.@{class} {
		.cb-feature-list	 {
			&:not(.cb-no-accent) {
				ul {
					li {
						background-color: @accent!important;
					}
				}
			}
		}
	}
}
		

	// Program accent
.programColor(cb-blue-corporate-bg, @blue-accent1-corporate-color);

.programColor(higher-education-program-bg, @higher-education-accent-color);
.programColor(cb-higher-education-program-bg, @higher-education-accent-color);

.programColor(access-program-bg, @access-accent-color);
.programColor(cb-access-program-bg, @access-accent-color);

// K-12
.programColor(cb-accent1-blue-corporate-bg, @k-12-color);
