//
// CB Welcome Mat
// --------------------------------------------------

.cb-welcome-mat {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: @white-color;
  background-color: @gray3-corporate-color;

  // desktop is the default size
  // ------- title
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4{
    .roboto-slab();
    font-size: 2.25rem;
    color: @white-color;
    line-height: 1.1666em;
    margin: -12px 0 12px;
  }
  p {
      margin-bottom: 0;
      .roboto-bold();
    }

  > .container {
      height: 100%;
      > .row {
        height: 100%;
      > .flex-block{
        height: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        .content {
          width: 100%;
          margin: 30px 0;
        }
      }
    }
  }

  // ------- Hero alignment

  &.cb-wm-left{
    text-align: left;
  }

  &.cb-wm-center{
    text-align: center;
  }

  // types
  &.cb-wm-image {
    .flex-block {
      position: relative;
      > .content {
        margin: 0 !important;
        position: absolute;
        bottom: 24px;
      }
    }
  }

  .cb-wm-date,
  .cb-wm-preamble {
    font-size: 1.125rem;
    line-height: 1.6666em;
    margin: 0;
    margin-bottom: 12px !important;
  }

  &.cb-wm-section {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4{
      font-size: 2.25rem;
      line-height: 1.3333333333333333em;
      margin: 0;
    }
    p {
        .roboto();
      }

    > .container {
        > .row {
        > .flex-block{
          .content {
            margin: 48px 0;
          }
        }
      }
    }
  }

  &.cb-wm-sub-section {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4{
      font-size: 1.4375rem;
      line-height: 1.0434782608695652em;
      .roboto-slab-bold();
      margin: 0 0 12px 0;
    }
    p:not(.cb-wm-preamble) {
      display: none;
    }

    > .container {
        > .row {
        > .flex-block{
          .content {
            margin: 24px 0;
          }
        }
      }
    }
    .cb-sibling-menu {
      margin-bottom: 12px;
    }
  }

  &.cb-wm-article {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4{
      font-size: 1.4375rem;
      line-height: 1.0434782608695652em;
      .roboto-slab-bold();
      margin: 0;
    }
    p:not(.cb-wm-preamble) {
      display: none;
    }

    > .container {
        > .row {
        > .flex-block{
          .content {
            margin: 48px 0;
          }
        }
      }
    }
  }

  &.cb-wm-section,
  &.cb-wm-sub-section,
  &.cb-wm-article {
    .cb-wm-preamble {
      font-size: 1.1875rem;
      line-height: 1.263157894736842em;
      margin: 0 !important;
      .roboto();
    }
  }

  // -------tablet, mobile
  @media (max-width: @screen-sm-max) {
    &:not(.cb-wm-article):not(.cb-wm-section):not(.cb-wm-sub-section){
      min-height: 240px;
      .flex-block {
        min-height: 240px;
      }
      &.cb-wm-image {
        height: 336px;
        .flex-block {
          > .content {
            bottom: 18px;
          }
        }
      }
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
  	h4, .h4 {
      font-size: 1.625rem;
      line-height: 1.1538em;
    }
    .cb-wm-date,
    .cb-wm-preamble {
      font-size: 1rem;
      line-height: 1.5em;
    }
  }

  // mobile
  @media (max-width: @screen-xs-max) {
    // background-color: @white-color;
    &.cb-wm-image{
      height: auto!important;
      .flex-block {
        > .content {
          position: relative;
          bottom: auto;
          margin: 30px 0 !important;
        }
      }
      padding-top: 240px !important;
      background-position: top center;
      background-size: auto 240px;
      -webkit-background-size: auto 240px;
      h1, .h1,
      h2, .h2,
      h3, .h3,
  		h4, .h4,
      p {
        color: @cb-base-font-color;
        .roboto-slab-bold();
      }
      &.cb-wm-border-bottom{
        border-bottom: 1px solid @cb-gray7-color;
      }
    }
  }

  // desktop
  @media (min-width: @screen-desktop) {
    &:not(.cb-wm-article):not(.cb-wm-section):not(.cb-wm-sub-section){
      height: 180px;
      &.cb-wm-image {
        height: 432px;
      }
    }
  }

  // oversized
  @media (min-width: @screen-lg-min) {
    &:not(.cb-wm-article):not(.cb-wm-section):not(.cb-wm-sub-section){
      height: 240px;
      &.cb-wm-image {
        height: 528px;
        .flex-block {
          > .content {
            bottom: 30px;
          }
        }
      }
    }
    h1, .h1,
    h2, .h2,
    h3, .h3,
  	h4, .h4 {
      font-size: 3rem;
      line-height: 1.125em;
    }
    .cb-wm-date,
    .cb-wm-preamble {
      font-size: 1.375rem;
      line-height: 1.6363em;
    }
  }
}
