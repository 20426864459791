//
// Pagination
// --------------------------------------------------

.pagination {
	margin: 0 0 1.5em 0;
  .border-box();
	li {
		&>a,
		&>span {
	    padding: 4px 12px;
			line-height: 1.375em;
	  }
	  a[aria-label="Previous"],
		a[aria-label="Next"],
		a[aria-label="previous"],
		a[aria-label="next"]{
			padding-top: 6px;
			padding-bottom: 2px;
      border-radius: 0;
		}
	}
}
