//
// Notices
// --------------------------------------------------

.cb-notice-callout,
.cb-notice{
  border: 0;
  background-color: @digital-field-gray;
  margin: 0 0 24px;
  padding: 24px;
  .cb-callout-content,
  .cb-notice-content{

    *{
      font-size: @font-size-small;
      line-height: @line-height-small-base;
      margin-bottom: 0;
    }
    h2,
    h3{
      font-size: @font-size-base;
      line-height: @line-height-base;
      margin: 0;
    }
    a{
      &.read-more{
        text-transform: capitalize;
      }
    }
  }
}
// not supported
.cb-tip-callout{
  border: 0;
  border-left: 1px solid @gray2-corporate-color;
  margin:0;
  padding: 42px 15px;
  display: inline-block;
  .cb-callout-content{
    font-size: @cb-tip-font-size;
    line-height: @cb-tip-line-height;
    color: @cb-notice-color;
    h2,
    h3{
      font-size: @cb-tip-title-font-size;
      line-height: @cb-tip-title-line-height;
      margin-top: 0;
    }
    p {
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    a{
      &.read-more{
        text-transform: capitalize;
        position: absolute;
        right: 33px;
        bottom: 0;
        span {
          &.glyphicon-cb,
          &.cb-glyph{
            font-size: 18px;
            &::before{
              top: 3px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}
