// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border) {
  color: @color;
  background-color: @background;
  border-color: @border;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color;
    background-color: darken(@background, 10%);
        border-color: darken(@border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @background;
          border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
}

.gradient (@startColor: @cb-gray-bk-color, @endColor: @white-color) {
  background-color: @startColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background-image: -webkit-linear-gradient(top, @startColor 0%, @endColor 100%);
  background-image: -moz-linear-gradient(top, @startColor 0%, @endColor 100%);
  background-image: -ms-linear-gradient(top, @startColor 0%, @endColor 100%);
}


.drop-shadow (@x: 0, @y: 1px, @blur: 3px, @spread: 1px, @alpha: 0.3) {
  -webkit-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:  @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:   @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

// Button sizes
.cb-button-size(@padding-vertical; @padding-horizontal; @font-size;) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
}

.cb-disabled-button(@background-color; @border-color; @font-color) {
  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    color: @font-color;
    background-color: @background-color;
    border-color: @border-color;
    background-image:none;
    text-shadow: none;
    pointer-events: none; // Future-proof disabling of clicks
    &:hover,
    &:focus {
      background-color: @background-color;
      color: @font-color;
      text-decoration: none;
    }
  }
}

.cb-hover-button(@background-color; @border-color; @font-color) {
  &:hover,
  &:focus,
  &:active,
  &.hover,
  &.focus,
  &.active{
    color: @font-color;
    text-decoration: underline;
    background-color: @background-color;
    border-color: @border-color;
    background-image:none;
    .box-shadow(none);
  }
}

.cb-focus-button() {
  &:focus,
  &.focus{
    .cb-focus();
    outline-offset: -1px;
  }
}

.cb-btn-fluid (@grid) {
  &.cb-@{grid}-btn-fluid{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}