//
// Legend
// --------------------------------------------------

.cb-legend {
	float: right;
	position: relative;
	display: inline-block;
	font-size: 0;
	line-height: 0;
	.cb-legend-btn {
		font-size: @font-size-base;
		height: 24px;
		padding-bottom: 2px;
		display: inline-block;
		span {
			float: left;
			display: inline-block;
			line-height: @line-height-base;
			&:not(.cb-glyph) {
				.roboto-slab-bold();
				color: @cb-base-font-color;
			}
			&.cb-glyph {
				margin-left: 8px;
			}
		}
		&:hover,
		&:focus {
			padding-bottom: 0;
			color: @cb-blue-link-color;
			text-decoration: none;
			border-bottom: 2px solid @cb-blue-link-color;
		}
	}
	.cb-legend-data {
		display: none;
	}
}
.cb-legend-content {
	width: 330px;
	max-width: 330px;
	left: auto !important;
	right: -16px !important;
	top: 20px !important;
	.arrow {
		left: 93% !important;
	}
	.popover-content {
		padding: 0;
		width: 100%;
		float: left;
	}
	padding: 23px 24px;
	.cb-legend-title {
		.roboto-bold();
		margin-bottom: 24px;
		font-size: @font-size-base;
		line-height: @line-height-base;
		padding-bottom: 23px;
		border-bottom: 1px solid @table-border-color;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			margin-bottom: 24px;
			display: flex;
			div {
				&:first-of-type {
					display: flex;
				}
				span {
					line-height: 24px !important;
					&+span {
						font-size: 0.75rem;
						margin-left: 8px;
						word-break: break-all;
						width: calc(100% - 24px);
						max-width: calc(100% - 24px);
					}
				}
				p {
					margin-bottom: 0;
				}
				&:last-of-type {
					margin-left: 24px;
				}
			}
			&:last-of-type {
				padding-bottom: 0;
			}
		}
		&.cb-legend-initial {
			li {
				div {
					&:first-of-type {
						min-width: 48px;
					}
				}
			}
		}
	}
	.cb-legend-desc {
		float: left;
		margin-bottom: 0;
		border-top: 1px solid @table-border-color;
		padding-top: 23px;
	}
}
.cb-legend-modal{
	.modal-content {
		.modal-header {
			padding:  24px 24px 0 24px;
		}
		.modal-body {
			padding: 24px 24px 0 24px;
			margin-bottom: 24px;
			.cb-legend-content {
				width: 100%;
				min-width: 100%;
				display: inline-block;
				padding: 0;
				.cb-legend-initial {
					padding-top: 24px;
					border-top: 1px solid @table-border-color;
				}
				.cb-legend-desc {
					margin-top: 24px;
				}
			}
		}
	}
}

.cb-legend-init-info {
	display: flex;
	float: left;
	span {
		line-height: 1.5em!important;
		&+span {
			font-size: 0.75rem!important;
			line-height: 2em!important;
			margin-left: 8px;
		}
	}
}

// no focus on mouse and touch
[data-cb-input-m="mouse"] {
	.cb-legend {
		.cb-legend-btn {
			&:focus,
			&:active {
	      .cb-no-focus();
	    }
		}
	}
  .cb-legend-content {
    &:focus,
		&:active {
      .cb-no-focus();
    }
  }
  .cb-legend-modal {
  	button.close {
	    &:focus,
			&:active {
	      .cb-no-focus();
	    }
  	}
  }
}