//
// Jumplinks
// --------------------------------------------------

.cb-jumplinks{
  &:not(.cb-jumplinks-btn) {
    list-style: none;
    border-left: 4px solid @gray4-corporate-color;
    margin-top: 0;
    margin-bottom: 48px;
    padding-left: 12px;
    li {
      line-height: 0;
      margin-bottom: 24px;
      &::last-child {
        margin-bottom: 0;
      }
      &.title {
        h2 {
          font-size: @font-size-small;
          line-height: @line-height-small-base;
          margin: 0;
          .roboto-bold();
          margin-bottom: 24px;
          text-transform: capitalize;
          white-space: nowrap;
          color: @cb-base-font-color;
        }
      }

      a{
        font-size: 1.1875rem;
        line-height: 1.263157894736842em;
        color: @cb-blue-link-color;
      }
    }
  }
  // 3.2.0
  &.cb-jumplinks-btn {
    margin: 0 0 48px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(50% - 4px);
      margin-bottom: 4px;
      margin-right: 4px;
      background: white;
      border: 1px solid @gray-corporate-border;
      a {
        .roboto-slab();
        display: block;
        font-size: 1.125rem;
        line-height: 1.333em;
        color: @cb-base-font-color;
        padding: 10px;
        text-decoration: none;
        cursor: pointer;
        transition: all .25s ease-in-out;
        &:hover {
          background: @digital-field-gray;
        }
      }
    }

  }
}

.cb-jumplinks-back{
  width: 100%;
  text-align: right;
  font-family: @font-family-RobotoSlab;
  position: relative;
  display: block;
  height: 32px;
  line-height: 16px;
  span {
    float: right;
    display: inline-block;
    &:not(.cb-glyph) {
      margin-right: 6px;
      line-height: 32px;
    }
  }
  .cb-glyph-circular {
    &::before {
      color: @cb-blue-link-color;
    }
    &::after {
      background-color: @white-color;
    }
  }
  &:hover {
    .cb-glyph-circular {
      &::before {
        color: @white-color;
      }
      &::after {
        background-color: @cb-blue-link-color;
      }
    }
  }
}
