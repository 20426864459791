//
// Carousel Controls
// --------------------------------------------------

.cb-carousel {
  border-radius: 50%;
  background-color: @cb-btn-blue;
  width:48px;
  height:48px;

  display: inline-block;
  outline: 0;
  position: relative;
  text-decoration: none;
  span {
    font-size: 0;
  }
  &.cb-prev{
    &::before{
      .cb-glyphs();
      color: @white-color;
      font-size: 1.5rem;
      font-weight: 700;
      position: absolute;
      display: block;
      content: "\e921";
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }
  &.cb-next {
    &::before{
      .cb-glyphs();
      color: @white-color;
      font-size: 1.5rem;
      font-weight: 700;
      position: absolute;
      display: block;
      content: "\e940";
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }
  &:hover,
  &:focus,
  &.rollover{
    background-color: @cb-btn-blue-rollover;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    -moz-border-radius: 50%;
    border-radius: 50%;
    .cb-focus();
  }
}