//
// $.fn.cbIntPhoneDetector
// --------------------------------------------------
.flag {
    background-image: url('@{cb-image-path}@{gv-image-sprite-flags-png}');
    background-position: 0 -2145px;
    width: 16px;
    height: 11px;
    display: block;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (   min--moz-device-pixel-ratio: 1.5),
only screen and (     -o-min-device-pixel-ratio: 3/2),
only screen and (        min-device-pixel-ratio: 1.5),
only screen and (                min-resolution: 144dpi),
only screen and (                min-resolution: 1.5dppx) {
    .flag {
        background-image: url('@{cb-image-path}@{gv-image-sprite-flags2x-png}');
        background-size: 16px 2156px;
    }
}

.ad {
    background-position: 0 -0px;
}

.ae {
    background-position: 0 -11px;
}

.af {
    background-position: 0 -22px;
}

.ag {
    background-position: 0 -33px;
}

.al {
    background-position: 0 -44px;
}

.am {
    background-position: 0 -55px;
}

.ao {
    background-position: 0 -66px;
}

.ar {
    background-position: 0 -77px;
}

.at {
    background-position: 0 -88px;
}

.au {
    background-position: 0 -99px;
}

.az {
    background-position: 0 -110px;
}

.ba {
    background-position: 0 -121px;
}

.bb {
    background-position: 0 -132px;
}

.bd {
    background-position: 0 -143px;
}

.be {
    background-position: 0 -154px;
}

.bf {
    background-position: 0 -165px;
}

.bg {
    background-position: 0 -176px;
}

.bh {
    background-position: 0 -187px;
}

.bi {
    background-position: 0 -198px;
}

.bj {
    background-position: 0 -209px;
}

.bn {
    background-position: 0 -220px;
}

.bo {
    background-position: 0 -231px;
}

.br {
    background-position: 0 -242px;
}

.bs {
    background-position: 0 -253px;
}

.bt {
    background-position: 0 -264px;
}

.bw {
    background-position: 0 -275px;
}

.by {
    background-position: 0 -286px;
}

.bz {
    background-position: 0 -297px;
}

.ca {
    background-position: 0 -308px;
}

.cd {
    background-position: 0 -319px;
}

.cf {
    background-position: 0 -330px;
}

.cg {
    background-position: 0 -341px;
}

.ch {
    background-position: 0 -352px;
}

.ci {
    background-position: 0 -363px;
}

.cl {
    background-position: 0 -374px;
}

.cm {
    background-position: 0 -385px;
}

.cn {
    background-position: 0 -396px;
}

.co {
    background-position: 0 -407px;
}

.cr {
    background-position: 0 -418px;
}

.cu {
    background-position: 0 -429px;
}

.cv {
    background-position: 0 -440px;
}

.cy {
    background-position: 0 -451px;
}

.cz {
    background-position: 0 -462px;
}

.de {
    background-position: 0 -473px;
}

.dj {
    background-position: 0 -484px;
}

.dk {
    background-position: 0 -495px;
}

.dm {
    background-position: 0 -506px;
}

.do {
    background-position: 0 -517px;
}

.dz {
    background-position: 0 -528px;
}

.ec {
    background-position: 0 -539px;
}

.ee {
    background-position: 0 -550px;
}

.eg {
    background-position: 0 -561px;
}

.er {
    background-position: 0 -572px;
}

.es {
    background-position: 0 -583px;
}

.et {
    background-position: 0 -594px;
}

.fi {
    background-position: 0 -605px;
}

.fj {
    background-position: 0 -616px;
}

.fm {
    background-position: 0 -627px;
}

.fr {
    background-position: 0 -638px;
}

.ga {
    background-position: 0 -649px;
}

.gb {
    background-position: 0 -660px;
}

.gd {
    background-position: 0 -671px;
}

.ge {
    background-position: 0 -682px;
}

.gh {
    background-position: 0 -693px;
}

.gm {
    background-position: 0 -704px;
}

.gn {
    background-position: 0 -715px;
}

.gq {
    background-position: 0 -726px;
}

.gr {
    background-position: 0 -737px;
}

.gt {
    background-position: 0 -748px;
}

.gw {
    background-position: 0 -759px;
}

.gy {
    background-position: 0 -770px;
}

.hn {
    background-position: 0 -781px;
}

.hr {
    background-position: 0 -792px;
}

.ht {
    background-position: 0 -803px;
}

.hu {
    background-position: 0 -814px;
}

.id {
    background-position: 0 -825px;
}

.ie {
    background-position: 0 -836px;
}

.il {
    background-position: 0 -847px;
}

.in:not(.collapse) {
    background-position: 0 -858px;
}

.iq {
    background-position: 0 -869px;
}

.ir {
    background-position: 0 -880px;
}

.is {
    background-position: 0 -891px;
}

.it {
    background-position: 0 -902px;
}

.jm {
    background-position: 0 -913px;
}

.jo {
    background-position: 0 -924px;
}

.jp {
    background-position: 0 -935px;
}

.ke {
    background-position: 0 -946px;
}

.kg {
    background-position: 0 -957px;
}

.kh {
    background-position: 0 -968px;
}

.ki {
    background-position: 0 -979px;
}

.km {
    background-position: 0 -990px;
}

.kn {
    background-position: 0 -1001px;
}

.kp {
    background-position: 0 -1012px;
}

.kr {
    background-position: 0 -1023px;
}

.kw {
    background-position: 0 -1034px;
}

.kz {
    background-position: 0 -1045px;
}

.la {
    background-position: 0 -1056px;
}

.lb {
    background-position: 0 -1067px;
}

.lc {
    background-position: 0 -1078px;
}

.li {
    background-position: 0 -1089px;
}

.lk {
    background-position: 0 -1100px;
}

.lr {
    background-position: 0 -1111px;
}

.ls {
    background-position: 0 -1122px;
}

.lt {
    background-position: 0 -1133px;
}

.lu {
    background-position: 0 -1144px;
}

.lv {
    background-position: 0 -1155px;
}

.ly {
    background-position: 0 -1166px;
}

.ma {
    background-position: 0 -1177px;
}

.mc {
    background-position: 0 -1188px;
}

.md {
    background-position: 0 -1199px;
}

.me {
    background-position: 0 -1210px;
}

.mg {
    background-position: 0 -1221px;
}

.mh {
    background-position: 0 -1232px;
}

.mk {
    background-position: 0 -1243px;
}

.ml {
    background-position: 0 -1254px;
}

.mm {
    background-position: 0 -1265px;
}

.mn {
    background-position: 0 -1276px;
}

.mr {
    background-position: 0 -1287px;
}

.mt {
    background-position: 0 -1298px;
}

.mu {
    background-position: 0 -1309px;
}

.mv {
    background-position: 0 -1320px;
}

.mw {
    background-position: 0 -1331px;
}

.mx {
    background-position: 0 -1342px;
}

.my {
    background-position: 0 -1353px;
}

.mz {
    background-position: 0 -1364px;
}

.na {
    background-position: 0 -1375px;
}

.ne {
    background-position: 0 -1386px;
}

.ng {
    background-position: 0 -1397px;
}

.ni {
    background-position: 0 -1408px;
}

.nl {
    background-position: 0 -1419px;
}

.no {
    background-position: 0 -1430px;
}

.np {
    background-position: 0 -1441px;
}

.nr {
    background-position: 0 -1452px;
}

.nz {
    background-position: 0 -1463px;
}

.om {
    background-position: 0 -1474px;
}

.pa {
    background-position: 0 -1485px;
}

.pe {
    background-position: 0 -1496px;
}

.pg {
    background-position: 0 -1507px;
}

.ph {
    background-position: 0 -1518px;
}

.pk {
    background-position: 0 -1529px;
}

.pl {
    background-position: 0 -1540px;
}

.pt {
    background-position: 0 -1551px;
}

.pw {
    background-position: 0 -1562px;
}

.py {
    background-position: 0 -1573px;
}

.qa {
    background-position: 0 -1584px;
}

.ro {
    background-position: 0 -1595px;
}

.rs {
    background-position: 0 -1606px;
}

.ru {
    background-position: 0 -1617px;
}

.rw {
    background-position: 0 -1628px;
}

.sa {
    background-position: 0 -1639px;
}

.sb {
    background-position: 0 -1650px;
}

.sc {
    background-position: 0 -1661px;
}

.sd {
    background-position: 0 -1672px;
}

.se {
    background-position: 0 -1683px;
}

.sg {
    background-position: 0 -1694px;
}

.si {
    background-position: 0 -1705px;
}

.sk {
    background-position: 0 -1716px;
}

.sl {
    background-position: 0 -1727px;
}

.sm {
    background-position: 0 -1738px;
}

.sn {
    background-position: 0 -1749px;
}

.so {
    background-position: 0 -1760px;
}

.sr {
    background-position: 0 -1771px;
}

.ss {
    background-position: 0 -1782px;
}

.st {
    background-position: 0 -1793px;
}

.sv {
    background-position: 0 -1804px;
}

.sy {
    background-position: 0 -1815px;
}

.sz {
    background-position: 0 -1826px;
}

.td {
    background-position: 0 -1837px;
}

.tg {
    background-position: 0 -1848px;
}

.th {
    background-position: 0 -1859px;
}

.tj {
    background-position: 0 -1870px;
}

.tl {
    background-position: 0 -1881px;
}

.tm {
    background-position: 0 -1892px;
}

.tn {
    background-position: 0 -1903px;
}

.to {
    background-position: 0 -1914px;
}

.tr {
    background-position: 0 -1925px;
}

.tt {
    background-position: 0 -1936px;
}

.tv {
    background-position: 0 -1947px;
}

.tz {
    background-position: 0 -1958px;
}

.ua {
    background-position: 0 -1969px;
}

.ug {
    background-position: 0 -1980px;
}

.us {
    background-position: 0 -1991px;
}

.uy {
    background-position: 0 -2002px;
}

.uz {
    background-position: 0 -2013px;
}

.va {
    background-position: 0 -2024px;
}

.vc {
    background-position: 0 -2035px;
}

.ve {
    background-position: 0 -2046px;
}

.vn {
    background-position: 0 -2057px;
}

.vu {
    background-position: 0 -2068px;
}

.ws {
    background-position: 0 -2079px;
}

.xk {
    background-position: 0 -2090px;
}

.ye {
    background-position: 0 -2101px;
}

.za {
    background-position: 0 -2112px;
}

.zm {
    background-position: 0 -2123px;
}

.zw {
    background-position: 0 -2134px;
}

.zzz {
    background-position: 0 -2145px;
}