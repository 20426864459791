//
// Print
// --------------------------------------------------

@media print {
  .print-page();
  .print-tables();
  .modal-open {
    .print-page-modal();
  }
  .no-screen {
    display: block;
  }
}

@media screen {
  .no-screen {
    display: none;
  }
  // Make sure you have the code in main bootstrap print.less
  .print {
    *,
    *::before,
    *::after {
      background: transparent !important;
      color: @black-color !important; // Black prints faster: h5bp.com/s
      box-shadow: none !important;
      text-shadow: none !important;
    }
    a,
    a:visited {
      text-decoration: underline;
    }
    a[href]::after {
      content: " (" attr(href) ")";
      word-break: break-all;
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
      word-break: break-all;
    }
    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    a[href^="#"]::after,
    a[href^="javascript:"]::after {
      content: "";
    }
    pre,
    blockquote {
      border: 1px solid @cb-gray60-color;
      page-break-inside: avoid;
    }
    img {
      page-break-inside: avoid;
      max-width: 100% !important;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    // Bootstrap specific changes start
    //
    // Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
    // Once fixed, we can just straight up remove this.
    select {
      background: @white-color !important;
    }
    // Bootstrap components
    .navbar {
      display: none;
    }
    .btn,
    .dropup > .btn {
      > .caret {
        border-top-color: @black-color !important;
      }
    }
    .label {
      border: 1px solid @black-color;
    }
    .print-page();
    .print-tables();
    &.modal-open {
      .print-page-modal ();
    }
    .no-screen {
      display: block;
    }
  }
}
