//
// CB Program Band
// --------------------------------------------------

.program-settings(@color) {
  border-top-color: @color;
  & > ul {
    & > li {
      & > a {
        &:hover,
        &:focus {
          background-color: @color;
        }
      }
    }
  }
}

// Latest version
.cb-program-brand {
  display: flex;
  flex-wrap: wrap;

  & > div {
    // desktop|oversized, 4
    width: calc((100% - 90px) / 4);
    margin: 0 15px;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    // tablet, 2
    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
      width: calc((100% - 20px) / 2);
      margin: 0 10px 32px;

      &:nth-of-type(2),
      &:nth-of-type(4){
        margin-right: 0;
      }

      &:nth-of-type(1),
      &:nth-of-type(3){
        margin-left: 0;
      }
      &:nth-last-of-type(2),
      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
    // mobile, 1
    @media (max-width: @screen-xs-max) {
      width: 100%;
      margin: 0 0 15px;
    }

    float: left;
    background-color: @white-color;
    border-bottom: 1px solid @gray-corporate-border;
    border-top: 6px solid @white-color;
    .border-box();
    &[data-cb-program="k-12"]{
      .program-settings(@k-12-color);
    }
    &[data-cb-program="access"]{
      .program-settings(@access-color);
    }
    &[data-cb-program="higher-education"]{
      .program-settings(@higher-education-color);
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
      li {
        a {
          display: block;
          width: 100%;
          &.focus {
            .cb-focus();
          }
        }

        span:not(.sr-only) {
          padding: 10px 3px;
          font-size: 1.25rem;
          .roboto-slab-bold();
        }

        &.cb-no-color {
          a {
            &::before {
              height: 0;
              background: none !important;
            }
          }
        }
      }
    }

    ul {
      padding: 0;
      margin: 0;
       li {
        width: 100%;
        margin: 0;
        padding: 0;
        border-top: 1px solid @cb-program-band-border-color;
        & > a {
          height: 47px;
          padding: 17px 0;
          padding-left: 20px;
          overflow: hidden;
          line-height: 1em;
          width: 100%;
          position: relative;
          &::before {
            height: 35px;
            background-size: 100% 100%;
            background-position: -14px -11px;
          }
          &::after {
            .cb-glyphs();
            color: @gray3-corporate-color;
            display: block;
            content: "\e940";
            line-height: 12px;
            width: 12px;
            height: 12px;
            right: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          &:hover,
          &:focus {
            &::after {
              color: @white-color;
            }
          }
        }
        &:hover {
          span {
            text-decoration: underline;
          }
        }

        &:first-of-type {
          border-top: 0;

          & > a {
            height: 48px;
          }
        }
        &.cb-dark-border {
          border-top: 1px solid @gray-corporate-border;
        }
        ul {
          background-color: @cb-program-band-sub-color;
          li {
            border-top: 1px solid @gray-corporate-border;
            border-right: 0;
            border-left: 0;
            &:first-of-type {
              border-top: 1px solid @gray-corporate-border;
            }
            a {
              padding: 0 0 0 30px;
              height: 47px !important;
              line-height: 48px;
              color: @cb-base-font-color;
              &:hover {
                color: @cb-base-font-color;
              }
              &::after{
                content: "";
              }
            }
          }
        }
        //  Not used in V5
        p {
          font-size: @font-size-small;
          line-height: 1.2em;
          padding: 8px 24px 20px 24px;
          margin-bottom: 0;
        }
      }
    }
  }

  // logos
  .cb-programs-access-black-b,
  .cb-programs-access-b {
    &::before {
      width: 91px;
    }
  }
  .cb-programs-accuplacer-black-b,
  .cb-programs-accuplacer-b {
    &::before {
      width: 155px;
    }
  }
  .cb-programs-ap-black-b,
  .cb-programs-ap-b {
    &::before {
      width: 54px;
    }
  }

  .cb-programs-pre-ap-black-b,
  .cb-programs-pre-ap-b {
    &::before {
      width: 89px;
    }
  }
  .cb-programs-clep-black-b,
  .cb-programs-clep-b {
    &::before {
      width: 76px;
    }
  }
  .cb-programs-css-profile-black-b,
  .cb-programs-css-profile-b {
    &::before {
      width: 128px;
    }
  }
  .cb-programs-powerfaids-black-b,
  .cb-programs-powerfaids-b {
    &::before {
      width: 139px;
    }
  }
  .cb-programs-psat-8-9-black-b,
  .cb-programs-psat-8-9-b {
    &::before {
      width: 109px;
    }
  }
  .cb-programs-psat-10-black-b,
  .cb-programs-psat-10-b {
    &::before {
      width: 101px;
    }
  }
  .cb-programs-psat-nmsqt-black-b,
  .cb-programs-psat-nmsqt-b {
    &::before {
      width: 154px;
    }
  }
  .cb-programs-sat-black-b,
  .cb-programs-sat-b {
    &::before {
      width: 64px;
    }
  }
  .cb-programs-springboard-black-b,
  .cb-programs-springboard-b {
    &::before {
      width: 138px;
    }
  }
  .cb-programs-search-black-b,
  .cb-programs-search-b {
    &::before {
      width: 89px;
    }
  }
}

// Latest version with glyph logos
.cb-program-brand {
  &.cb-program-brand-glyph {
    .cb-glyph-logo {
      &:before {
        font-size: 2.125rem;
        background: none!important;
        height: auto;
        color: @black-color;
        line-height: 3rem;
        margin-left: 13px;
      }
      &:hover,
      &focus {
        &:before {
          color: @white-color;
        }
      }
    }
  }
}


// V1
.cb-program-brand-v1{
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  li {
    width: calc((100% - 18px) / 4);
    height: 44px;
    float: left;
    margin-right: 6px;
    margin-bottom: 6px;
    a {
      display: block;
      width: 100%;
      height: 44px;
      &.focus {
        .cb-focus();
      }
      &::before {
        height: 44px;
        -moz-transition: all .2s;
        -webkit-transition: all .2s;
        transition: all .2s;
        background-size: 100% 100%;
      }
      &:hover {
        &::before {
          background-size: 110% 110%;
        }
      }
      &.cb-programs-access-b{
        &::before {
          width: 99px;
        }
        &:hover{
          &::before {
            width: 109px;
          }
        }
      }
      &.cb-programs-accuplacer-b{
        &::before {
          width: 180px;
        }
        &:hover{
          &::before {
            width: 198px;
          }
        }
      }
      &.cb-programs-ap-b{
        &::before {
          width: 54px;
        }
        &:hover{
          &::before {
            width: 60px;
          }
        }
      }
      &.cb-programs-clep-b{
        &::before {
          width: 83px;
        }
        &:hover{
          &::before {
            width: 92px;
          }
        }
      }
      &.cb-programs-css-profile-b{
        &::before {
          width: 149px;
        }
        &:hover{
          &::before {
            width: 164px;
          }
        }
      }
      &.cb-programs-powerfaids-b{
        &::before {
          width: 162px;
        }
        &:hover{
          &::before {
            width: 179px;
          }
        }
      }
      &.cb-programs-psat-8-9-b{
        &::before {
          width: 125px;
        }
        &:hover{
          &::before {
            width: 138px;
          }
        }
      }
      &.cb-programs-psat-10-b{
        &::before {
          width: 115px;
        }
        &:hover{
          &::before {
            width: 127px;
          }
        }
      }
      &.cb-programs-psat-nmsqt-b{
        &::before {
          width: 182px;
        }
        &:hover{
          &::before {
            width: 201px;
          }
        }
      }
      &.cb-programs-sat-b{
        &::before {
          width: 70px;
        }
        &:hover{
          &::before {
            width: 77px;
          }
        }
      }
      &.cb-programs-springboard-b{
        &::before {
          width: 163px;
        }
        &:hover{
          &::before {
            width: 180px;
          }
        }
      }
      &.cb-programs-search-b{
        &::before {
          width: 100px;
        }
        &:hover{
          &::before {
            width: 110px;
          }
        }
      }
    }

    span:not(.sr-only) {
      padding: 10px 3px;
      font-size: 1.25rem;
      .roboto-slab-bold();
    }
    &:hover {
      cursor: pointer;
      span {
        text-decoration: underline;
      }
    }

    &.cb-no-color {
      a {
        &::before {
          height: 0;
          background: none !important;
        }
      }
    }

    &:nth-child(4),
    &:nth-child(8),
    &:nth-child(12){
      margin-right: 0;
    }
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12){
      margin-bottom: 0;
    }
  }

  @media (max-width: @screen-xs-max) { // mobile
    li {
      width: calc((100% - 6px) / 2);
      height: 36px;
      margin-right: 6px !important;
      margin-bottom: 6px !important;
      a {
        height: 33px;
        &::before {
          margin-top: 3px;
          height: 30px !important;
        }

        &.cb-programs-access-b{
          &::before {
            width: 68px;
          }
          &:hover{
            &::before {
              width: 75px;
            }
          }
        }
        &.cb-programs-accuplacer-b{
          &::before {
            width: 123px;
          }
          &:hover{
            &::before {
              width: 136px;
            }
          }
        }
        &.cb-programs-ap-b{
          &::before {
            width: 37px;
          }
          &:hover{
            &::before {
              width: 41px;
            }
          }
        }
        &.cb-programs-clep-b{
          &::before {
            width: 57px;
          }
          &:hover{
            &::before {
              width: 63px;
            }
          }
        }
        &.cb-programs-css-profile-b{
          &::before {
            width: 102px;
          }
          &:hover{
            &::before {
              width: 113px;
            }
          }
        }
        &.cb-programs-powerfaids-b{
          &::before {
            width: 110px;
          }
          &:hover{
            &::before {
              width: 122px;
            }
          }
        }
        &.cb-programs-psat-8-9-b{
          &::before {
            width: 85px;
          }
          &:hover{
            &::before {
              width: 94px;
            }
          }
        }
        &.cb-programs-psat-10-b{
          &::before {
            width: 78px;
          }
          &:hover{
            &::before {
              width: 86px;
            }
          }
        }
        &.cb-programs-psat-nmsqt-b{
          &::before {
            width: 124px;
          }
          &:hover{
            &::before {
              width: 137px;
            }
          }
        }
        &.cb-programs-sat-b{
          &::before {
            width: 48px;
          }
          &:hover{
            &::before {
              width: 53px;
            }
          }
        }
        &.cb-programs-springboard-b{
          &::before {
            width: 111px;
          }
          &:hover{
            &::before {
              width: 123px;
            }
          }
        }
        &.cb-programs-search-b{
          &::before {
            width: 68px;
          }
          &:hover{
            &::before {
              width: 75px;
            }
          }
        }
      }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(8),
      &:nth-child(10),
      &:nth-child(12){
        margin-right: 0 !important;
      }
      &:nth-child(11),
      &:nth-child(12){
        margin-bottom: 0 !important;
      }
      span {
        font-size: @line-height-base !important;
      }
    }
  }
  @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { //tablet
   li {
      width: calc((100% - 12px) / 3);
      margin-right: 6px !important;
      margin-bottom: 6px !important;
      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(9),
      &:nth-child(12){
        margin-right: 0 !important;
      }
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12){
        margin-bottom: 0 !important;
      }
      span{
        font-size: @line-height-base !important;
      }
    }
  }
}

//  V2
.cb-program-brand-v2{
  display: flex;
  flex-wrap: wrap;

  & > div {
    // desktop|oversized, 4
    width: calc((100% - 90px) / 4);
    margin: 0 15px;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    // tablet, 2
    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
      width: calc((100% - 20px) / 2);
      margin: 0 10px 32px;

      &:nth-of-type(2),
      &:nth-of-type(4){
        margin-right: 0;
      }

      &:nth-of-type(1),
      &:nth-of-type(3){
        margin-left: 0;
      }
      &:nth-last-of-type(2),
      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
    // mobile, 1
    @media (max-width: @screen-xs-max) {
      width: 100%;
      margin: 0 0 15px;
    }

    float: left;
    background-color: @white-color;
    border: 1px solid @gray-corporate-border;
    border-top: 12px solid @white-color;
    .border-box();
    &[data-cb-program="k-12"]{
      .program-settings(@k-12-color);
    }
    &[data-cb-program="access"]{
      .program-settings(@access-color);
    }
    &[data-cb-program="higher-education"]{
      .program-settings(@higher-education-color);
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
      li {
        // float: left;
        width: calc(100% - 24px);
        a {
          display: block;
          width: 100%;
          &.focus {
            .cb-focus();
          }
        }

        span:not(.sr-only) {
          padding: 10px 3px;
          font-size: 1.25rem;
          .roboto-slab-bold();
        }

        &.cb-no-color {
          a {
            &::before {
              height: 0;
              background: none !important;
            }
          }
        }
      }
    }

    & > ul {
      padding: 0;
      margin: 12px 0;
      & > li {
        width: 100%;
        margin: 0;
        padding: 0;
        & > a {
          height: 40px;
          padding: 12px 0;
          padding-left: 24px;
          overflow: hidden;
          line-height: 1em;
          &::before {
            height: 40px;
            background-size: 100% 100%;
            background-position: -12px -12px;
          }
        }
        &:hover {
          span {
            text-decoration: underline;
          }
        }
        div {
          background-color: @digital-field-gray;
          ul {
            display: inline-block;
            margin: 24px 0 24px 18px;
            border-left: 1px dashed @gray1-corporate-color;
            width: calc(100% - 18px);
            li {
              &+li {
                padding-top: 16px;
              }
              a {
                padding-left: 12px;
                color: @cb-base-font-color;
                width: calc(100% - 12px);
                &:hover {
                  color: @cb-base-font-color;
                }
              }
            }
          }

        }
        p {
          font-size: 0.875rem;
          line-height: 1.2em;
          padding: 8px 24px 20px 24px;
          margin-bottom: 0;

        }
      }
    }
  }

  // logos
  .cb-programs-access-black-b,
  .cb-programs-access-b {
    &::before {
      width: 95px;
    }
  }
  .cb-programs-accuplacer-black-b,
  .cb-programs-accuplacer-b {
    &::before {
      width: 170px;
    }
  }
  .cb-programs-ap-black-b,
  .cb-programs-ap-b {
    &::before {
      width: 54px;
    }
  }

  .cb-programs-pre-ap-black-b,
  .cb-programs-pre-ap-b {
    &::before {
      width: 94px;
    }
  }
  .cb-programs-clep-black-b,
  .cb-programs-clep-b {
    &::before {
      width: 79px;
    }
  }
  .cb-programs-css-profile-black-b,
  .cb-programs-css-profile-b {
    &::before {
      width: 139px;
    }
  }
  .cb-programs-powerfaids-black-b,
  .cb-programs-powerfaids-b {
    &::before {
      width: 150px;
    }
  }
  .cb-programs-psat-8-9-black-b,
  .cb-programs-psat-8-9-b {
    &::before {
      width: 117px;
    }
  }
  .cb-programs-psat-10-black-b,
  .cb-programs-psat-10-b {
    &::before {
      width: 107px;
    }
  }
  .cb-programs-psat-nmsqt-black-b,
  .cb-programs-psat-nmsqt-b {
    &::before {
      width: 168px;
    }
  }
  .cb-programs-sat-black-b,
  .cb-programs-sat-b {
    &::before {
      width: 66px;
    }
  }
  .cb-programs-springboard-black-b,
  .cb-programs-springboard-b {
    &::before {
      width: 150px;
    }
  }
  .cb-programs-search-black-b,
  .cb-programs-search-b {
    &::before {
      width: 95px;
    }
  }
}