//
// CB Dropdowns
// --------------------------------------------------

.dropdown {
	.cb-icon-icn_arrow-down,
	.cb-icon-icn_arrow-up,
	.cb-down,
	.cb-up  {
		font-size: 0.75rem;
	}

	.dropdown-menu {
		.roboto();
		font-size: @font-size-small;
		line-height: @line-height-small-base;
		border-radius: 0;
		padding: 5px 0;
		li{
			&:first-child{
				margin-top: 6px;
			}
			&:last-child {
				margin-bottom: 6px;
			}
		}
		>li {
			>a {
    		padding: 0 20px;
				line-height: @line-height-small-base;
			}
		}
		a{
			&:hover,
			&:active,
			.active{
				color: @blue-accent1-corporate-color;
				text-decoration: underline;
				background-color: transparent;
			}

			&:focus {
				// background-color: transparent;
				.cb-focus();
			}
		}
	}

	&.cb-dropdown {
		.dropdown-toggle{
			.roboto();
			font-size: @font-size-base;
			line-height: @line-height-base;
			color: @cb-base-font-color;
			&:hover,
			&:active,
			&:focus  {
				color: @blue-accent1-corporate-color;
	    	border-bottom: 3px solid @blue-accent1-corporate-color;
				text-decoration: none;
				background-color: transparent;
			}
		}
		&.active,
		&.active-trail {
			.dropdown-toggle{
				color: @blue-accent1-corporate-color;
	    	border-bottom: 3px solid @blue-accent1-corporate-color;
				text-decoration: none;
	    }
		}
		.dropdown-menu {
			a{
				border-left: 3px solid @white-color;
				&:hover,
				&:active,
				.active{
					background-color: transparent;
					color: @blue-accent1-corporate-color;
					border-left: 3px solid @blue-accent1-corporate-color;
					text-decoration: none;
				}

				&:focus {
					background-color: transparent;
				}
			}
			.dropdown-header{
				.roboto-bold();
				font-size: @font-size-small;
				color: @list-group-link-color;
				line-height: @line-height-small-base;
				padding-top: 24px;
				padding-bottom: 0;
				padding-left: 23px;
			}
			li {
				&.cb-active {
					background-color: @blue-accent1-corporate-color;
					a {
						border-left: 3px solid @blue-accent1-corporate-color;
						color: @white-color;
						&:hover,
						&:focus {
							color: @white-color;
						}

					}
				}
			}
		}
	}

	.cb-multi{
		a {
			white-space: pre-wrap;
			padding-top: 5px;
			padding-bottom: 5px;
			line-height: 20px !important;
		}
	}
	.divider{
		margin: 3px 0 4px 0;
		background-color: @gray-corporate-border;
	}

	&.cb-menu-right {
		&.open{
			.dropdown-menu {
				left: auto;
				right: 0;
			}
		}
	}
}
.dropdown-menu {
	a{
		line-height: 1.7142857142857142em;
	}
	.divider{
		margin: 3px 0 4px 0;
		background-color: @gray-corporate-border;
	}
}
