//
// CB Sibling Menu
// --------------------------------------------------

.cb-sibling-menu {
	padding: 0;
	margin: 0;
	display: inline-block;
	li {
		list-style: square;
		position: relative;
		list-style: none;
		padding-left: 12px;
		height: 24px;
		a {
			.roboto-bold();
			&:focus {
				.roboto-italic();
			}
		}
		&::before {
			position: absolute;
			display: block;
			background-color: @cb-base-font-color;
			height: 4px;
			width: 4px;
			content: "";
	  	left: 1px;
	  	top: 10px;
	    -webkit-transition: all .5s ease-out;
	    -moz-transition: all .5s ease-out;
	    -o-transition: all .5s ease-out;
	    -ms-transition: all .5s ease-out;
	    transition: all .5s ease-out;
		}
		&:hover {
			&::before {
				background-color: @blue-accent2-corporate-color;
				height: 16px;
				width: 4px;
				top: 4px;
			}
		}
		&.active {
			a {
				.roboto-italic();
				font-weight: 400;
				color: @cb-base-font-color;
				text-decoration: none;
				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
			&::before {
				background-color: @blue-accent2-corporate-color;
				height: 16px;
				width: 4px;
				top: 4px;
			}
			&.cb-sibling-hover {
				&::before {
					height: 0;
					top: 14px;
				}
			}
		}
	}
	&.cb-sibling-menu-light {
		li {
			a {
				color: @white-color;
				&:hover,
				&:focus {
					color: @white-color;
				}
			}
			&:not(.active) {
				&::before {
					background-color: @white-color;
				}
			}
			&:hover {
				&::before {
					background-color: @blue-accent2-corporate-color;
				}
			}
		}
	}
}