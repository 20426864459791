//
// Logos
// --------------------------------------------------

.cb-logo-white,
.cb-logo {
	.bk-image('@{cb-image-path-aws}cb-logo-white.svg');
}
.cb-logo-acorn {
	.bk-image('@{cb-image-path-aws}cb-acorn.svg');
}
.cb-search:not(.cb-glyph),
.cb-programs-search  {
	.bk-image('@{cb-image-path-aws}cb-search.svg');
}
.cb-programs-access {
	.bk-image('@{cb-image-path-aws}cb-programs-access.svg');
}
.cb-programs-accuplacer {
	.bk-image('@{cb-image-path-aws}cb-programs-accuplacer.svg');
}
.cb-programs-ap {
	.bk-image('@{cb-image-path-aws}cb-programs-ap.svg');
}
.cb-programs-pre-ap {
	.bk-image('@{cb-image-path-aws}cb-programs-pre-ap.svg');
}
.cb-programs-clep {
	.bk-image('@{cb-image-path-aws}cb-programs-clep.svg');
}
.cb-programs-css-profile {
	.bk-image('@{cb-image-path-aws}cb-programs-css-profile.svg');
}
.cb-programs-powerfaids {
	.bk-image('@{cb-image-path-aws}cb-programs-powerfaids.svg');
}
.cb-programs-psat-nmsqt {
	.bk-image('@{cb-image-path-aws}cb-programs-psat-nmsqt.svg');
}
.cb-programs-psat-8-9 {
	.bk-image('@{cb-image-path-aws}cb-programs-psat-8-9.svg');
}
.cb-programs-psat-10 {
	.bk-image('@{cb-image-path-aws}cb-programs-psat-10.svg');
}
.cb-programs-sat {
	.bk-image('@{cb-image-path-aws}cb-programs-sat.svg');
}
.cb-programs-springboard {
	.bk-image('@{cb-image-path-aws}cb-programs-springboard.svg');
}

// ---------- Black

.cb-logo-black {
	.bk-image('@{cb-image-path-aws}cb-logo-black.svg');
}
.cb-search-black:not(.cb-glyph),
.cb-programs-search-black  {
	.bk-image('@{cb-image-path-aws}cb-search-black.svg');
}
.cb-programs-access-black {
	.bk-image('@{cb-image-path-aws}cb-programs-access-black.svg');
}
.cb-programs-accuplacer-black {
	.bk-image('@{cb-image-path-aws}cb-programs-accuplacer-black.svg');
}
.cb-programs-ap-black {
	.bk-image('@{cb-image-path-aws}cb-programs-ap-black.svg');
}
.cb-programs-pre-ap-black {
	.bk-image('@{cb-image-path-aws}cb-programs-pre-ap-black.svg');
}
.cb-programs-clep-black {
	.bk-image('@{cb-image-path-aws}cb-programs-clep-black.svg');
}
.cb-programs-css-profile-black {
	.bk-image('@{cb-image-path-aws}cb-programs-css-profile-black.svg');
}
.cb-programs-powerfaids-black {
	.bk-image('@{cb-image-path-aws}cb-programs-powerfaids-black.svg');
}
.cb-programs-psat-nmsqt-black {
	.bk-image('@{cb-image-path-aws}cb-programs-psat-nmsqt-black.svg');
}
.cb-programs-psat-8-9-black {
	.bk-image('@{cb-image-path-aws}cb-programs-psat-8-9-black.svg');
}
.cb-programs-psat-10-black {
	.bk-image('@{cb-image-path-aws}cb-programs-psat-10-black.svg');
}
.cb-programs-sat-black {
	.bk-image('@{cb-image-path-aws}cb-programs-sat-black.svg');
}
.cb-programs-springboard-black {
	.bk-image('@{cb-image-path-aws}cb-programs-springboard-black.svg');
}

// ---------- before

.cb-logo-white-b,
.cb-logo-b {
	.bk-image-b('@{cb-image-path-aws}cb-logo-white.svg');
}
.cb-logo-acorn-b {
	.bk-image-b('@{cb-image-path-aws}cb-acorn.svg');
}
.cb-programs-search-b {
	.bk-image-b('@{cb-image-path-aws}cb-search.svg');
}
.cb-programs-access-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-access.svg');
}
.cb-programs-accuplacer-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-accuplacer.svg');
}
.cb-programs-ap-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-ap.svg');
}
.cb-programs-pre-ap-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-pre-ap.svg');
}
.cb-programs-clep-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-clep.svg');
}
.cb-programs-css-profile-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-css-profile.svg');
}
.cb-programs-powerfaids-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-powerfaids.svg');
}
.cb-programs-psat-nmsqt-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-psat-nmsqt.svg');
}
.cb-programs-psat-8-9-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-psat-8-9.svg');
}
.cb-programs-psat-10-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-psat-10.svg');
}
.cb-programs-sat-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-sat.svg');
}
.cb-programs-springboard-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-springboard.svg');
}

// ---------- before Black
.cb-logo-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-logo-black.svg');
}
.cb-programs-search-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-search-black.svg');
}
.cb-programs-access-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-access-black.svg');
}
.cb-programs-accuplacer-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-accuplacer-black.svg');
}
.cb-programs-ap-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-ap-black.svg');
}
.cb-programs-pre-ap-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-pre-ap-black.svg');
}
.cb-programs-clep-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-clep-black.svg');
}
.cb-programs-css-profile-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-css-profile-black.svg');
}
.cb-programs-powerfaids-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-powerfaids-black.svg');
}
.cb-programs-psat-nmsqt-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-psat-nmsqt-black.svg');
}
.cb-programs-psat-8-9-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-psat-8-9-black.svg');
}
.cb-programs-psat-10-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-psat-10-black.svg');
}
.cb-programs-sat-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-sat-black.svg');
}
.cb-programs-springboard-black-b {
	.bk-image-b('@{cb-image-path-aws}cb-programs-springboard-black.svg');
}

// ---------- after

.cb-logo-white-a,
.cb-logo-a {
	.bk-image-a('@{cb-image-path-aws}cb-logo-white.svg');
}
.cb-programs-search-a {
	.bk-image-a('@{cb-image-path-aws}cb-search.svg');
}
.cb-logo-acorn-a {
	.bk-image-a('@{cb-image-path-aws}cb-acorn.svg');
}
.cb-programs-access-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-access.svg');
}
.cb-programs-accuplacer-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-accuplacer.svg');
}
.cb-programs-ap-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-ap.svg');
}
.cb-programs-pre-ap-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-pre-ap.svg');
}
.cb-programs-clep-a {
	.bk-image-b('@{cb-image-path-aws}cb-programs-clep.svg');
}
.cb-programs-css-profile-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-css-profile.svg');
}
.cb-programs-powerfaids-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-powerfaids.svg');
}
.cb-programs-psat-nmsqt-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-psat-nmsqt.svg');
}
.cb-programs-psat-8-9-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-psat-8-9.svg');
}
.cb-programs-psat-10-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-psat-10.svg');
}
.cb-programs-sat-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-sat.svg');
}
.cb-programs-springboard-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-springboard.svg');
}

// ----------- after, Black

.cb-logo-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-logo-black.svg');
}
.cb-programs-search-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-search-black.svg');
}
.cb-programs-access-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-access-black.svg');
}
.cb-programs-accuplacer-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-accuplacer-black.svg');
}
.cb-programs-ap-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-ap-black.svg');
}
.cb-programs-pre-ap-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-pre-ap-black.svg');
}
.cb-programs-clep-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-clep-black.svg');
}
.cb-programs-css-profile-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-css-profile-black.svg');
}
.cb-programs-powerfaids-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-powerfaids-black.svg');
}
.cb-programs-psat-nmsqt-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-psat-nmsqt-black.svg');
}
.cb-programs-psat-8-9-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-psat-8-9-black.svg');
}
.cb-programs-psat-10-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-psat-10-black.svg');
}
.cb-programs-sat-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-sat-black.svg');
}
.cb-programs-springboard-black-a {
	.bk-image-a('@{cb-image-path-aws}cb-programs-springboard-black.svg');
}
