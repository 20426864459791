//
// $.fn.cbTaggedSidebar
// --------------------------------------------------

body{
    position: relative;
}
.cb-tagged-sidebar{
    .cb-tagged-sidenav {
      margin-bottom: 24px;
    }
    .nav {
      > li {
        > a {
          display: block;
          padding: 0 20px;
          font-size: 0.8125rem;
          line-height: 1.8461538461538463em;
          color: @gray1-corporate-color;
          border-left: 3px solid transparent;
          word-wrap: break-word;
          &:hover{
            text-decoration: underline;
            background-color: transparent;
          }
          &:focus{
            background-color: transparent;
          }
        }
        &.cb-nested {
          > a{
            .roboto-bold();
          }
        }
      }
      > .active {
        > a{
          padding-left: 20px;
          color: @cb-blue-link-color;
          background-color: transparent;
          border-left: 3px solid @blue-corporate-color;
          cursor: default;
        }
        &:hover{
          > a {
            color: @gray1-corporate-color;
          }
        }
        &:focus{
          > a {
            .cb-focus();
          }
        }
      }
      .nav {
        display: none;
        padding-bottom: 10px;
        > li {
          > a {
            padding-top: 1px;
            padding-bottom: 1px;
            padding-left: 30px;
            font-size: 0.75rem;
          }
          ul {
            padding-left: 20px;
          }
        }
        > .active{
          > a {
            padding-left: 30px;
            &::after{
              border: 0;
            }
          }
        }
      }
    }

    .back-to-top{
      display: none;
      padding: 0 10px;
      margin-top: 10px;
      margin-left: 14px;
      font-size: 0.75rem;
      line-height: 2em;
      color: @gray1-corporate-color;
      &:hover{
        color: @gray1-corporate-color;
        text-decoration: underline;
      }
    }
}

@media (min-width: @screen-sm-min) {
  .cb-tagged-sidebar {
    .back-to-top {
      display: block
    }
  }
}
@media (min-width: @screen-md-min) {
  .cb-tagged-sidebar{
    .nav {
      > .active {
        ul{
          display: block;
        }
      }
    }
    &.affix{
      position: fixed;
      top: 0;
      .cb-tagged-sidenav {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &.affix-bottom{
      position: absolute;
      .cb-tagged-sidenav {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
