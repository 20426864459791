//
// List group
// --------------------------------------------------

.list-group {
	&.cb-listgroup-right-icon {
		.list-group-item {
		  > .cb-icon {
		    float: right;
		    margin-left: 10px;
		  }
		  p {
		  	margin-right: 25px;
		  }
		}
	}
	&.cb-listgroup-left-icon {
		.list-group-item {
		  > .cb-icon {
		    float: left;
		    margin-right: 10px;
		  }
		  p {
		  	margin-left: 25px;
		  }
		}
	}
	&.cb-listgroup-right-badge {
		.list-group-item {
		  > .badge {
		    float: left;
		    margin-right: 10px;
		  }
		}
	}

	&.cb-listgroup-no-border {
		.list-group-item {
			border-right: 0;
			border-left: 0;
		}
	}
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.
.list-group-item {
  padding: 15px 15px 14px 15px;
  .roboto();
  font-size: @font-size-small;
  line-height: @line-height-small;
  color: @list-group-link-color;
  .badge {
	  .roboto-bold();
	  font-size: 0.75rem;
	  color: @white-color;
	  vertical-align: center;
	  background-color: @badge-bg;
	}
	.cb-icon  {
		display: block;
		width: 16px;
		height: 16px;
		font-size: @font-size-base;
		line-height: 1em;
	}

	&.active,
  &.active:hover,
  &.active:focus {
    color: @cb-base-font-color;
    background-color: @digital-field-gray;
    border-color: @list-group-border;
    text-decoration: underline;
  }
}


// Linked list items
//
// Use anchor elements instead of `li`s or `div`s to create linked list items.
// Includes an extra `.active` modifier class for showing selected items.

a.list-group-item {
  color: @list-group-link-color;

  .list-group-item-heading {
    color: @list-group-link-heading-color;
  }

  // Hover state
  &:hover {
    text-decoration: underline;
  	background-color: @list-group-link-bk;
  }
  &:focus {
    text-decoration: none;
    color: @list-group-link-color;
  	background-color: @list-group-link-bk;
  }
}