//
// CB Pager
// --------------------------------------------------

.cb-pager {
  float: left;
  width: 100%;
  border-top: 4px solid @gray20-color;
  .border-box();
  padding-top: 20px;
  margin-bottom: 24px;
  hr {
    display: none;
  }

  h3 {
    display: inline;
    line-height: 1.6842105263157894em;
  }

  .row {
    span {
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }

    div {
      &:last-child {
        text-align: right;
      }
    }
  }

  &.cb-no-prev .row div:first-child a {
    display: none;
  }

  &.cb-no-next .row div:last-child a {
    display: none;
  }

  &.cb-no-prev.cb-no-next {
    display: none;
  }

  &.cb-article span {
    display: none;
  }
}
