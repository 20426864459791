//
// Left navigation
// --------------------------------------------------

.cb-left-navigation {
  ul, li{
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none;
    background: none;
  }
  li{
      .roboto();
    a {
      border-bottom: 1px solid @gray-corporate-border;
      font-size: @font-size-small;
      line-height: @line-height-small-base;
    }
  }
  > ul {
    > li {
      &.cb-nested {
        &.active-trail{
          ul.menu{
            border-bottom: 1px solid @gray-corporate-border;
          }
        }
      }
      &.active-trail {
        > a{
          &::after {
            display: block;
            background: @blue-corporate-color;
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            content: '';
          }
        }
      }
    }
  }
  a {
    display: block;
    position: relative;
    padding: 12px 10px 11px 10px;
    &:focus{
      .cb-focus();
    }
  }
  li {
    &.collapsed{
      .menu {
        height: 0px;
        padding: 0;
        margin: 0;
        overflow: hidden;
        display: none;
      }
    }
    &.cb-nested {
      .roboto-bold();
      &.active-trail {
       li {
          &.active-trail{
            color: @cb-blue-link-color;
            &::before{
              .cb-glyphs();
              color: @blue-corporate-color;
              content: '\e958';
              display: list-item;
              position: absolute;
              font-size: 0.5rem;
              top: 20px;
              left: 2px;
            }
            li {
              color:@gray1-corporate-color;
            }
          }
        }
      }
      a{
        color: @gray1-corporate-color;
        &:hover {
          color: @gray1-corporate-color;
        }
      }
      &.cb-selected-child{
        &::after{
          content: '';
          display: block;
          position: absolute;

          left: 4px;
          top: 25px;
          border-left: 1px solid @blue-corporate-color;
          width: 15px;
        }
        &::before {
          color: @blue-corporate-color;
        }
      }
    }
    &.active-trail{
      li {
        &.cb-nested{
          &.active-trail {
            &::before{
              .cb-glyphs();
              content: '\e958';
              display: list-item;
              position: absolute;
              font-size: 0.5rem;
              top: 20px;
              left: 2px;
              color: @blue-corporate-color;
            }
          }
        }
      }
      > a{
        color: @cb-blue-link-color;
        &:hover{
          color: @cb-blue-link-color;
        }
      }
    }
    &.home {
      color: @black-corporate-color;
      margin: 0;
      h2, h3, h4 {
        .roboto-slab-bold();
        font-size: @font-size-base;
        line-height: @line-height-base;
        color: @black-corporate-color;
        padding:0;
        margin: 0;
      }
      & + li {
        border-top: 4px solid @blue-corporate-color;
        a {
          line-height: 1.4285714285714286em;
        }
      }
    }
    &.cb-selected-child{
      li {
        &:not(.cb-nested) {
          &.active-trail,
          &.active {
            &::after{
              content: '';
              display: block;
              position: absolute;

              left: -16px;
              top: 22px;
              border-top: 1px solid @blue-corporate-color;
              height: 10px;
              width: 19px;
            }
            &::before{
              color: @blue-corporate-color;
            }
          }
        }
      }
    }
    a{
      color: @gray1-corporate-color;
      &:hover {
        color: @gray1-corporate-color;
      }
    }
  }
  .active-trail,
    .active{
    > a {
      color: @cb-blue-link-color;
      &:hover {
        color: @cb-blue-link-color;
      }
    }
  }
  ul {
    ul {
      li {
        padding-left: 10px;
        margin-left: 10px;
        position: relative;
        &::before {
          .cb-glyphs();
          content: '\e90c';
          display: list-item;
          position: absolute;
          font-size: 0.5rem;
          top: 20px;
          left: 2px;
        }
        &:last-child{
          &:not(.cb-nested) {
            a {
              border: 0;
            }
          }
        }
      }
    }
  }
  // adjust borders
  li {
    &.cb-no-border{
      &.collapsed {
        > a {
          border-bottom: 0;
        }
      }
      &.active-trail {
        ul.menu {
          border-bottom: 0!important;
        }
      }
    }
  }
}