//
// Loaders and Progress Bars
// --------------------------------------------------

// loader without progress
.cb-loader {
	height: 32px;
  width: 32px;
  position: relative;
  border-radius: 100%;
  &::before {
    height: 32px;
    width: 32px;
    display: block;
    content: "";
    border-radius: 100%;
    -webkit-animation: rotation .8s infinite linear;
    -o-animation: rotation .8s infinite linear;
    animation: rotation .8s infinite linear;
  }

  // size
  &.cb-loader-lg {
    height: 64px;
    width: 64px;
    &::before {
      height: 64px;
      width: 64px;
      border-width: 7px !important;
    }
	  &::after{
      position: absolute;
      content: "loading";
      .roboto-bold();
      text-transform: capitalize;
      font-size: 0.625rem;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
           -o-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
	  }
    &.cb-loader-progress {
      &::after{
        font-size: 0.75rem;
        content: "1%";
      }
    }
  }
  &.cb-loader-md {
    height: 32px;
    width: 32px;
    &::before {
    	border-width: 5px !important;
  	  height: 32px;
  	  width: 32px;
    }
  }
  &.cb-loader-sm{
    height: 16px;
    width: 16px;
    &::before {
    	border-width: 3px !important;
  	  height: 16px;
  	  width: 16px;
    }
  }

  // color
  &.cb-loader-blue {
    &::before {
      border: 5px solid @cb-spinner-light-blue;
      border-top-color: @cb-spinner-dark-blue;
    }
    &.cb-loader-lg {
      &::after{
        color: @cb-spinner-dark-blue;
      }
    }
  }
  &.cb-loader-gray {
    &::before {
      border: 5px solid @cb-spinner-light-gray;
      border-top-color:@cb-spinner-dark-gray;
    }
    &.cb-loader-lg {
      &::after{
        color: @cb-spinner-dark-gray;
      }
    }
  }

  //type
  &.cb-radial-halo {
    padding: 10px;
    background-color: @white-color;
    &::before{
      position: absolute;
      top: 5px;
      left: 5px;
      -webkit-transform: translate(-5%, -5%);
      -ms-transform: translate(-5%, -5%);
      -o-transform: translate(-5%, -5%);
      transform: translate(-5%, -5%);
    }
    &.cb-loader-lg {
      height: 74px;
      width: 74px;
    }
    &.cb-loader-md {
      height: 42px;
      width: 42px;
    }
    &.cb-loader-sm {
      padding: 8px;
      height: 24px;
      width: 24px;
      &::before{
        top: 4px;
        left: 4px;
        -webkit-transform: translate(-4%, -4%);
        -ms-transform: translate(-4%, -4%);
        -o-transform: translate(-4%, -4%);
        transform: translate(-4%, -4%);
      }
    }
  }
  &.cb-white-radial {
    &::before {
      border: 5px solid @cb-spinner-white-radial;
      border-top-color: @white-color;
    }
    &.cb-loader-blue,
    &.cb-loader-gray {
      &::before {
        border: 5px solid @cb-spinner-white-radial;
        border-top-color: @white-color;
      }
    }
    &.cb-loader-lg {
      &::after{
        color: @white-color !important;
      }
    }
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

// loader with progress
.cb-progress-loader {
  position: relative;
  height: 32px;
  width: 32px;
  .cb-progressinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: @cb-spinner-dark-gray;
    border-radius: 100%;
    overflow: hidden;
    z-index: 1;
    .cb-lpane,
    .cb-rpane {
      content: "";
      position: absolute;
      top: -0.5%;
      left: 0;
      width: 52%;
      height: 101%;
      overflow: hidden;
      .cb-cover {
        position: absolute;
        top: -5%;
        left: 100%;
        width: 100%;
        height: 110%;
        background: @cb-spinner-light-gray;
        transform-origin: 0 50%;
        -ms-transform-origin: 0 50%;
        -moz-transform-origin: 0 50%;
        -o-transform-origin: 0 50%;
        -webkit-transform-origin: 0 50%
      }
    }
    .cb-rpane {
      left: 50%;
      .cb-cover {
        left: auto;
        right: 100%;
        transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
        -moz-transform-origin: 100% 50%;
        -o-transform-origin: 100% 50%;
        -webkit-transform-origin: 100% 50%
      }
    }
    .cb-display {
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: @white-color;
      text-align: center;
      .cb-displayprogress {
        position: absolute;
        .roboto-bold();
        font-size: 0.75rem;
        color: @cb-base-font-color;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }

  &.cb-loader-lg {
    height: 64px;
    width: 64px;
    .cb-display {
      height: 50px;
      width: 50px;
    }
  }
  &.cb-loader-md {
    height: 32px;
    width: 32px;
    .cb-progressinner {
      .cb-lpane{
      left: -0.5%;
      width: 50.5%;

      }
      .cb-rpane {
        left: 48%;
        width: 54%;
      }
    }
    .cb-display {
      height: 22px;
      width: 22px;
      .cb-displayprogress {
        display: none;
      }
    }
  }
  &.cb-loader-sm {
    height: 16px;
    width: 16px;
    .cb-display {
      height: 10px;
      width: 10px;
      .cb-displayprogress {
        display: none;
      }
    }
  }
  &.cb-loader-blue {
    .cb-progressinner {
       background: @cb-spinner-dark-blue;
      .cb-rpane,
      .cb-lpane {
        .cb-cover {
         background: @cb-spinner-light-blue;
        }
      }
      .cb-display{
        .cb-displayprogress {
          color: @cb-spinner-dark-blue;
        }
      }
    }
  }

  &.cb-loader-gray {
    .cb-progressinner {
       background: @cb-spinner-dark-gray;
      .cb-rpane,
      .cb-lpane {
        .cb-cover {
         background: @cb-spinner-light-gray;
        }
      }
    }
  }
  &.cb-radial-halo {
    &.cb-loader-lg {
      height: 74px;
      width: 74px;
      .cb-progressinner {
        border: 5px solid @white-color;
      }
    }
    &.cb-loader-md {
      height: 42px;
      width: 42px;
      .cb-progressinner {
        border: 5px solid @white-color;
      }
    }
    &.cb-loader-sm {
      height: 24px;
      width: 24px;
      .cb-progressinner {
        border: 4px solid @white-color;
      }
    }
  }
  &.cb-white-radial{
    .cb-display{
      .cb-displayprogress {
        color: @white-color !important;
      }
    }
    &.cb-loader-blue {
      .cb-progressinner {
        background: @cb-spinner-light-blue;
        .cb-rpane,
        .cb-lpane {
          .cb-cover {
           background: @white-color;
          }
        }
      .cb-display {
          background: @cb-spinner-dark-blue;
        }
      }
    }

    &.cb-loader-gray {
      .cb-progressinner {
         background: @cb-spinner-dark-gray;
        .cb-rpane,
        .cb-lpane {
          .cb-cover {
           background: @white-color;
          }
        }
      .cb-display {
          background: @cb-gray1-color;
        }
      }
    }
  }
}

//progress bar
.progress {
  overflow: hidden;
  height: 7px;
  margin-bottom: @line-height-computed;
  background-color: @cb-progress-bg;
  border-radius: @cb-progress-border-radius;
  .box-shadow(none);
  // Bar of progress
  .progress-bar {
    float: left;
    width: 0%;
    height: 100%;
    font-size: @font-size-small;
    line-height: @line-height-computed;
    color: @cb-progress-bar-color;
    text-align: center;
    background-color: @progress-bar-bg;
    .box-shadow(none);
    // .transition(width .6s ease);
    transition: none;
  }
  &.cb-progress-gray {
    background-color: @cb-spinner-light-gray;
    .progress-bar {
      background-color: @cb-spinner-dark-gray;
    }
  }
  &.cb-white-radial {
    background-color: @cb-spinner-white-radial;
    .progress-bar {
      background-color: @white-color;
    }
  }
  &.cb-radial-halo{
    height: 17px;
    border: 5px solid @white-color;
  }
}
