//
// Scaffolding
// --------------------------------------------------

html {
  font-size: 16px;
  line-height: 1.5em;
}
p {
  font-size: 1rem;
  line-height: 1.5em;
}
p, ul, ol {
  margin-bottom: @line-height-computed;
}
hr {
  margin-top:	1.4375em;
  margin-bottom: @line-height-computed;
  border-top: 1px solid @gray-corporate-border;
}
ul{
	&.cb-list-style{
		li {
			list-style: disc;
		}
		ul {
			li {
				list-style: circle;
			}
			ul {
				li {
					text-indent:-5px;
					list-style-type: none;
					&::before{
						content:"-";
						position:relative;
						left: -12px;
					}
				}
			}
		}
	}
	&.cb-list-style-checkbox{
		li {
			list-style: none !important;
			position: relative;
			padding-left: 8px;
			&::before {
				.cb-glyphs();
				font-size: 1rem;
				position: absolute;
				left: -17px;
				top: 4px;
				content: "\e95a";
			}
		}
	}

	&.cb-list-no-style,
	&.cb-no-style{
		list-style: none;
		li {
				list-style: none;
			}
	}
	&.cb-list-no-indent {
		padding-left: 0;
		li {
			margin-left: 18px;
		}
		&.cb-list-no-style {
			li {
				margin-left: 0;
			}
		}
	}
}
ol {
	&.cb-list-no-indent {
		padding-left: 0;
		li {
			margin-left: 18px;
		}
	}
}