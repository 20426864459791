//
// CB Event Module
// --------------------------------------------------

.cb-event {
  border-top: 4px solid @blue-corporate-color;
  border-bottom: 1px solid @blue-corporate-color;
  .border-box();
  padding: 12px 16px 23px 6px;
  line-height: 0;
  background-color: @white-color;
  width: 100%;
  > a {
    h2, h3, h4 {
      color: @cb-blue-link-color;
    }
    &:hover,
    &:focus {
      cursor: pointer;
      outline: 0;
      h2, h3, h4, p {
        text-decoration: underline;
      }
    }
  }
  .cb-event-date{
    display: inline-block;
    width: 100%;
    span{
      &.glyphicon-cb,
      &.cb-glyph {
        display: block;
        float: left;
        &::before {
          color: @cb-base-font-color;
        }
        &::after {
          background-color: @white-color;
        }
      }
    }
    p {
      margin-left: 8px;
      float: left;
      display: inline-block;
      .roboto();
      font-size: @font-size-small;
      line-height: 2.143em;
      text-transform: uppercase;
      color: @cb-base-font-color;
      margin-bottom: 0;
      span{
        &:nth-child(2) {
          margin-left: 6px;
          &::before {
            content: '';
            border-right: 1px solid @gray4-corporate-color;
            display: inline-block;
            width: 1px;
            position: relative;
            top: 1px;
            height: 11px;
            margin-right: 6px;
          }

          span{
            .roboto-bold();
          }
        }
      }
      &.cb-multi {
        line-height: 15px;
        span {
          display: block;
          span {
            display: inline-block;
          }
          &:nth-child(2) {
            margin-left: 0;
            &::before {
              border: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
  > a {
    &:hover,
    &:focus {
      .cb-event-date{
        span{
          &.glyphicon-cb,
          &.cb-glyph {
            display: block;
            float: left;
            &::before {
              color: @white-color;
            }
            &::after {
              background-color: @blue-accent2-corporate-color;
            }
          }
        }
      }
    }
  }
  .cb-event-content {
    min-height: 24px;
    margin-left: 40px;
    h2, h3, h4{
      .roboto-slab();
      margin: 0;
      font-size: @font-size-base;
      line-height: @line-height-base;
    }

    .cb-event-description{
      .roboto();
      font-size: @font-size-small;
      line-height: @line-height-small-base;
      margin-bottom: 0;
      color: @cb-base-font-color;
    }
  }
  &.focus{
    border-color: @blue-corporate-color;
  }
  &.prev-focus{
    border-right-color: @blue-corporate-color;
  }
}

.text-dimension-calculation {
  display: inline-block;
}

.cb-event-band {
  background-color: @white-color;
  .make-event-row();
  .cb-event {
    .make-event-columns();
  }

  // One item
  .cb-event:first-child:nth-last-child(1),
  .cb-event:first-child:nth-last-child(1) ~ .cb-event,
  // two items
  .cb-event:first-child:nth-last-child(2),
  .cb-event:first-child:nth-last-child(2) ~ .cb-event {
    width: calc(100% - (@grid-gutter-width));

    // CB guidlines
    @media (min-width: @screen-sm-min) {
      //tablet: 2
      width: calc(50% - (@cb-grid-gutter-width-sm - (@cb-grid-gutter-width-sm / 4)));
    }
    @media (min-width: @screen-md-min) {
      //desktop: 2
      width: calc(50% - (@cb-grid-gutter-width-md - (@cb-grid-gutter-width-md / 4)));
    }
    @media (min-width: @screen-lg-min) {
      //oversize: 2
      width: calc(50% - (@cb-grid-gutter-width-lg - (@cb-grid-gutter-width-lg / 4)));
    }
  }
}

.cb-event-container {
  .cb-event {
    &:not(:nth-child(1)) {
      margin-top: (@cb-grid-gutter-width-xs / 2);
    }
    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
      &:not(:nth-child(1)) {
        margin-top: (@cb-grid-gutter-width-sm / 2);
      }
    }
    @media (max-width: @screen-md-max) and (min-width: @screen-md-min) {
      &:not(:nth-child(1)) {
        margin-top: (@cb-grid-gutter-width-md / 2);
      }
    }
    @media (min-width: @screen-lg-min) {
      &:not(:nth-child(1)) {
        margin-top: (@cb-grid-gutter-width-lg / 2);
      }
    }
  }
}

@media (max-width: @screen-xs-max) { // mobile
  .cb-event {
    .cb-event-content {
      height: auto !important;
    }
  }
}

// -- program specific colors
[data-cb-program="corporate"],
.cb-blue-corporate-program{
  .cb-event{
    border-top: 4px solid @blue-corporate-color;
    border-bottom: 1px solid @blue-corporate-color;
    > a {
      &:hover,
      &:focus {
        .cb-event-date{
          span{
            &::before {
              color: @white-color;
            }
            &::after {
              background-color: @blue-corporate-color;
            }
          }
        }
      }
    }
    .cb-event-date{
      span{
        &::before {
          color: @blue-corporate-color;
        }
      }
    }
  }
}

[data-cb-program="k-12"],
.cb-k-12-program{
  .cb-event{
    border-top: 4px solid @k-12-color;
    border-bottom: 1px solid @k-12-color;
    > a {
      &:hover,
      &:focus {
        .cb-event-date{
          span{
            &::before {
              color: @white-color;
            }
            &::after {
              background-color: @k-12-color;
            }
          }
        }
      }
    }
    .cb-event-date{
      span{
        &::before {
          color: @k-12-color;
        }
      }
    }
  }
}

[data-cb-program="higher-education"],
.cb-higher-education-program{
  .cb-event{
    border-top: 4px solid @higher-education-color;
    border-bottom: 1px solid @higher-education-color;
    > a {
      &:hover,
      &:focus {
        .cb-event-date{
          span{
            &::before {
              color: @white-color;
            }
            &::after {
              background-color: @higher-education-color;
            }
          }
        }
      }
    }
    .cb-event-date{
      span{
        &::before {
          color: @higher-education-color;
        }
      }
    }
  }
}

[data-cb-program="access"],
.cb-access-program{
  .cb-event{
    border-top: 4px solid @access-color;
    border-bottom: 1px solid @access-color;
    > a {
      &:hover,
      &:focus {
        .cb-event-date{
          span{
            &::before {
              color: @white-color;
            }
            &::after {
              background-color: @access-color;
            }
          }
        }
      }
    }
    .cb-event-date{
      span{
        &::before {
          color: @access-color;
        }
      }
    }
  }
}