//
// Filter List
// Focus: custom
// --------------------------------------------------

.cb-filter-list {
  ul {
    &.cb-filter-list-desktop {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 24px;
        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          color: @cb-base-font-color;
          border-bottom: 2px solid @white-color;
          transition: border-bottom .25s ease-in-out;
          &:hover,
          &:focus {
            color: @cb-base-font-color;
            text-decoration: none;
            border-bottom: 2px solid @black-corporate-color;
          }
        }
        &.active {
          a {
            border-bottom: 2px solid @black-corporate-color;
          }
        }
      }
    }
  }
}