//
// Spacer
// --------------------------------------------------

//--------- Vertical
spacer,
spacervxs,
.cb-spacer,
.cb-spacerv-xs {
	display: block;
	height: 24px;
}
.cb-spacer-top,
.cb-spacerv-xs-top {
	margin-top: 24px;

}
.cb-spacer-bottom,
.cb-spacerv-xs-bottom {
	margin-bottom: 24px;
}

spacerdouble,
spacervsm,
.cb-spacer-double,
.cb-spacerv-sm {
	display: block;
	height: 48px;
}
.cb-spacer-double-top,
.cb-spacerv-sm-top {
	margin-top: 48px;

}
.cb-spacer-double-bottom,
.cb-spacerv-sm-bottom {
	margin-bottom: 48px;
}

//--------- Horizontal
spacerhxs,
.cb-spacerh-xs {
	display: inline-block;
	width: 8px;
}
.cb-spacerh-xs-left{
	margin-left: 8px;
}
.cb-spacerh-xs-right {
	margin-right: 8px;
}

spacerhsm,
.cb-spacerh-sm{
	display: inline-block;
	width: 16px;
}

.cb-spacerh-sm-left {
	margin-left: 16px;
}
.cb-spacerh-sm-right {
	margin-right: 16px;
}


spacerhmd,
.cb-spacerh-md{
	display: inline-block;
	width: 24px;
}

.cb-spacerh-md-left {
	margin-left: 24px;
}
.cb-spacerh-md-right {
	margin-right: 24px;
}
