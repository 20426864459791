// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

.form-control-validation(@text-color: @davy-grey-color; @border-color: @cb-form-input-border-color; @background-color: @cb-form-control-bk-color; @glyph: '') {
  // Color the label and help text
  .help-block,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline  {
    color: @text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: @border-color;
    //.box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken(@border-color, 10%);
      // @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@border-color, 20%);
      // .box-shadow(@shadow);
      .box-shadow(none);
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: @text-color;
    border-color: @border-color;
    background-color: @background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: @text-color;
  }
  .cb-input-state {
    position: relative;
    i.@{glyph} {
      position: absolute;
      color: @text-color;
      top: 16px;
      right: 12px;
    }
    input{
      padding-right: 36px;
      &:focus {
        &+ i.@{glyph} {
          color: darken(@border-color, 10%);
        }
      }
    }
  }
}

.focus-shadow(@border-color) {
  @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@border-color, 20%);
  .box-shadow(@shadow);
}

.hover-effect() {
  -webkit-box-shadow:inset 0 0 2px 1px @cb-form-input-shadow-hover-color;
  box-shadow: inset 0 0 2px 1px @cb-form-input-shadow-hover-color;
  box-shadow: inset 0 0 2px 1px @cb-form-input-shadow-hover-color;
  border-color: @cb-form-input-border-hover-color;
}

.focus-border-shadow(@border-color) {
  //.focus-shadow(@border-color);
  //border-color: darken(@border-color, 10%);
  border-color: @border-color;
}

.focus-outline(@border-color) {
  @color: lighten(@border-color, 20%);
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto @color;
  outline-offset: -2px;
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-border-focus` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
.form-control-focus(@color: @input-border-focus) {
  @color-rgba: rgba(red(@color), green(@color), blue(@color), .6);
  &:focus {
    border-color: @color;
    outline: 0;
    .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-rgba}");
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!
.input-size(@input-height; @padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  height: @input-height;
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;

  select& {
    height: @input-height;
    line-height: @input-height;
  }

  textarea&,
  select[multiple]& {
    height: auto;
  }
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.

.input-sm() {
  .input-size(@input-height-small; @padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}

.input-lg() {
  .input-size(@input-height-large; @padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large);
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
//
// Heads up! This is mixin-ed into `.navbar-form` in navbars.less.

.form-inline(){

  // Kick in the inline
  @media (min-width: @screen-sm-min) {
    // Inline-block all the things for "inline"
    .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }

    // In navbar-form, allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    .input-group {
      display: inline-table;
      vertical-align: middle;

      .input-group-addon,
      .input-group-btn,
      .form-control {
        width: auto;
      }
    }

    // Input groups need that 100% width though
    .input-group > .form-control {
      width: 100%;
    }

    .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match (which also avoids
    // a bug in WebKit: https://github.com/twbs/bootstrap/issues/1969).
    .radio,
    .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }
    }
    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
    }

    // Validation states
    //
    // Reposition the icon because it's now within a grid column and columns have
    // `position: relative;` on them. Also accounts for the grid gutter padding.
    .has-feedback .form-control-feedback {
      top: 0;
    }
  }
}

.form-control-validation-checkbox-radio(@border-color;) {
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline  {
    color: @cb-base-font-color;
  }
  .checkbox,
  .checkbox-inline {
    span::before{
      color: @border-color !important;
    }
    input[type="checkbox"]{
      &:focus {
        + span::before{
          color: darken(@border-color, 10%)!important;
        }
      }
    }
  }
  .radio,
  .radio-inline {
    span::before{
      color: @border-color !important;
    }
    input[type="radio"]{
      &:focus {
        + span::before{
          color: darken(@border-color, 10%)!important;
        }
      }
    }
  }
}

