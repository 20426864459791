//
// Tooltip
// --------------------------------------------------

.tooltip {
  font-size: @font-size-tooltip;

	.tooltip-inner {
	  padding: 5px 10px;
	  font-size: 1em;
	  .roboto-bold();
	  border-radius: @cb-border-radius-tooltip;
	  .box-shadow(0 5px 10px rgba(0, 0, 0, .1));
	}

	&.cb-tooltip-dark{
		.tooltip-inner {
	  	background-color: @white-color;
	  	color: @gray1-corporate-color;
		}
		&.top .tooltip-arrow {
	    border-top-color: @white-color;
	  }
	  &.top-left .tooltip-arrow {
	    border-top-color: @white-color;
	  }
	  &.top-right .tooltip-arrow {
	    border-top-color: @white-color;
	  }
	  &.right .tooltip-arrow {
	    border-right-color: @white-color;
	  }
	  &.left .tooltip-arrow {
	    border-left-color: @white-color;
	  }
	  &.bottom .tooltip-arrow {
	    border-bottom-color: @white-color;
	  }
	  &.bottom-left .tooltip-arrow {
    	border-bottom-color: @white-color;
	  }
	  &.bottom-right .tooltip-arrow {
	    border-bottom-color: @white-color;
	  }
	}
}