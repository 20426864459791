//
// Multi color Glyphicons
// --------------------------------------------------
.cb-glyph-multi {
	position: relative;
	display: inline-block;
	width: 1em;
	height: 1em;
	border-radius: 50%;

  .cb-glyphs-multi();
	&::before,
	&::after {
	  position: absolute;
	  top: 0;
	  left: 0;
	}

		// size
	font-size: 4rem;
  &.cb-glyph-sm {
    font-size: calc(unit(@font-size-base, rem) * 2)!important;
  }
  &.cb-glyph-lg {
    font-size: calc(unit(@font-size-base, rem) * 4)!important;
  }
  &.cb-glyph-xl {
    font-size: calc(unit(@font-size-base, rem) * 6)!important;
  }

	// Color
  .glyph-multi-bk-color (@white-color, @yellow-corporate-color, @blue-accent1-corporate-color);
	&[class^="cb-glyph-hover-"] {
    transition: all .5s ease-out;
	}
	&.cb-glyph-hover-rotate {
		&:hover {
			transition: 0.9s;
			transform: rotateY(180deg);
		}
	}
	&.cb-glyph-hover-flip-color {
		&:hover {
			.glyph-multi-content-color(@black-color, @white-color);
		}
	}
	&.cb-glyph-corporate1 {
	  .glyph-multi-bk-color (@white-color, @yellow-corporate-color, @blue-accent1-corporate-color);
	}

	&.cb-glyph-corporate2 {
	  .glyph-multi-bk-color (@white-color, @black-color, @blue-accent2-corporate-color);
	}
	&.cb-glyph-corporate3 {
	  .glyph-multi-bk-color (@black-color, @black-color, @yellow-corporate-color);
	}
	&.cb-glyph-k-12 {
	  .glyph-multi-bk-color (@white-color, @black-color, @k-12-color);
	}
	&.cb-glyph-k-12-accent {
	  .glyph-multi-bk-color (@white-color, @black-color, @k-12-accent-color);
	}
	&.cb-glyph-k-12-accent2 {
	  .glyph-multi-bk-color (@white-color, @black-color, @blue-accent1-corporate-color);
	}
	&.cb-glyph-higher-education {
	  .glyph-multi-bk-color (@white-color, @black-color, @higher-education-color);
	}
	&.cb-glyph-higher-education-accent {
	  .glyph-multi-bk-color (@white-color, @black-color, @higher-education-accent-color);
	}
	&.cb-glyph-access {
	  .glyph-multi-bk-color (@white-color, @black-color, @access-color);
	}
	&.cb-glyph-access-accent {
	  .glyph-multi-bk-color (@white-color, @black-color, @access-accent-color);
	}

	&.cb-exam {
		.glyph-multi-content("\e921", "\e944");
	}
	&.cb-send-scores {
		.glyph-multi-content("\e925", "\e945");
	}
	&.cb-submit-documents {
		.glyph-multi-content("\e92a", "\e946");
	}
	&.cb-submit-portfolio {
		.glyph-multi-content("\e932", "\e952");
	}
	&.cb-accessible {
		.glyph-multi-content("\e900", "\e97c");
	}
	&.cb-app {
		.glyph-multi-content("\e901", "\e97d");
	}
	&.cb-bag {
		.glyph-multi-content("\e902", "\e97e");
	}
	&.cb-barchart {
		.glyph-multi-content("\e903", "\e97f");
	}
	&.cb-batch-download{
		.glyph-multi-content("\e904", "\e980");
	}
	&.cb-batch-upload{
		.glyph-multi-content("\e905", "\e981");
	}
	&.cb-bell{
		.glyph-multi-content("\e906", "\e982");
	}
	&.cb-book{
		.glyph-multi-content("\e907", "\e983");
	}
	&.cb-bullet-list{
		.glyph-multi-content("\e908", "\e984");
	}
	&.cb-bullet-list-doc{
		.glyph-multi-content("\e909", "\e985");
	}
	&.cb-calculator{
		.glyph-multi-content("\e90a", "\e986");
	}
	&.cb-calendar-empty{
		.glyph-multi-content("\e90b", "\e987");
	}
	&.cb-calendar-full{
		.glyph-multi-content("\e90c", "\e988");
	}
	&.cb-cart{
		.glyph-multi-content("\e90d", "\e989");
	}
	&.cb-chat{
		.glyph-multi-content("\e90e", "\e98a");
	}
	&.cb-check{
		.glyph-multi-content("\e910", "\e98b");
	}
	&.cb-check-list{
		.glyph-multi-content("\e911", "\e98c");
	}
	&.cb-check-list-doc{
		.glyph-multi-content("\e912", "\e98d");
	}
	&.cb-college-reputation{
		.glyph-multi-content("\e914", "\e98e");
	}
	&.cb-compose{
		.glyph-multi-content("\e915", "\e98f");
	}
	&.cb-computer{
		.glyph-multi-content("\e916", "\e990");
	}
	&.cb-credit-card{
		.glyph-multi-content("\e917", "\e991");
	}
	&.cb-dbl-left{
		.glyph-multi-content("\e91a", "\e994");
	}
	&.cb-dbl-right{
		.glyph-multi-content("\e91b", "\e995");
	}
	&.cb-dollar{
		.glyph-multi-content("\e91d", "\e997");
	}
	&.cb-download{
		.glyph-multi-content("\e91f", "\e999");
	}
	&.cb-educator{
		.glyph-multi-content("\e920", "\e99a");
	}
	&.cb-error{
		.glyph-multi-content("\e922", " ");
	}
	&.cb-everyday-words{
		.glyph-multi-content("\e924", "\e99c");
	}
	&.cb-expand-window{
		.glyph-multi-content("\e926", "\e99e");
	}
	&.cb-fax{
		.glyph-multi-content("\e929", "\e9a0");
	}
	&.cb-full-length-practice{
		.glyph-multi-content("\e92b", "\e9a1");
	}
	&.cb-globe{
		.glyph-multi-content("\e92c", "\e9a2");
	}
	&.cb-graduate-pro-school{
		.glyph-multi-content("\e92d", "\e9a3");
	}
	&.cb-graduation{
		.glyph-multi-content("\e92e", "\e9a4");
	}
	&.cb-home{
		.glyph-multi-content("\e92f", "\e9a5");
	}
	&.cb-information{
		.glyph-multi-content("\e930", "\e9a6");
	}
	&.cb-instagram{
		.glyph-multi-content("\e931", " ");
	}
	&.cb-facebook{
		.glyph-multi-content("\e928", " ");
	}
	&.cb-youtube{
		.glyph-multi-content("\e97b", " ");
	}
	&.cb-light-bulb{
		.glyph-multi-content("\e933", "\e9a8");
	}
	&.cb-linkedin{
		.glyph-multi-content("\e934", " ");
	}
	&.cb-list{
		.glyph-multi-content("\e935", "\e9a9");
	}
	&.cb-local-menu{
		.glyph-multi-content("\e936", "\e9aa");
	}
	&.cb-location{
		.glyph-multi-content("\e937", "\e9ab");
	}
	&.cb-lock{
		.glyph-multi-content("\e938", "\e9ac");
	}
	&.cb-lock-open{
		.glyph-multi-content("\e939", "\e9ad");
	}
	&.cb-mail{
		.glyph-multi-content("\e93a", "\e9ae");
	}
	&.cb-matrix{
		.glyph-multi-content("\e93b", "\e9af");
	}
	&.cb-megaphone{
		.glyph-multi-content("\e93c", "\e9b0");
	}
	&.cb-menu{
		.glyph-multi-content("\e93d", "\e9b1");
	}
	&.cb-minus{
		.glyph-multi-content("\e93e", "\e9b2");
	}
	&.cb-nav-links{
		.glyph-multi-content("\e93f", "\e9b3");
	}
	&.cb-new-doc{
		.glyph-multi-content("\e941", "\e9b5");
	}
	&.cb-new-window{
		.glyph-multi-content("\e942", "\e9b6");
	}
	&.cb-note{
		.glyph-multi-content("\e947", "\e9b9");
	}
	&.cb-online-tool{
		.glyph-multi-content("\e948", "\e9ba");
	}
	&.cb-pending{
		.glyph-multi-content("\e949", "\e9bb");
	}
	&.cb-phone{
		.glyph-multi-content("\e94a", "\e9bc");
	}
	&.cb-phone-alt{
		.glyph-multi-content("\e94b", "\e9bd");
	}
	&.cb-plus{
		.glyph-multi-content("\e94c", "\e9be");
	}
	&.cb-practice{
		.glyph-multi-content("\e94d", "\e9bf");
	}
	&.cb-practice-test{
		.glyph-multi-content("\e94e", "\e9c0");
	}
	&.cb-presentation{
		.glyph-multi-content("\e94f", "\e9c1");
	}
	&.cb-print{
		.glyph-multi-content("\e950", "\e9c2");
	}
	&.cb-problem-feedback{
		.glyph-multi-content("\e951", "\e9c3");
	}
	&.cb-raided-pencil{
		.glyph-multi-content("\e955", "\e9c5");
	}
	&.cb-resources{
		.glyph-multi-content("\e956", "\e9c6");
	}
	&.cb-rupee{
		.glyph-multi-content("\e958", "\e9c8");
	}
	&.cb-save-date{
		.glyph-multi-content("\e959", "\e9c9");
	}

	&.cb-science{
		.glyph-multi-content("\e95d", "\e9cc");
	}
	&.cb-search{
		background-image: none!important;
		.glyph-multi-content("\e95e", "\e9cd");
	}
	&.cb-series{
		.glyph-multi-content("\e95f", "\e9ce");
	}
	&.cb-settings{
		.glyph-multi-content("\e960", "\e9cf");
	}
	&.cb-share{
		.glyph-multi-content("\e961", "\e9d0");
	}
	&.cb-share-alt{
		.glyph-multi-content("\e962", "\e9d1");
	}
	&.cb-signed-in{
		.glyph-multi-content("\e963", "\e9d2");
	}
	&.cb-sound{
		.glyph-multi-content("\e966", "\e9d4");
	}
	&.cb-special-edition{
		.glyph-multi-content("\e969", "\e9d5");
	}
	&.cb-tag{
		.glyph-multi-content("\e96b", "\e9d6");
	}
	&.cb-task-complete{
		.glyph-multi-content("\e96d", "\e9d8");
	}
	&.cb-teacher-edition{
		.glyph-multi-content("\e96e", "\e9d9");
	}
	&.cb-team-leader{
		.glyph-multi-content("\e96f", "\e9da");
	}
	&.cb-test-scored{
		.glyph-multi-content("\e970", "\e9db");
	}
	&.cb-tuitinon-financial-aid{
		.glyph-multi-content("\e971", "\e9dc");
	}
	&.cb-twitter{
		.glyph-multi-content("\e972", " ");
	}
	&.cb-upload{
		.glyph-multi-content("\e974", "\e9de");
	}
	&.cb-user{
		.glyph-multi-content("\e975", " ");
	}
	&.cb-video-lesson{
		.glyph-multi-content("\e976", "\e9df");
	}
	&.cb-window{
		.glyph-multi-content("\e978", " ");
	}
	&.cb-work-together{
		.glyph-multi-content("\e979", "\e9e0");
	}
	&.cb-quote-left{
		.glyph-multi-content("\e913", "\e91c");
	}
	&.cb-quote-right{
		.glyph-multi-content("\e91e", "\e919");
	}
	&.cb-add-user{
		.glyph-multi-content("\e9e2", "\e9ec");
	}
	&.cb-dollars {
		.glyph-multi-content("\e9e3", "\e9ed");
	}
	&.cb-essential-math {
		.glyph-multi-content("\e9e4", "\e9ee");
	}
	&.cb-explore {
		.glyph-multi-content("\e9e5", "\e9ef");
	}
	&.cb-face-happy {
		.glyph-multi-content("\e9e6", "\e9d3");
	}
	&.cb-face-unhappy {
		.glyph-multi-content("\e9e7", "\e9d3");
	}
	&.cb-face-unsure {
		.glyph-multi-content("\e9e8", "\e9d3");
	}
	&.cb-maths {
		.glyph-multi-content("\e9ea", "\e9f3");
	}
	&.cb-school {
		.glyph-multi-content("\e9eb", "\e9f4");
	}
	&.cb-khan-academy {
		.glyph-multi-content("\e90f", " ");
	}
}