//
// Typography
// --------------------------------------------------

h1, .h1 {
  font-family: @cb-font-family-h1;
  font-size: @font-size-h1;
  line-height: @cb-line-height-h1;
  margin: 0 0 24px 0;
}
h2, .h2 {
  font-family: @cb-font-family-h2;
  font-size: @font-size-h2;
  line-height: @cb-line-height-h2;
  margin: 0 0 24px 0;
}
h3, .h3 {
  font-family: @cb-font-family-h3;
  font-size: @font-size-h3;
  line-height: @cb-line-height-h3;
  margin: 0 0 24px 0;
}
h4, .h4 ,
h5, .h5 {
  font-family: @cb-font-family-h4;
  font-size: @font-size-h4;
  line-height: @cb-line-height-h4;
  margin: 0;
}
h6, .h6 {
  font-family: inherit;
  font-size: @font-size-h6;
  line-height: @cb-line-height-h6;
  margin: 0;
}

// heading with border
.cb-heading-border-four{
  line-height: 24px;
  padding-bottom: 12px;
  border-bottom: 4px solid @gray1-corporate-color;
  margin: 0 0 20px 0;
}



// Same as h3
legend{
  font-family: @font-family-RobotoSlab;
  font-size: @font-size-h3;
  line-height: @cb-line-height-h3;
}

// Lists
dt{
  margin-left: @cb-list-offset-left;
  .roboto-bold();
}

dd{
  margin-left: @cb-list-offset-left;
}
ul,
ol {
  padding-left: (@cb-list-offset-left * 2);
}

li{
  ul,
  ol {
    padding-left: @cb-list-offset-left;
  }
  ul{
    list-style-type: disc;
  }
}

.cb-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Blockquotes
blockquote {
  &:not(.cb-testimonial):not(.cb-testimonial-half):not(.cb-testimonial-full) {
    font-family: @font-family-RobotoSlab;
    border: 0;
    border-top: 1px solid @cb-blockquote-border-color;
    border-bottom: 1px solid @cb-blockquote-border-color;

    margin: 23px 0;
    padding: 30px 0;
    position: relative;

   &::before {
      .cb-glyphs();
      color: @cb-blockquote-icon-color;
      font-size: 1.5rem;
      position: absolute;
      content: "\e93d";
      left: 0;
      top: 33px;
    }
    &::after {
      .cb-glyphs();
      color: @cb-blockquote-icon-color;
      font-size: 1.5rem;
      position: absolute;
      content: "\e93e";
      right: 0;
      bottom: 33px;
    }

    p {
      text-align:  center;
      color: @cb-blockquote-color;
      font-size: @cb-blockquote-font-size;
      line-height: @cb-blockquote-line-height;
      padding: 0 30px;
      margin: 0 25px;
      @media screen and (max-width: @screen-xs-max) {
        font-size: @cb-blockquote-mobile-font-size;
        line-height: @cb-blockquote-mobile-line-height;
        padding: 0 15px;
        margin: 0 25px;
      }
    }
  }
}

// Font family
.serifaStd-light{
  .serifaStd-light();
}
.serifaStd-roman{
  .serifaStd-roman();
}
.serifaStd-bold{
  .serifaStd-bold();
}
.helvetica-bold{
  .helvetica-bold();
}
.helvetica-regular{
  .helvetica-regular();
}
.helveticaNeue-bold{
  .helveticaNeue-bold();
}
.helveticaNeue-regular{
  .helveticaNeue-regular();
}
.roboto,
.cb-roboto {
  .roboto();
}
.roboto-bold,
.cb-roboto-bold  {
  .roboto-bold();
}
.roboto-italic,
.cb-roboto-italic {
  .roboto-italic();
}
.roboto-slab,
.cb-roboto-slab {
  .roboto-slab();
}
.roboto-slab-bold,
.cb-roboto-slab-bold {
  .roboto-slab-bold();
}
