//
// Fonts
// --------------------------------------------------
body {
	.font-smoothing();
}

@font-face {
  .fontface('SerifaStd-Light', '@{gv-font-serifastd-light-webfont}', 'SerifaStd45Light');
}
@font-face {
  .fontface('SerifaStd-Roman', '@{gv-font-serifastd-roman-webfont}', 'SerifaStd55Roman');
}
@font-face {
  .fontface('SerifaStd-Bold', '@{gv-font-serifastd-bold-webfont}', 'SerifaStd65Bold');
}
@font-face {
 	.fontface('RobotoSlab-Light', '@{gv-font-robotoslab-light}', '');
}
@font-face {
  .fontface2('Roboto', '@{gv-font-roboto-regular}', 'Roboto-Regular', 'normal');
}
@font-face {
  .fontface2('Roboto Bold', '@{gv-font-roboto-bold}', 'Roboto-Bold', 'bold');
}
@font-face {
  .fontface2('Roboto Italic', '@{gv-font-roboto-italic}', 'Roboto-Italic', 'normal', 'italic');
}
@font-face {
  .fontface2('Roboto Slab', '@{gv-font-robotoslab-regular}', 'RobotoSlab-Regular', 'normal');
}
@font-face {
  .fontface2('Roboto Slab Bold', '@{gv-font-robotoslab-bold}', 'RobotoSlab-Bold', 'bold');
}

// glyphs
@font-face {
  .fontface('CB Icons', '@{gv-font-cb-icons}', 'cb-icons-font-svg-id');
}
@font-face {
  .fontface('CB Icons Multi', '@{gv-font-cb-icons-multi}', 'cb-icons-multi-font-svg-id');
}
@font-face {
  .fontface('CB Icons Logo', '@{gv-font-cb-icons-logo}', 'cb-icons-logo-font-svg-id');
}


