//
// Menu List
// --------------------------------------------------

.cb-menu-list {
  @media (min-width: @screen-md) { //desktop, oversized
    margin-bottom: 30px;
  }
  @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { // tablet
    margin-bottom: 20px;
  }

  @media (max-width: @screen-xs-max) { // mobile
    margin-bottom: 24px;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
    li {
      width: 100%;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid @gray-corporate-border;
      > h2, > .h2,
      > h3, > .h3,
      > h3, > .h3,
      > h4, > .h4,
      > h5, > .h5 {
        border-top: 4px solid @cb-base-font-color;
        .roboto-slab-bold();
        font-size: 1.125rem;
        line-height: 1.3333333333333333em;
        padding: 8px 48px 11px 16px;
        margin: 0;
        &+ p {
          margin-top: -12px;
          font-size: 0.875rem;
          line-height: 1.7142857142857142em;
          margin-bottom: 12px;
          padding: 0 48px 0 16px;
        }
      }

      a {
        display: block;
        color: @cb-base-font-color;
        position: relative;
        padding: 12px 48px 11px 16px;
        background-color: @white-color;
        &::after {
          .cb-glyphs();
          color: @gray3-corporate-color;
          display: block;
          position: absolute;
          content: "\e940";
          width: 1rem;
          height: 1rem;
          right: 16px;
          top: 16px;
        }

        &.focus {
          .cb-focus();
        }
      }

      &:nth-of-type(1){
        a {
          border-top: 4px solid @cb-base-font-color;
          padding: 8px 48px 11px 16px;
          &::after {
            top: 12px;
          }

          h3, .h3,
          h4, .h4{
            .roboto-slab-bold();
            font-size: 1.125rem;
            line-height: 1.3333333333333333em;
            margin: 0;
          }
          p {
            font-size: 0.875rem;
            line-height: 1.7142857142857142em;
            margin-bottom: 0;
          }
        }
      }

    }
  }
}