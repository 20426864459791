//
// Popover, Button/Link With Menu Items
// --------------------------------------------------

.popover {
	.box-shadow(0 5px 10px rgba(0, 0, 0, .1));
	.popover-title {
    border-bottom: 1px solid @gray4-corporate-color;
  }
  .popover-content {
	  &.cb-popover-contnet-height {
	  	min-height: 96px;
	  }
  }
}
.cb-popover-button-list{
	min-width: 273px;
	text-align: left;
	padding: 18px;
	font-size: @font-size-base;
	span{
		&.glyphicon-cb,
		&.cb-glyph{
			padding-top: 4px;
		}
	}
}
.cb-popover-list-content {
	.popover-content{
		padding: 0;
		font-size: @font-size-popOver;
	}
	.cb-popover-list{
		list-style-type: none;
		min-width: 273px;
		padding:0;
		margin: 0;
		font-size: @font-size-small;
		position: relative;
		z-index: 10;
		padding: 0;
		li {
			width:100%;
			display: inline-block;
			padding:0;
			margin:0;
			text-transform: capitalize;
			border-bottom: 1px solid @popover-border-color;
			&:hover{
				background-color: @cb-popove-list-hover-color;
			}
			&:last-child{
				border-bottom: 0;
			}
			a {
				.roboto-bold();
				margin: 5px 0 ;
				display: block;
				width: 100%;
				padding: 10px 21px;
				letter-spacing: 0.032em;
			}
			span{
				&.glyphicon-cb,
				&.cb-glyph{
					float: right;
				}
			}
		}
	}
}