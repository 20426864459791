//
// Filter component
// --------------------------------------------------

// Filter Button
.cb-filter-btn {
	&.cb-open  {
		background: @white-color;
		.cb-plus {
			&::before{
				content: "\e92c";
			}
		}
	}
}

// Filter Block, Accordion
.cb-filter-container{
	padding: 0;
	width: 100%;
	border-bottom: 1px solid @gray-corporate-border;
	label {
		margin-bottom: 0;
	}
	&.cb-filter-no-tag:not(.in) {
		border-bottom: 0;
	}
	.cb-filter-selected {
		border-bottom: 0;
	}

	.cb-filter-options {
		padding: 23px 24px 24px;

		&.in + .cb-filter-selected {
			margin-top: 0;
		}
	}
}

// Combined
.cb-filter-button {
	text-align: right;
	&.cb-open  {
		border-bottom: 1px solid @gray-corporate-border;
	}
	.cb-filter-btn {
		&.cb-open  {
			border-color: @gray-corporate-border;
			border-bottom: 0;
			margin-bottom: -1px;
		}
	}
	&:not(.cb-open) {
		&+.cb-filter-container{
			&:not(.in) {
				.cb-filter-selected {
					margin-top: 24px;
				}
			}
		}
	}
}

// Filter  elements/tags
.cb-filter-selected {
	padding: 0 24px 24px 24px;
	font-size: 0;
	border-top: 1px solid @gray-corporate-border;
	border-bottom: 1px solid @gray-corporate-border;
	min-height: 50px;
  display: grid;
  grid-gap: 24px;
  align-items: center;
  grid-template-columns: 2fr 1fr;

	// Filter tags
	.cb-filter-tags {
		display: grid;
	  align-items: start;
	  grid-template-columns: 16px calc(100% - 16px);
		.cb-filter-tag {
			margin-top: 24px;
			margin-left: 24px;
		  &.cb-glyph {
		  	.cb-x-mark {
			  	font-size: 0.75rem;
			  	margin-left: 8px;
		  	}
		  }
		  &:hover,
		  &:focus {
		  	text-decoration: none;
		  }
		}
	  .cb-glyph {
	  	&.cb-tag {
	  		margin-top: 24px;
	  		position: relative;
	  		float: left;
	  		height: 24px;
	  		width: 16px;
	  		&::before {
	  			position: absolute;
		  		font-size: @font-size-base;
		  		top: 4px;
	  		}
	  	}
	  }
	}
	.btn-secondary-group {
		margin-top: 24px;
		text-align: right;
	}

	// mobile
	@media (max-width: @screen-xs-max) {
		.cb-filter-tags {
			display: block;
		}
	  .cb-filter-tags,
	  .btn-secondary-group {
	  	grid-column-start: 1;
  		grid-column-end: 3;
	  }
	  .cb-filter-tags-container {
	  	margin-left: -24px;
	  }
	  .btn-secondary-group {
	  	margin-top: 0;
	  }
	}
}

// modal
.cb-filter-modal{
	.modal-content {
		.modal-header {
			padding:  24px 24px 0 24px;
		}
		.modal-body {
			padding: 24px;
			.cb-filter-content {
				width: 100%;
				min-width: 100%;
				display: inline-block;
				padding: 0;
				&.cb-filter-container {
					border: 0;
					.cb-filter-options {
						padding: 0;
					}
					.cb-filter-selected {
						padding: 0;
						margin-top: 24px;
						.cb-filter-tags-glyph {
							display: none;
						}
					}
				}
			}
		}
	}
}