.cb-glyphs-fonts(@name) {
  font-family: @name;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  .font-smoothing();
}
.cb-glyphs(){
  .cb-glyphs-fonts('CB Icons');
}

.cb-glyphs-multi(){
  .cb-glyphs-fonts('CB Icons Multi');
}

.cb-glyphs-logo(){
  .cb-glyphs-fonts('CB Icons Logo');
}

.glyph-color (@color) {
  color: @color;
  &.cb-glyph-numeric {
    border-color: @color;
    &.cb-glyph-circular {
      color: @white-color;
      border-color: @color;
      background-color: @color;
    }
  }
}

.glyph-multi-content(@before, @after) {
	&::before {
	  content: @before;
	}
	&::after {
		content: @after;
	}
}


.glyph-multi-bk-color-imp (@color1, @color2, @bk) {
  background-color: @bk!important;

  .glyph-multi-content-color-imp(@color1, @color2);
}

.glyph-multi-content-color-imp (@color1, @color2) {
  &::before {
    color: @color1!important;
  }
  &::after {
    color: @color2!important;
  }
}

.glyph-multi-content-color (@color1, @color2) {
  &::before {
    color: @color1;
  }
  &::after {
    color: @color2;
  }
}

.glyph-multi-bk-color (@color1, @color2, @bk) {
  background-color: @bk;

  .glyph-multi-content-color(@color1, @color2);
}

.glyph-logo-content (@before) {
  &::before {
    content: @before;
  }
}
