
// Creates a wrapper for a series of columns
.make-event-row(@gutter: @grid-gutter-width) {
  width: auto;
  margin-left:  (@gutter / -2);
  margin-right: (@gutter / -2);

  // CB guidlines
  @media (min-width: @screen-sm-min) {
    margin-left:  (@cb-grid-gutter-width-sm / -2);
    margin-right: (@cb-grid-gutter-width-sm / -2);
  }
  @media (min-width: @screen-md-min) {
    margin-left:  (@cb-grid-gutter-width-md / -2);
    margin-right: (@cb-grid-gutter-width-md / -2);
  }
  @media (min-width: @screen-lg-min) {
    margin-left:  (@cb-grid-gutter-width-lg / -2);
    margin-right: (@cb-grid-gutter-width-lg / -2);
  }

  &:extend(.clearfix all);
}

.make-event-columns() {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  margin-left:  (@grid-gutter-width / 2);
  margin-right: (@grid-gutter-width / 2);
  float: left;
  width: calc(100% - (@grid-gutter-width));
  margin-top: (@grid-gutter-width / 2);
  &:nth-child(1) {
    margin-top: 0;
  }

  // CB guidlines
  @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
    margin-top: (@cb-grid-gutter-width-sm  / 2);
    margin-right: (@cb-grid-gutter-width-sm / 2);

    //tablet: 2
    width: calc(50% - (@cb-grid-gutter-width-sm));
    margin-left: 0 !important;
    &:nth-child(1),
    &:nth-child(2)  {
      margin-top: 0;
    }
    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(9){
      margin-left: (@cb-grid-gutter-width-sm / 2) !important;
    }
  }

  @media (max-width: @screen-md-max) and (min-width: @screen-md-min) {
    margin-top: (@cb-grid-gutter-width-md / 2);
    margin-right: (@cb-grid-gutter-width-md / 2);
    margin-left: 0 !important;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(7),
    &:nth-child(10),
    &:nth-child(13){
      margin-left: (@cb-grid-gutter-width-md / 2);
    }
    //desktop: 3
    width: calc(33.33333333% - ((@cb-grid-gutter-width-md / 3) * 2)) ;
  }

  @media (min-width: @screen-lg-min) {
    margin-top: (@cb-grid-gutter-width-lg / 2);
    margin-right: (@cb-grid-gutter-width-lg / 2);
    margin-left: 0 !important;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(7),
    &:nth-child(10),
    &:nth-child(13){
      margin-left: (@cb-grid-gutter-width-lg / 2) !important;
    }

    //oversize: 3
    width: calc(33.33333333% - ((@cb-grid-gutter-width-lg / 3) * 2)) ;
  }
}
