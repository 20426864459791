//
// Highlighted Article band
// Focus: custom
// --------------------------------------------------

.cb-article-band-headline,
.cb-article-card-headline {
  h2, h3 {
    float: left;
    font-size: 1.188rem;
    line-height: 0.9em;
    margin: 0;
    padding: 0;
  }
  a{
    text-transform: uppercase;
    float: left;
    margin-left: 18px;
    font-size: 0.5rem;
    line-height: 3em;
  }
  margin-bottom: 12px;
  width: 100%;
  display: inline-block;
}

.cb-article-card{
  float: left;
  width: 100%;
  background-color: @white-color;
  .cb-article-item {
    position: relative;
    width: 50%;
    .border-box();
    float: left;
    border: 1px solid @gray-corporate-border;
    border-top: 0;
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(8) {
      border-left: 0
    }
    &:nth-child(1),
    &:nth-child(2) {
      border-top: 1px solid @gray-corporate-border;
    }

    padding:  18px;
    height: 156px;
    > a {
      position: absolute;
      width: calc(100% - 36px);
      height: calc(100% - 36px);
      display: block;
      top: 18px;
      left: 18px;
      color: @gray1-corporate-color;
      &::after {
        .cb-glyphs();
        content: "\e916";
        position: absolute;
        display:  block;
        bottom: 0;
        right: 0;
        color: @cb-base-font-color;
        // font-size: 1em;
        width: 2em;
        height: 2em;
        line-height: 2em;
        text-align: center;
        border-radius: 50%;
        background-color: @white-color;
      }
      &.cb-target-blank {
        &::after {
          content: "\e932";
        }
      }
      &:hover,
      &:focus {
        cursor: pointer;
        outline: 0;
        h2, h3, h4, p {
          text-decoration: underline;
        }
        &::after {
          color: @white-color;
          background-color: @blue-accent2-corporate-color;
        }
      }
    }
    .cb-article-image {
      position: relative;
      min-height: 18px;
      span {
        position: absolute;
        top: 0 ;
        left: 0;
        display: block;
        z-index: 1;
        height: 18px;
        &.cb-programs-sat {
          width: 29px;
          background-color: @k-12-color;
        }
        &.cb-programs-psat-nmsqt {
          width: 74px;
          background-color: @k-12-color;
        }
        &.cb-programs-psat-10{
          width: 47px;
          background-color: @k-12-color;
        }
        &.cb-programs-psat-8-9 {
          width: 51px;
          background-color: @k-12-color;
        }
        &.cb-programs-springboard {
          width: 67px;
          background-color: @k-12-color;
        }
        &.cb-programs-ap {
          width: 22px;
          background-color: @k-12-color;
        }
        &.cb-programs-access {
          width: 41px;
          background-color: @access-color;
        }
        &.cb-programs-accuplacer {
          width: 74px;
          background-color: @higher-education-color;
        }
        &.cb-programs-clep {
          width: 34px;
          background-color: @higher-education-color;
        }
        &.cb-programs-css-profile {
          width: 61px;
          background-color: @higher-education-color;
        }
        &.cb-programs-powerfaids {
          width: 66px;
          background-color: @higher-education-color;
        }
      }
      img {
        z-index: 2;
        height: 132px;
      }
    }

    .cb-article-content {
      h2, h3, h4 {
        font-size: @font-size-large;
        line-height: @line-height-large-base;
        margin: 12px 0;
      }
      p {
        margin: 0;
        font-size: @font-size-small;
        line-height: @line-height-small;
      }
    }
    .cb-article-tag {
      text-transform: uppercase;
      color: @cb-blue-link-color;
      font-size: 0.75rem;
      line-height: 2em;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }
    &.focus {
      .cb-focus();
      outline-offset: -1px;
    }

    @media (max-width: @screen-xs-max) { // mobile
      width: 100%;
      height: auto !important;
      .cb-article-image {
        img {
          height: auto;
          width: 100%;
        }
      }
      > a {
        position: relative;
        top: 0;
        left: 0;
        height:100%;
        width: 100%;
        padding-bottom: 36px;
      }
      border: 1px solid @gray-corporate-border!important;
      border-bottom: 0!important;
      &:nth-last-child(1) {
        border-bottom: 1px solid @gray-corporate-border!important;
      }
    }

    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { //tablet
      height: 168px;
      .cb-article-image {
        img {
          height: 154px;
        }
      }
    }

    @media (min-width: @screen-lg-min) {//oversized
      width: 25%;
      height: 150px;
      .cb-article-image {
        img {
          height: 120px;
        }
      }

      border: 1px solid @gray-corporate-border;
      border-left: 0;
      &:nth-child(1),
      &:nth-child(5) {
        border-left: 1px solid @gray-corporate-border;
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        border-top: 0;
      }
    }
  }

  &.cb-tall-article {
    .cb-article-item {
      height: 312px;
    }
  }

  @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { //tablet
    &.cb-tall-article {
      .cb-article-item{
        height: 336px;
      }
    }
  }
  @media (min-width: @screen-lg-min) {//oversized
    &.cb-tall-article {
      .cb-article-item{
        height: 300px;
      }
    }
  }
}

.cb-article-band {
  float: left;
  width: 100%;
  border: 1px solid @gray-corporate-border;
  background-color: @white-color;
  .cb-article-column {
    width: 50%;
    border-right: 1px solid @gray-corporate-border;
    border-bottom: 1px solid @gray-corporate-border;
    .border-box();
    float: left;
    &:nth-child(2),
    &:nth-child(4) {
      border-right: 0;
    }
    &:nth-child(3),
    &:nth-child(4) {
      border-bottom: 0;
    }
    .cb-article-item {
      position: relative;
      border: 0;
      padding:  18px;
      float: left;
      width: 100%;
      height: 156px;
      .border-box();
      > a {
        position: absolute;
        width: calc(100% - 36px);
        height: calc(100% - 36px);
        display: block;
        top: 18px;
        left: 18px;
        color: @gray1-corporate-color;
        &::after {
          .cb-glyphs();
          content: "\e916";
          position: absolute;
          display:  block;
          bottom: 0;
          right: 0;
          color: @cb-base-font-color;
          // font-size: 1em;
          width: 2em;
          height: 2em;
          line-height: 2em;
          text-align: center;
          border-radius: 50%;
          background-color: @white-color;
        }
        &.cb-target-blank {
          &::after {
            content: "\e932";
          }
        }
        &:hover,
        &:focus {
          cursor: pointer;
          outline: 0;
          h2, h3, h4, p {
            text-decoration: underline;
          }
          &::after {
            color: @white-color;
            background-color: @blue-accent2-corporate-color;
          }
        }
      }
      .cb-article-image {
        position: relative;
        min-height: 18px;
        span {
          position: absolute;
          top: 0 ;
          left: 0;
          display: block;
          z-index: 1;
          height: 18px;
          &.cb-programs-sat {
            width: 29px;
            background-color: @k-12-color;
          }
          &.cb-programs-psat-nmsqt {
            width: 74px;
            background-color: @k-12-color;
          }
          &.cb-programs-psat-10{
            width: 47px;
            background-color: @k-12-color;
          }
          &.cb-programs-psat-8-9 {
            width: 51px;
            background-color: @k-12-color;
          }
          &.cb-programs-springboard {
            width: 67px;
            background-color: @k-12-color;
          }
          &.cb-programs-ap {
            width: 22px;
            background-color: @k-12-color;
          }
          &.cb-programs-access {
            width: 41px;
            background-color: @access-color;
          }
          &.cb-programs-accuplacer {
            width: 74px;
            background-color: @higher-education-color;
          }
          &.cb-programs-clep {
            width: 34px;
            background-color: @higher-education-color;
          }
          &.cb-programs-css-profile {
            width: 61px;
            background-color: @higher-education-color;
          }
          &.cb-programs-powerfaids {
            width: 66px;
            background-color: @higher-education-color;
          }
        }
        img {
          z-index: 2;
          height: 132px;
        }
      }
      .cb-filler-image {
        img{
          height: 132px;
        }
      }
      .cb-article-content {
        h2, h3, h4 {
          font-size: @font-size-large;
          line-height: @line-height-large-base;
          margin: 12px 0;
        }
        p {
          margin: 0;
          font-size: @font-size-small;
          line-height: @line-height-small;
        }
      }
      .cb-article-tag {
        text-transform: uppercase;
        color: @cb-blue-link-color;
        font-size: 0.75rem;
        line-height: 2em;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
      }
      &.focus{
        .cb-focus();
      }

      // Fillers
      .cb-filler-white,
      .cb-filler-program{
        h3, h4 {
          font-size: @font-size-large;
          line-height: @line-height-large-base;
          margin: 0;
        }
      }
      .cb-filler-program{
        h3, h4 {
          color: @white-color;
        }
      }
    }

    &.cb-tall-article {
      .cb-article-item {
        height: 312px;
      }
    }

    &:not(.cb-tall-article ){
      .cb-article-item {
        &:first-child{
          border-bottom: 1px solid @gray-corporate-border;
        }
      }
    }

    @media (max-width: @screen-xs-max) { // mobile
      width: 100%;
      border: 0;
      .cb-article-item {
        height: auto !important;
        border-bottom: 1px solid @gray-corporate-border;
        .cb-article-image {
          img {
            height: auto;
            width: 100%;
          }
        }
        .cb-filler-image {
          img{
            height: auto;
            width: 100%;
          }
        }
        > a {
          position: relative;
          top: 0;
          left: 0;
          height:100%;
          width: 100%;
          padding-bottom: 36px;
        }
      }
      &:nth-child(4) {
        .cb-article-item {
          &:last-child{
            border-bottom:0;
          }
        }
      }
    }
    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { //tablet
      .cb-article-item {
        height: 168px;
        .cb-article-image {
          img {
            height: 154px;
          }
        }
        .cb-filler-image {
          img{
            height: 154px;
          }
        }
      }
      &.cb-tall-article {
        .cb-article-item{
          height: 336px;
        }
      }
    }
    @media (min-width: @screen-lg-min) {//oversized
      width: 25%;
      border-bottom: 0;
      .cb-article-item {
        height: 150px;
        .cb-article-image {
          img {
            height: 120px;
          }
        }
        .cb-filler-image {
          img{
            height: 120px;
          }
        }
      }
      &.cb-tall-article {
        .cb-article-item{
          height: 300px;
        }
      }

      &:nth-child(2){
        border-right: 1px solid @gray-corporate-border;
      }
    }
  }
}

// no focus on mouse and touch
[data-cb-input-m="mouse"] {
  .cb-article-band,
  .cb-article-card {
    .cb-article-column {
      .cb-article-item {
        &.focus{
          .cb-no-focus();
        }
      }
    }
  }
}