//
// Tabs
// Focus: custom
// --------------------------------------------------

.nav-tabs{
	margin-bottom: 1.5em;
	.dropdown-toggle{
		.glyphicon-cb,
		.cb-glyph{
			font-size: 0.65rem;
		}
	}
	.dropdown-menu {
		.divider{
			margin: 0;
		}
	}
	& > li {
		& > a {
		  padding: 11px 15px;
  		.border-box();
			&:focus{
				.cb-focus();
			}
		}
	}

	&.nav{
		& > li {
			&.disabled {
				& > a {
					color: @cb-form-input-disabled-font-color;
				}
			}
		}
	}
}

// no focus on mouse and touch
[data-cb-input-m="mouse"] {
	.nav-tabs {
		& > li {
			& > a {
				&:focus{
					.cb-no-focus();
				}
			}
		}
		&.nav {
			.open {
				& > a {
					&:focus,
					&:hover {
						border-color: transparent;
					}
				}
			}
		}
	}
}
