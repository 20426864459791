//
// CB Architectural Views
// --------------------------------------------------

.cb-arch-view,
.cb-nav-card{
  position: relative;
  h2, .h2,
  h3, .h3{
    .roboto-slab-bold();
    color: @cb-blue-link-color;
    white-space: nowrap;
    font-size: 1.125rem;
    line-height: 1.333em;
    margin: 0;
  }
  p{
    margin-bottom: 24px;
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 1px;
    left: 1px;
  }
  &:hover,
  &:focus {
    cursor: pointer;
    h2, .h2,
    h3, .h3,
    p {
      text-decoration: underline;
    }
  }
}

.cb-arch-view-list,
.cb-nav-card-list{
  ul {
    padding: 12px 0 0 18px;
    margin: -13px 0 24px 0;
    border-top: 1px solid @gray-corporate-border;
    li {
      list-style: disc;
      a {
        font-size: @font-size-small;
        line-height: @line-height-small-base;
      }
    }
  }
  &.cb-arch-view-no-des {
    ul {
      margin-top: 11px;
    }
  }
}

.cb-arch-view-grid {
  display: flex;
  flex-wrap: wrap;
}