//
// $.fn.cbSelectElement
// --------------------------------------------------

.cb-select-container {
  position: relative;
  width: auto;
  select{
    &.replaced {
      line-height: 2em;
      position: relative;
      z-index: 10;
      width: 100%;
      height: @cb-form-control-height;
      opacity: 0;
      border: 0 none;
    }
  }
  span {
    &.cb-select {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: @cb-form-control-height;
      > span {
        font-size: @font-size-base;
        line-height: 3em;
        display: block;
        padding: 0 9px;
        color: @cb-base-font-color;
        border: 1px solid @cb-form-input-border-color;
        .border-box();
        border-radius: @cb-form-control-radius;
        background-color: @cb-form-control-bk-color;
      }
      span{
        span {
          display: block;
          height: 46px;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
        }
      }
      .cb-select-arrow {
        position: absolute;
        display: block;
        right: -10px;
        top: 0;
        height: 46px;
        width: 38px;
        border-left: 1px solid @cb-form-input-border-color;
        .cb-glyphs();
        font-size: 0.6875rem;
        font-weight: 700;
        &::before{
          position: absolute;
          display: block;
          content: "\e913";
          top: 17px;
          left: 13px;
        }
      }
      &.focus{
        > span{
          .focus-border-shadow(@input-border-focus);
          border-width: 1px;
        }
      }
      &.mouseover{
        > span{
          border-color: @cb-form-input-border-hover-color;
        }
        .cb-select-arrow {
          border-left: 1px solid @cb-form-input-border-hover-color;
        }
      }
      // Disabled
      &.disabled{
        > span {
          cursor: default;
          background-color: @input-bg-disabled;
          color: @cb-form-input-disabled-font-color;
          opacity: 1; // iOS fix for unreadable disabled content
        }
      }
    }
  }
}

.has-success {
  .cb-select-container {
    span {
      &.cb-select {
        > span {
          border-color: @state-success-border;
        }
        &.focus {
          > span {
            border-color: darken(@state-success-border, 10%)
          }
        }
      }
    }
  }
}

.has-error {
  .cb-select-container {
    span {
      &.cb-select {
        > span {
          border-color: @cb-red-color;
        }
        &.focus {
          > span{
            border-color: darken(@cb-red-color, 10%)
          }
        }
      }
    }
  }
}