//
// $.fn.cbCustomScrollbar
// --------------------------------------------------

.cb-scrollbar-container {
  @media screen and (max-width: @screen-xs-max) {
    &.active{
      position: relative;
      overflow: hidden;
      border: 1px solid @table-border-color;
      .table-responsive{
        position: relative;
        overflow: hidden;
      }
      .table{
        position: absolute;
        border-bottom: 0px !important;
        > thead,
        > tbody,
        > tfoot {
          > tr {
            > th,
            > td {
              &:first-child {
                border-left: 0;
              }
              &:last-child {
                border-right: 0;
              }
            }
          }
        }
        > caption {
          border-left: 0;
          border-right: 0;
        }
      }
      .cb-scrollbar {
        position: relative;
        width: 100%;
        height: 14px;
        padding: 2px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);

        display: none;
        .cb-scrollbar-thumb{
          position: absolute;
          height: 10px;
          min-width: 30px;
          left: 0;

          background-color: @cb-gray4-color;
          opacity: 0.4;
          border-radius: 3px;
          -moz-border-radius: 4px;
          -webkit-border-radius: 4px;
        }
        &:hover {
          .cb-scrollbar-thumb{
            opacity: 0.7;
          }
        }
      }
    }
  }
  .table-responsive {
    border: 0;
    margin-bottom: 0;
    overflow-y: hidden;
    .table {
      margin-bottom: 0;
    }
  }
  margin-bottom: 24px;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}