//
// Alerts
// --------------------------------------------------

.alert{
	border-radius: 0;
	padding: 20px 0 24px 0;
	margin-bottom: 24px;
	width: 100%;
	display: inline-block;
	.btn-minimal,
	.btn-secondary{
		float: right;
		margin-top: 24px;
	}
	&.alert-success {
	  .cb-alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text; @alert-success-header; @alert-success-glyph);
	}

	&.alert-warning {
	  .cb-alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text; @alert-warning-header; @alert-warning-glyph);
	}
	&.alert-danger {
	  .cb-alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text; @alert-danger-header; @alert-danger-glyph);
	  .alert-link{
	  	text-decoration: underline;
	  	color: @alert-danger-text;
	  	&:hover {
	  		text-decoration: underline;
	  		color: @alert-danger-text;
	  	}
	  }
	}
	&.alert-dismissible {
		padding: 20px 15px 23px 15px;
	}
	.close {
	  .opacity(1);

	  &:hover,
	  &:focus {
	    .opacity(1);
	  }
	}

	ul{
		&.cb-list-style {
			padding-left: 64px;
		}
	}
	&> p + p {
    margin-top: 16px;
	}
}

