//
// Sticky Bar
// --------------------------------------------------
.cb-sticky-bar {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  z-index: 904;
  height: auto;
  transition: top .25s ease-in-out;
  background: @white-color;
  box-shadow: 0px 0px 24px rgba(0,0,0,.125);
  &.cb-sticky-bar-active {
    top: 0!important;
  }
  .cb-sticky-bar-container {
    display: flex;
    align-items: center;
    margin: 24px 0;
    .cb-sticky-container {
      width: calc(70%);
      .cb-sticky-preamble {
        margin: 0;
      }
      .cb-sticky-title {
        .roboto-slab-bold();
        font-size: 1.1875em;
        line-height: 1.263157894736842em;
        margin: 0;
      }
      .cb-sticky-content {
        margin-top: 12px;
        .cb-onpage-menu {
          margin-bottom: -24px;
        }
      }
    }
    .cb-sticky-cta {
      width: calc(30% - 24px);
      margin-left: 24px;
      display: flex;
      justify-content: flex-end;
      .btn {
        font-size: @font-size-small;
        line-height: @line-height-small-base;
        background: @yellow-corporate-color;
        color: @black-corporate-color;
        border: 1px solid @yellow-corporate-color;
        padding: 11px 18px;
        .cb-focus-button();
        &:focus,
        &:hover{
          text-decoration: underline;
        }
      }
    }

    // mobile, tablet
    @media (max-width: @screen-sm-max) {
      .cb-sticky-container {
        // width: auto;
        min-width: 50%;
        // max-width: 70%;
      }
      .cb-sticky-cta {
        width: auto;
        min-width: calc(30% - 24px);
        max-width: calc(50% - 24px);
      }
    }
  }
}
