//
// CB Hero
// --------------------------------------------------

.cb-hero{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
	color: @white-color;
	background-color: @gray3-corporate-color;

  // desktop is the default size
  // ------- title
  .content {
		h1, .h1,
		h2, .h2,
		h3, .h3,
		h4, .h4{
	    .roboto-slab();
	  	font-size: 2.25rem;
			color: @white-color;
	  	line-height: 1.1666em;
			margin: -12px 0 12px;
			&:first-child {
				display: inline-block;
		    border-top-style: solid;
		    border-top-width: .2em;
		    border-top-color: @white-color;
		    padding-top: .2em;
			}
	  }
  }

  // ------- buttons
	.btn {
  	font-size: @font-size-small;
  	line-height: @line-height-small-base;
  	background: @yellow-corporate-color;
  	color: @black-corporate-color;
  	border: 1px solid @yellow-corporate-color;
    padding: 11px 18px;
    .cb-focus-button();
    &:focus,
    &:hover{
  		text-decoration: underline;
  	}
	}

  > .container {
	    height: 100%;
	  	> .row {
	    	height: 100%;
	    > .flex-block{
		    height: 100%;
		    display: flex;
		    align-items: center;
		    .content {
			    width: 100%;
			    margin: 30px 0;
			    p {
				    margin-bottom: 18px;
				    .roboto-bold();
					}
				}
	    }
		}
	}

	// -------CTA block
	.cb-hero-cta {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
	    display: inline-block;
	    margin-bottom: 12px;
  		.border-box();
			&:nth-child(2) {
    		margin-left: 12px;
				.btn{
			  	background-color: transparent;
					color: @white-color;
					border: 1px solid @white-color;
			  }
			  .cb-primary-cta{
			  	background: @yellow-corporate-color;
					border: 1px solid @yellow-corporate-color;
  				color: @black-corporate-color;
			  }
			}
		}
	}

	// Hero with light background color
	&.cb-hero-light {
		background-color: @white-color;
    h1, .h1,
    h2, .h2,
    h3, .h3,
		h4, .h4,
    p  {
			color: @cb-base-font-color;
		}
		.content {
			h1, .h1,
			h2, .h2,
			h3, .h3,
			h4, .h4{
				&:first-child {
			    border-top-color: @cb-base-font-color;
				}
			}
		}
		.cb-hero-cta {
	    li {
				&:nth-child(2) {
					.btn{
						&:not(.cb-primary-cta) {
							color: @black-corporate-color;
							border: 1px solid @black-corporate-color;
						}
					}
				}
			}
		}
	}


	// ------- Hero alignment
	&.cb-hero-left,
	&.cb-hero-right{
		text-align: left;
	}

	&.cb-hero-center{
		text-align: center;
	}
	// ------- Hero Type
	&.cb-hero-standard{
		padding-top: 0;
		&.cb-hero-border-bottom {
			border-bottom: 1px solid @white-color;
		}
		@media (max-width: @screen-xs-max) { // mobile
	    .cb-focus-left,
	    .cb-focus-right {
	        text-align: center!important;
	    }
			&.cb-hero-border-bottom {
				border-bottom: 0 !important;
			}
	  }
		.cb-hero-date,
		.cb-hero-preamble {
   		font-size: 1.1875rem;
			line-height: 1.6666;
			letter-spacing: .05em;
			margin: 0 0 12px;
		}
	}
	&.cb-hero-no-bar {
		.content {
			h1, .h1,
			h2, .h2,
			h3, .h3,
			h4, .h4{
				&:first-child {
					padding-top: 0 !important;
					border: 0 !important;
				}
			}
		}
	}

	// ------ story
	&.cb-hero-fullpage {
  	max-height: 800px;
  	&.cb-hero-right {
	  	.cb-hero-cta {
		    li {
			    &:last-child {
					  margin-right: 12px;
			    }
			    &:first-child {
					  margin-right:0;
					  float: right;
					}
				}
			}
  	}
	}

	// ------- Title Cart
	&.cb-hero-site-cart {
		&.cb-hero-border-bottom {
			border-bottom: 1px solid @white-color;
		}

		// ------- title
	  .content {
			h1, .h1,
			h2, .h2,
			h3, .h3,
			h4, .h4{
		    .roboto-slab();
		  	font-size: 2.625rem;
		  	line-height: 1.1428571428571428em;
				color: @white-color;
				&:first-child {
			 		border-top: 0;
			 		display: block;
			 		padding-top: 0;
				}
				margin: 0 0 12px 0;
		  }
	  }

	  // ------- buttons
		.cb-hero-cta {
	    margin: 0;
	    padding: 0;
	    list-style-type: none;
	    li {
		    display: inline-block;
		    margin-bottom: 0;
				margin-left: 0!important;
		    .btn{
			  	background-color: transparent;
					color: @white-color;
					border: 1px solid @white-color;
					margin: 0 24px 24px 0;
			  }
			  .cb-primary-cta{
			  	background: @yellow-corporate-color;
					border: 1px solid @yellow-corporate-color;
					color: @black-corporate-color;
			  }
			}
		}

	  > .container {
	  	> .row {
		    > .flex-block{
			    height: 100%;
			    .content {
				    width: 100%;
				    margin: 48px 0 24px 0;
				    p {
				    	font-size: 1.1875rem;
				    	line-height: 1.263157894736842em;
					    margin-bottom: 36px;
					    .roboto();
						}
					}
		    }
			}
		}

		&.cb-hero-no-btn {
			> .container {
		  	> .row {
			    > .flex-block{
				    .content {
					    margin-bottom: 48px;
					  }
					}
				}
			}
		}
		.cb-hero-preamble {
			margin-bottom: 0 !important;
		}
	}

	// -------tablet, mobile
	@media (max-width: @screen-sm-max) {
    h1, .h1,
    h2, .h2,
    h3, .h3,
  	h4, .h4 {
      font-size: 1.625rem;
      line-height: 1.1538em;
    }
  	.btn {
	    padding: 11px 18px;
		}
    &.cb-hero-standard {
      height: 240px;
      &.cb-hero-border-bottom {
      	height: 241px;
      }
    }
	}

	// mobile
	@media (max-width: @screen-xs-max) {
		&:not(.cb-hero-site-cart) {
			background-color: @white-color !important;
		}
		.content {
			h1, .h1,
			h2, .h2,
			h3, .h3,
			h4, .h4{
				&:first-child {
			    border-top-color: @cb-base-font-color;
			    display: block;
				}
			}
		}
    &.cb-hero-standard {
	    &.cb-hero-left,
	    &.cb-hero-right{
	      height: auto;
	    	padding-top: 240px !important;
	    	background-position: top center;
	    	background-size: auto 240px;
	    	-webkit-background-size: auto 240px;
				text-align: center;
		    h1, .h1,
		    h2, .h2,
		    h3, .h3,
  			h4, .h4,
		    p {
					color: @cb-base-font-color;
				}

				.cb-hero-cta {
			    li {
						&:nth-child(2) {
							.btn{
								&:not(.cb-primary-cta) {
							  	background-color: transparent;
				  				color: @black-corporate-color;
									border: 1px solid @black-corporate-color;
								}
						  }
						}
					}
				}
	    }
	    &.cb-hero-center:not(.cb-hero-light){
				.content {
					h1, .h1,
					h2, .h2,
					h3, .h3,
					h4, .h4{
						&:first-child {
					    border-top-color: @white-color;
						}
					}
				}
	    }
	    &.cb-hero-border-bottom{
	  		border-bottom: 1px solid @cb-gray7-color;
	  	}
    }
		&.cb-hero-fullpage {
			&.cb-hero-center,
			&.cb-hero-left,
	    &.cb-hero-right {
	      height: auto;
	    	padding-top: 240px !important;
	    	background-position: top center;
	    	background-size: auto 240px;
	    	-webkit-background-size: auto 240px;
		    h1, .h1,
		    h2, .h2,
		    h3, .h3,
  			h4, .h4,
		    p {
					color: @cb-base-font-color;
				}
				.cb-hero-cta {
			    li {
						&:nth-child(2) {
							.btn{
						  	background-color: transparent;
			  				color: @black-corporate-color;
								border: 1px solid @black-corporate-color;
						  }
						}
					}
				}
	    }

	  	&.cb-hero-right {
		  	.cb-hero-cta {
			    li {
				    &:last-child {
						  margin-right: 0;
				    }
				    &:first-child {
						  margin-right:12px;
						  float: none;
						}
					}
				}
	  	}
      .flex-block{
        margin-top: 24px;
      }
  	}
  	&.cb-hero-site-cart {
			.content {
		    h1, .h1,
		    h2, .h2,
		    h3, .h3,
  			h4, .h4 {
  				font-size: 2.25rem;
  				line-height: 1.3333333333333333em;
  			}
  		}
  	}
	}

	// desktop
	@media (min-width: @screen-desktop) {
	  &.cb-hero-standard {
      height: 312px;
      &.cb-hero-border-bottom {
      	height: 313px;
      }
	  }
    &.cb-hero-fullpage {
	    min-height: 600px;
    	&.cb-hero-left,
    	&.cb-hero-right{
    		background-size: 2056px auto;
    	}
	  }
	  &.cb-hero-site-cart {
    	.flex-block {
      	min-height: 384px;
    	}
      &.cb-hero-border-bottom {
	    	.flex-block {
	      	min-height: 383px;
    		}
      }
    }
	}

	// oversized
	@media (min-width: @screen-lg-min) {
    h1, .h1,
    h2, .h2,
    h3, .h3,
  	h4,	.h4 {
      font-size: 3rem;
      line-height: 1.125em;
    }
    &.cb-hero-standard {
      height: 384px;
      &.cb-hero-border-bottom {
      	height: 385px;
      }
    }
    &.cb-hero-fullpage {
    	&.cb-hero-left,
    	&.cb-hero-right{
    		background-size: auto auto;
    	}
    }
    &.cb-hero-site-cart {
    	.flex-block {
      	min-height: 384px;
    	}
      &.cb-hero-border-bottom {
	    	.flex-block {
	      	min-height: 383px;
    		}
      }
    }
	}

	//tablet
	@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
		&.cb-hero-fullpage {
    	&.cb-hero-left,
    	&.cb-hero-right{
    		background-size: 1592px auto;
    	}
		}
	}

	// -------tablet, mobile
	@media (max-width: @screen-sm-max) {
	  > .container {
	  	> .row {
	  		> .flex-block{
			    .content {
				    p {
					    margin-bottom: 12px;
					  }
					}
				}
	  	}
		}
		.content  {
	    h1, .h1,
	    h2, .h2,
	    h3, .h3,
	  	h4, .h4 {
	      font-size: 1.5rem;
			  line-height: 1.1666;
			  margin: 0 0 12px;
	    }
		}
    &.cb-hero-standard{
			.cb-hero-date,
			.cb-hero-preamble {
	   		font-size: @font-size-base;
			  line-height: @line-height-base;
			  margin: 0 0 12px;
			}
		}
		.cb-hero-cta {
			li {
			  margin-bottom: 0;
			}
		}
	}
}
// ------------------------------------------  IE 11
.ie11 {

	// desktop
	@media (min-width: @screen-desktop) {
	  .cb-hero-site-cart {
    	.flex-block {
      	min-height: 384px !important;
    	}
      &.cb-hero-border-bottom {
	    	.flex-block {
	      	min-height: 383px !important;
    		}
      }
    }
	}

	// oversized
	@media (min-width: @screen-lg-min) {
    .cb-hero-site-cart {
    	.flex-block {
      	height: 384px !important;
    	}
      &.cb-hero-border-bottom {
	    	.flex-block {
	      	height: 383px !important;
    		}
      }
    }
	}
}