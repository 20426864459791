//
// Forms
// --------------------------------------------------

.radio-inline,
.checkbox-inline {
  display: inline-block;
 }

.form-control {
  border-radius: @cb-form-control-radius;
  background-color: @cb-form-control-bk-color;
  border-color: @cb-form-input-border-color;
  height: @cb-form-control-height;
  font-size: @font-size-base;
  line-height: 3em;
	padding-top: @cb-form-control-vertical;
	padding-bottom: @cb-form-control-vertical;
	.border-box();
  .box-shadow(none);
	&:hover{
		.hover-effect();
  }
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
  	&:hover{
    	border-color: @cb-form-input-border-color;
  	}
  }
  &.cb-placeholder{
  	color: @input-color-placeholder;
  }
  &:focus{
  	.box-shadow(none);
    border-color: @input-border-focus;
  	border-width:  1px;
  }
}
textarea {
	&.form-control {
  	line-height: 1.5em;
  	padding: 23px 12px;
	}
}


input{
  &::-ms-clear{
    width: 0;
    height: 0;
    display: none;
  }
}

.radio,
.radio-inline{
	input[type="radio"]{
    position: relative;
    opacity: 0;
    margin-left: -17px;
		+ span {
	    position: relative;
	    margin-right: 10px;
	    &::before {
	     .cb-glyphs();
        content: "\e90c";
        position: absolute;
        display:  block;
        top: 2px;
        right: 0;
        color: @gray1-corporate-color;
        font-size: 1em;
			}
		}
		&:checked {
			+ span::before {
        content: "\e958";
			}
		}
		&:focus {
			+ span {
				&::before{
					color: @input-border-focus;
				}
				&::after{
	        content: " ";
	        position: absolute;
	        display:  block;
	        top: -2px;
	        right: -4px;
	        height: 1.5em;
	        width: 1.5em;
	        padding: 2px;
	        border: 1px solid @input-border-focus;
				}
			}
		}

		&:hover {
			+ span::before{
				color: @input-border-focus;
			}
		}
		&.no-cb{
	    opacity: 1;
	    left: 0;
		}
	}
}

// none CB radio buttons
.radio,
.radio-inline{
	input[type="radio"]:not(.cb-radio){
    opacity: 1;
    left: 0;
	}
}

.checkbox,
.checkbox-inline {
	input[type=checkbox] {
	  position: relative;
	  margin-left: -17px;
	  opacity: 0;
	  +span {
	    position: relative;
	    margin-right: 10px;
	    &::before {
	     .cb-glyphs();
        content: "\e95a";
        position: absolute;
        display:  block;
        top: 2px;
        right: 0;
        color: @gray1-corporate-color;
        font-size: 1em;
			}
		}
		&:checked{
			+ span::before {
		    content: "\e95c";
			}
		}
		&:focus {
			+ span{
				&::before{
					color: @input-border-focus;
				}
				&::after{
	        content: " ";
	        position: absolute;
	        display:  block;
	        top: -2px;
	        right: -4px;
	        height: 1.5em;
	        width: 1.5em;
	        padding: 2px;
	        border: 1px solid @input-border-focus;
				}
			}
		}
		&.no-cb{
	    opacity: 1;
	    left: 0;
		}
	}
}
// none CB checkbox
.checkbox,
.checkbox-inline {
	input[type=checkbox]:not(.cb-checkbox){
		opacity: 1.0;
	  left: 0;
	}
}

.checkbox,
.radio {
	margin-top: 0;
	margin-bottom: 0;
	line-height: 24px;
}
.checkbox+.checkbox,
.radio+.radio {
	margin-top: 0;
}

input[type="radio"],
input[type="checkbox"] {
  &[disabled],
  &.disabled,
  fieldset[disabled] & {
  	span{
  		&::before {
	  		background-color: @input-bg-disabled;
	  		border-color: @cb-gray-light-border-color;
	  		cursor: default;
	  	}
  	}
  	&:hover {
			+ span::before{
  			border-color: @cb-gray-light-border-color;
			}
		}
  	label{
  		color: @cb-form-input-disabled-font-color;
  	}
  }
}
.radio-inline,
.checkbox-inline {
  &.disabled,
  fieldset[disabled] & {
  	span{
  		&::before {
	  		background-color: @input-bg-disabled;
	  		border-color: @cb-gray-light-border-color;
	  		cursor: default;
	  	}
  	}
  	&:hover {
			+ span::before{
  			border-color: @cb-gray-light-border-color;
			}
		}
  	label{
  		color: @cb-form-input-disabled-font-color;
  	}
  }
}

.radio,
.checkbox {
  &.disabled,
  fieldset[disabled] & {
    label {
    	color: @cb-form-input-disabled-font-color;

    }
    span{
  		&::before {
  			color: @cb-gray-light-border-color !important;
  			cursor: default;
  		}
  	}
  	&:hover {
			+ span::before{
  			border-color: @cb-gray-light-border-color;
			}
		}
  }
}

.form-control-static{
	margin-top: 0;
}

.form-horizontal{
	.radio,
	.checkbox {
		min-height: @line-height-computed;
	}
}

.cb-file-upload{
	input[type="file"]{
	  display: none;
	  /* Note: display:none on the input won't trigger the click event in WebKit.
	     Setting visibility to hidden and width 0 works.*/
	  visibility: hidden;
	  width: 0;
	  height: 0;
	}
	.btn {
		margin-top: 0;
		margin-bottom: 24px;
	}
}

.cb-file-element{
  white-space: nowrap;
  line-height: 24px;
  display: block;
	.cb-file-element-rm {
	  padding-left: 16px;
	  font-size: 0.75rem;
	  cursor: pointer;
	  text-decoration: none;
	}
}

.cb-file-name {

	word-break: break-word;
}

.has-success {
  .form-control-validation(@state-success-text; @state-success-border; @state-success-bg; @cb-success-glyph);
  .form-control-validation-checkbox-radio (@state-success-border);
}

.has-error {
  .form-control-validation(@state-danger-text; @cb-red-color; @state-danger-bg; @cb-error-glyph);
  .form-control-validation-checkbox-radio (@cb-red-color);

  .cb-file-element {
  	color: @cb-red-color;
  }
}

.has-warning{
  .form-control-validation(@state-warning-text; @state-warning-border; @state-warning-bg; @cb-warning-glyph);
  .form-control-validation-checkbox-radio (@state-warning-border);

  .cb-file-element {
  	color: @state-warning-border;
  }
}

.cb-error-msg{
	color:  @cb-red-color;
	margin: 0;
}

.cb-warning-msg{
	color:  @state-warning-text;
	margin: 0;
	padding: 0;
}

.cb-required{
	&::after{
		content: "*";
		position: absolute;
		color: @cb-red-color;
		font-size: @font-size-base;
		margin-left: 3px;
	}
}

.cb-notice-callout {
	&.cb-notice-error {
		border-left: 4px solid @cb-red-color;
    h3{
    	.helvetica-bold();
    	color: @cb-red-color;
    }
    .cb-callout-content{
    	position: relative;
    	padding-left: 90px;
    	&::before{
	    	.cb-glyphs();
	    	color: @cb-red-color;
	    	content: "\e918";
	    	font-size: 3.125rem;
	    	left: 20px;
	    	position: absolute;
	    }
	  }
	}
}

.cb-input-search{
  position: relative;
  i.cb-search {
    position: absolute;
    color: @input-color-placeholder;
    top: 16px;
    left: 12px;
  }
  input{
    padding-left: 36px;
    &:focus {
      &+ i.cb-search {
        color: darken(@input-color-placeholder, 10%);
      }
    }
  }
}
.cb-input-edit{
  position: relative;
  i.cb-compose {
    position: absolute;
    color: @input-color-placeholder;
    top: 16px;
    left: 12px;
  }
  input{
    padding-left: 36px;
    &:focus {
      &+ i.cb-compose {
        color: darken(@input-color-placeholder, 10%);
      }
    }
  }
}

form {
	label {
		font-weight: 400;
	}
	.btn {
	  margin-top: 24px;
	}
	.form-group + .btn {
		margin-top: 0;
	}
}

.form-control {
	&[disabled],
	&[readonly],
	fieldset[disabled] & {
	  cursor: default;
	}
}

input[type="radio"],
input[type="checkbox"] {
  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    cursor: default;
  }
}
// These classes are used directly on <label>s
.radio-inline,
.checkbox-inline {
  &.disabled,
  fieldset[disabled] & {
    cursor: default;
  }
}
// These classes are used on elements with <label> descendants
.radio,
.checkbox {
  &.disabled,
  fieldset[disabled] & {
    label {
      cursor: default;
    }
  }
}

select[multiple] {
  padding: 23px 0;
  option{
	  font-size: @font-size-small;
	  line-height: @line-height-small-base;
	  padding: 4px 20px;
	  border-left: 3px solid @digital-field-gray;
	  white-space: nowrap;
	  font-weight: 400;
    color: @cb-base-font-color;
		&:hover{
		  background-color: transparent;
		  color: @cb-blue-dark-color;
		  border-left: 3px solid @cb-blue-dark-color;
		  outline: 0;
		}
		&:active{
		  background-color: @cb-gray7-color;
		  border-left: 3px solid @cb-gray7-color;
		}
		&:checked{
		  padding-left: 23px;
		  border-left: 3px;
		}
	}
}

legend {
  margin-bottom: 23px;
  line-height: 2em;
}

fieldset{
	fieldset{
	  legend {
		  font-family: @font-family-base;
		  font-size: @font-size-base;
		  line-height: @line-height-base;
		  color: @text-color;

	  	margin-bottom: 0;
	  	border-bottom: 0;
	  }
	}
}
.form-group {
  margin-bottom: 24px;
  .form-group {
	  margin-bottom: 0
	}
	.radio{
	  margin-top: 0;
	  margin-bottom: 0;
	}
	.checkbox {
	  margin-top: 0;
	  margin-bottom: 0;
	}
	label {
		margin-bottom: 0px;
	}
}

.cb-disabled-color {
	color: @cb-form-input-disabled-font-color;
}