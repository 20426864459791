//
// Stepper
// --------------------------------------------------

.cb-stepper {
	padding: 24px 0;
	ul,
	ol {
		margin: 0;
		padding: 0;
		list-style-type: none;
	  display: flex;
		li {
			padding: 0;
			margin: 0;
			position: relative;
			width: 100%;
			text-align: center;
			padding: 0 8px;
			p {
				.roboto-bold();
				margin-bottom: 0;
				&+ p {
					font-size: @font-size-small;
					line-height: @line-height-small-base;
					.roboto();
				}
			}
			a {
				color: @cb-base-font-color;
				&:hover,
				&:focus {
					color: @cb-base-font-color;
				}
				&.cb-stepper-disabled {
					&:hover,
					&:focus {
						text-decoration: none;
						cursor: default;
					}
				}
			}
			.cb-glyph {
				display: inline-block;
				color: @white-color;
				&.cb-glyph-circular {
					&::after{
						background-color: transparent;
						border: 2px solid @gray2-corporate-color;
					}
					&::before{
						color: @gray2-corporate-color;
					}
					margin-bottom: 1px;
				}

				&.cb-glyph-numeric {
					background-color: transparent;
					color: @gray2-corporate-color;
					border-color: @gray2-corporate-color;
					margin-bottom: 8px;
				}

				&.cb-glyph-circular {
					&::after{
						background-color: transparent;
						border: 2px solid @gray2-corporate-color;
					}
					&::before{
						color: @gray2-corporate-color;
					}
				}
			}
			&::after {
				height: 2px;
				width: calc(100% - 44px);
				background: @gray2-corporate-color;
				content: '';
				display: block;
				position: absolute;
				top: 15px;
				left: calc(50% + 22px);
			}
			&.cb-stepper-active {
				.cb-glyph {
					color: @white-color;
					&.cb-glyph-circular {
						&::after{
							background-color: @blue-accent1-corporate-color;
							border: 2px solid @blue-accent1-corporate-color;
						}
						&::before{
							color: @white-color;
						}
					}

					&.cb-glyph-numeric {
						background-color: @blue-accent1-corporate-color;
						color: @white-color;
						border-color: @blue-accent1-corporate-color;
					}
				}
				&::after {
					background: @gray2-corporate-color;
				}
			}
			&.cb-stepper-skip {
				.cb-glyph {
					color: @white-color;
					&.cb-glyph-circular {
						&::after{
							background-color: @gray2-corporate-color;
							border: 2px solid @gray2-corporate-color;
						}
						&::before{
							color: @white-color;
						}
					}

					&.cb-glyph-numeric {
						background-color: @gray2-corporate-color;
						color: @white-color;
						border-color: @gray2-corporate-color;
					}
				}
				&::after {
					background: @blue-accent1-corporate-color;
				}
			}
			&.cb-stepper-complete {
				.cb-glyph {
					&.cb-glyph-circular {
						&::after{
							background-color: @blue-accent1-corporate-color;
							border: 2px solid @blue-accent1-corporate-color;
						}
						&::before{
							color: @white-color;
						}
					}
				}
				&::after {
					background: @blue-accent1-corporate-color;
				}
			}
			&:last-child {
				&::after {
					width: 0;
					height: 0;
				}
			}
		}
	}

	&:not(.cb-stepper-simple) {
		// mobile + tablet
		@media (max-width: @screen-sm-max) {
			.cb-v-stepper();
		}
	}
	&.cb-stepper-vertical {
		.cb-v-stepper();
	}

	// Simple
	&.cb-stepper-simple {
		ul,
		ol {
			li {
				p {
					display: none;
					visibility: hidden;
				}
				.cb-glyph {
					color: @blue-accent1-corporate-color;
					&.cb-circle {
						color: @gray2-corporate-color;
					}
				}
				&.cb-stepper-skip,
				&.cb-stepper-active {
					.cb-glyph {
						&.cb-circle,
						&.cb-disc {
							color: @blue-accent1-corporate-color;
						}
					}
				}
				&::after {
					width: calc(100% - 24px);
					top: 9px;
					left: calc(50% + 12px);
				}
			}
		}
	}
}


// ------------------------------------ Mixin

.cb-stepper (@color) {
	.cb-stepper  {
		ul,
		ol {
			li {
				&.cb-stepper-active {
					.cb-glyph {
						&.cb-glyph-circular {
							&::after{
								background-color: @color;
								border: 2px solid @color;
							}
						}
						&.cb-glyph-numeric {
							background-color: @color;
							border-color: @color;
						}
					}
				}
				&.cb-stepper-skip {
					&::after {
						background: @color;
					}
				}
				&.cb-stepper-complete {
					.cb-glyph {
						&.cb-glyph-circular {
							&::after{
								background-color: @color;
								border: 2px solid @color;
							}
						}
					}
					&::after {
						background: @color;
					}
				}
			}
		}

		// Simple
		&.cb-stepper-simple {
			ul,
			ol {
				li {
					.cb-glyph {
						color: @color;
						&.cb-circle {
							color: @gray2-corporate-color;
						}
					}
					&.cb-stepper-skip,
					&.cb-stepper-active {
						.cb-glyph {
							&.cb-circle,
							&.cb-disc {
								color: @color;
							}
						}
					}
				}
			}
		}
	}
}

.cb-v-stepper () {
	padding: 0;
	ul,
	ol {
		display: block;
		li {
			text-align: left;
			padding: 0;
			margin-bottom: 44px;
			width: auto;
			.cb-glyph {
				float: left;
			}
			p {
				padding-left: 40px;
				&:first-of-type {
					padding-top: 6px;
				}
			}
			&::after {
				height: 100%;
				width: 2px;
				top: 38px;
				left: 15px;
			}
			&:last-child {
				&::after {
					width: 0;
					height: 0;
				}
			}
		}
	}
}

// ------------------------------------ corporate
[data-cb-program="corporate"],
.cb-corporate-program {
	.cb-stepper (@blue-corporate-color);
}
// ------------------------------------ k-12
[data-cb-program="k-12"],
.cb-k-12-program {
	.cb-stepper (@k-12-color);
}
// ------------------------------------ access
[data-cb-program="access"],
.cb-access-program {
	.cb-stepper (@access-color);
}
// ------------------------------------ higher-education
[data-cb-program="higher-education"],
.cb-higher-education-program {
	.cb-stepper (@higher-education-color);
}