//
// Sticky Column
// --------------------------------------------------

.cb-scrollbar-container {
  .cb-sticky-column,
  .cb-sticky-nav-bar {
    display: none;
  }
}
@media screen and (max-width: @screen-xs-max) {
  .cb-scrollbar-container {
    &.active {
      .table-responsive {
        position: relative;
        overflow: hidden;
        .table {
          z-index: 1;
        }
        .cb-sticky-column {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          z-index: 2;
          display: block;
          border-top: 0;
          border-left: 0;
          .table {
            thead{
              tr {
                td,
                th {
                  &:last-child {
                    border-right: 5px solid @gray2-corporate-color;
                  }
                }
              }
            }
            tbody{
              tr {
                td,
                th {
                    background-color: @white-color;
                  &:last-child {
                    border-right: 5px solid @gray2-corporate-color;
                  }
                }
              }
            }
          }
        }

        .cb-sticky-nav-bar {
          display: block;
          height: calc(100% - 1px);
          position: absolute;
          right: 0;
          top: 1px;
          text-align: center;
          z-index: 3;
          a {
            position: relative;
            font-size: 0.75rem;
            float: left;
            width: 24px;
            height: 100%;
            color: @white-color;
            transition: background-color 0.2s;
            background-color: rgba(30, 30, 30, 0.75);
            border-bottom: 1px solid @gray-corporate-border;
            opacity: 0.6;
            span {
              position: absolute;
              left: 6px;
              &:nth-child(1) {
                top: 6px;
              }
              &:nth-child(2) {
                top: 50%;
              }
              &:nth-child(3) {
                bottom: 6px;
              }
            }
            &:hover,
            &:focus,
            &:active {
              opacity: 0.8;
              background-color: @blue-corporate-color;
            }
          }
        }
      }
    }
  }
}
