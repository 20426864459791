//
// Person
// --------------------------------------------------

.cb-person {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	li {
		padding: 0;
		margin: 0;

  	p {
  		margin-bottom: 0;
  		&:first-child {
  			.roboto-bold();
  		}
  	}

    width: 25%;
    margin-right: 30px;
		margin-bottom: 48px;
		@media (max-width: @screen-sm-max) {

	    width: 100%;
	    margin: 0;
		}
  }
	@media (max-width: @screen-sm-max) {
		margin-bottom: 48px;
	}
}

// custom role for authors
.cb-person[role="author"] {
	li {
    &:first-child {
    	p {
    		&:first-child {
		    	&::before {
		    		display: inline;
		    		content: 'by ';
		    	}
    		}
    	}
    }
    &:nth-child(n+2) {
    	p {
    		&:first-child {
		    	&::before {
		    		display: inline;
		    		content: 'and ';
		    	}
		    }
    	}
    }
	}
}
