//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb{
	background-color: transparent;
	margin: 24px 0 24px 0;
	padding: 0;
	border: 0;
	float: left;
	width: 100%;
	> .active,
	> .cb-truncate {
		color: @cb-base-font-color;
	}
	> li {
		height: 24px;
		line-height: 1.5em;
		overflow-y: visible;
		white-space: nowrap;
		display: block;
		float: left;
		+ li::before {
			padding: 0 10px 0 12px;
			color: @cb-base-font-color;
		}
	}

	&.cb-has-collapse {
		> li {
			height: 24px;
			line-height: 1.500em;
		}
	}
	a {
		&:focus {
			.cb-focus();
		}
		&.hide {
	    display: none!important;
    	visibility: hidden!important;
		}
		&.cb-truncate-show {
			color: @cb-base-font-color;
		}
		&.cb-truncate-hide {
			&::before {
				margin-left: 4px;
				content: "(";
				display: inline-block;
				color: @cb-base-font-color;
			}
			&::after {
				content: ")";
				display: inline-block;
				color: @cb-base-font-color;
			}
		}
	}
	@media (max-width:767px) { // mobile
		padding-top: 24px;
		padding-bottom: 23px;
		border-bottom: 1px solid @gray-corporate-border;
  	.border-box();
		margin-top: 0;
		margin-bottom: 24px;
		border-radius: 0;
		> li {
			+ li::before {
				padding: 0 4px 0 6px;
			}
		}
  }
	.cb-truncate-hide {
		display: none;
	}
  &.cb-activate-collapse {
  	.cb-truncate-hide {
  		display: inline-block;
  	}
  }
}