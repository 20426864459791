//
// $.fn.cbCreditCardTypeDetector
// --------------------------------------------------

input {
  &.cb-credit-card {
    background: @cb-form-control-bk-color url('@{cb-image-path}@{gv-image-sprite-cards-png}') -1000px 0 no-repeat;

    &.amex {
        background-position: right 0;
    }
    &.diners {
        background-position: right -32px;
    }
    &.discover {
        background-position: right -180px;
    }
    &.jcb {
        background-position: right -143px;
    }
    &.mastercard {
        background-position: right -69px;
    }
    &.visa {
        background-position: right -106px;
    }
    &.paypal{
      background-position: right -217px;
    }
  }
}

