//
// Local navigation
// Focus: custom
// --------------------------------------------------

.cb-local-navigation,
.cb-site-navigation {
  &.container{
    margin: 0;
    padding: 0;
  }

  border: 0;
  border-bottom: 1px solid @gray-corporate-border;
  background: @white-color;
  width: 100%;
  position: relative;

  .glyphicon-cb,
  .cb-glyph {
    font-size: @font-size-base;
    margin-right: @cb-glyph-icon-gutter;

    &.cb-down,
    &.cb-up {
      margin-right: 0;
    }
  }
  ul:not(.cb-menu-list-ul) {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      list-style-image: none;
      background: none;
      float: left;
      margin-right: 1px;
      a {
        font-size: 0.875em;
        display: block;
        color: @black-corporate-color;
        height: 59px;
        padding: 0;
        padding-top: 36px;
        margin-right: 15px;
        margin-left: 15px;
        &:focus {
          outline: none;
          text-decoration: none;
        }
      }
      &:not(.dropdown) ul {
        display: none;
      }
    }
  }

  // Desktop Layout
  .cb-desktop-navigation {
    width: 100%;
    .col-xs-12 {
      height: 65px;
    }
    .cb-site-name {
      height: 99%;
      padding-top: 29px;
      float: left;
      display: inline-block;
      a.title {
        .roboto-slab-bold();
        font-size: 1.500em;
        line-height: 1.417em;
        padding: 0;
        color: @black-corporate-color;
        margin: 0 10px;
        &:focus {
          outline: none;
          text-decoration: none;
        }
        &:hover {
          text-decoration: none;
        }
      }
      &.focus,
      &:focus{
        .cb-focus();
      }
      &.cb-multi {
        padding-top: 17px;
        a.title {
          font-size: 1.125em;
          line-height: 1.167em;
          max-width: 160px;
          display: block;
          span {
            word-break: word-all;
          }
        }
      }
      &.hover {
        background-color: @digital-field-gray;
      }
    }
    :not(.cb-menu-list-ul) li {
      padding:2px 15px 4px 15px;
      a {
        margin-right: 0;
        margin-left: 0;
        font-size: 0.875em;
        line-height: 1.714em;
        &:hover {
          text-decoration: none;
        }
      }
      &.hover,
      &:hover {
        cursor: pointer;
        background-color: @digital-field-gray;
        a,
        a:hover{
          border: 0;
        }
      }
      &.focus,
      &:focus{
        .cb-focus();
      }
      &.active,
      &.active-trail {
        & > a {
          border-bottom: 3px solid @black-corporate-color;
        }
        &.hover,
        &:hover {
          background-color: @digital-field-gray;
          a {
            border-color: @black-corporate-color;
          }
        }
      }
      &.cb-multi {
        & > a {
            line-height: 18px;
          span {
            max-width: 105px;
            float: left;
            margin-top: -15px;
          }
        }
      }

      &.cb-multi {
        &.cb-icon {
          & > a {
            span {
              padding-left: 18px;
            }
          }
          .glyphicon-cb,
          .cb-glyph {
            float: left;
            position: relative;
            &::before{
              top: 5px;
              position: absolute;
              left: 0px;
            }
          }
        }
      }
      &.dropdown {
        .dropdown-menu {
          margin: 0;
          padding: 0;
          li {
            width: 99.9%;
            float: left;
            padding: 0;
            &:first-child {
              margin-top: 0 !important;
            }
            a {
              margin: 0;
              padding: 9px 15px;
              height: auto;
              border: 0;
              &:focus{
                outline: 0;
              }
            }
            a:hover {
              color: @black-corporate-color !important;
              background-color: @digital-field-gray !important;
            }
          }
          li.active {
            a {
              color: @black-corporate-color;
              background-color: @digital-field-gray;
              border: 0;
            }
          }
        }

        .dropdown-toggle {
          margin-right: 22px;
          .glyphicon-cb,
          .cb-glyph{
            position: relative;
            display: inline-block;;
            &::before {
              font-size: 1em;
              padding-left: @cb-glyph-icon-gutter;
              position: absolute;
              bottom: -3px;
              right: -24px;
            }
          }
        }
      }
    }
    .dropdown-menu li{
      margin-bottom: 1px;
    }
    .cb-local-menu{
      float: right;
    }
    .dropdown-menu li{
      margin-bottom: 1px;
    }
    margin: 0 auto;
    &.cb-no-site-name {
      .cb-local-menu{
        float: left;
      }
    }
  }


  @media (max-width: @screen-sm-max) {
    border-bottom: 0;
    height: 44px;
  }
  // Mobile Layout
  .cb-mobile-navigation {
    li {
      width: 100% !important;
      min-width: 100%;
      float: none;
      a {
        font-size: 1em !important;
        padding: 0;
        padding-top: 8px!important;
        border-bottom: 1px solid @cb-gray7-color;
        height: auto!important;
        min-height: 42px;
        overflow-y: hidden;
        &:focus {
          .cb-focus();
        }
      }
      &.dropdown,
      &.mobile-dropdown {
          .roboto-bold();
          position: relative;
          a {
            span {
              display: block;
              max-width: 90%;
            }
          }
      }
      &.dropdown:after,
      &.mobile-dropdown:after{
        .cb-glyphs();
        font-weight: 400;
        color: @black-corporate-color;
        display: block;
        content: "\e940";
        right: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-child {
        & > a {
          border-bottom: 0;
        }
      }
    }

    .cb-mobile-local-header {
      color: @black-corporate-color;
      border-bottom: 1px solid;
      border-color: @cb-gray7-color;
      border-bottom-color: @cb-base-font-color;
      font-size: 1em;
      height: 44px;
      padding: 0;
      padding: 10px 15px 15px;
      padding-bottom: 0;
      margin: 0;
      a{
        &.local-menu-title {
          color: @black-corporate-color;
          .roboto-slab-bold();
          font-size: 1.125em;
        }
        &:focus {
          .cb-focus();
        }
      }
      .local-menu-icon {
        color: @black-corporate-color;
        font-size: 0.750em;
        position: absolute;
        top: 16px;
        right: 15px;
        text-transform: uppercase;
        .glyphicon-cb,
        .cb-glyph{
          margin-left: 10px;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.cb-multi {
      .cb-mobile-local-header {
        padding-top: 4px;
      }
      a{
        &.local-menu-title {
          font-size: 0.875em;
          line-height: 1.286em;
          max-width: 120px;
          display: block;
          span{
            word-break: word-all;
          }
        }
      }
    }
    .cb-mobile-local-panels{
      position: fixed;
      width: 100%;
      min-width: 100%;
      background: @white-color;
      left: 0;
      z-index: 902;
    }
    .cb-menu-panel {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 0;
      background-color: @white-color;
      opacity: 0.96;
      &.show {
        width: 100%;
        min-width: 100%;
        z-index: 903;
        li.cb-nav-back {
          a:not(.local-menu-icon){
            padding-left: 20px;
            .roboto-bold();
            cursor: pointer;
            &::before {
              .cb-glyphs();
              font-weight: 400;
              left: 11px;
              position: absolute;
              top: 12px;
              content: "\e921";
            }
          }
        }
        .link-panel {
          &:not(.first-panel) {
            li {
              &:not(.cb-nav-back){
                a {
                  padding-left: 24px;
                }
              }
            }
          }
        }
      }
      &.adjust {
        width: 100%;
        min-width: 100%;
      }
      &.showadjust {
        width: 100%;
        min-width: 100%;
        z-index: 903;
      }
    }
  }

  // sticky header
  &.sticky-local-nav{
    position: fixed;
    z-index: 903;
    left: 0;
    top: 0;
    background: @white-color;
    width: 100%;
    border: 1px solid @gray-corporate-border;
    border-left:0;
    border-right:0;

    .cb-mobile-local-header{
      position: fixed;
      z-index: 903;
      left: 0;
      top: 0;
      width: 100%;
      background: @white-color;
    }
    .cb-mobile-local-panels{
      top: 0;
    }
  }

  .cb-overflow-y{
    height: 100%;
    overflow-y: auto;
  }
}

.cb-adjust-local-nav{
  margin-top: 68px;
  @media (max-width: @screen-sm-max) {
    margin-top: 46px;
  }
}
.cb-no-overflow{
  overflow: hidden !important;
}

// no focus on mouse and touch
[data-cb-input-m="mouse"] {
  .cb-local-navigation,
  .cb-site-navigation {
    .cb-desktop-navigation {
      .cb-site-name {
        &.focus,
        &:focus{
          .cb-no-focus();
        }
      }
      li {
        &.focus,
        &:focus{
          .cb-no-focus();
        }
      }
    }
    .cb-mobile-navigation {
      li {
        a {
          &:focus {
            .cb-no-focus();
          }
        }
      }
      .cb-mobile-local-header {
        a{
          &:focus {
            .cb-no-focus();
          }
        }
      }
    }
  }
}
