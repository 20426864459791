//
// Panels
// --------------------------------------------------

.panel {
	.panel-variant(@panel-default-border; @panel-default-text; @panel-default-heading-bg; @panel-default-border);
	color: @cb-base-font-color;
  .roboto();
	font-size: @font-size-small;
	line-height: 1.28571429em;
	.list-group {
		font-size: @font-size-base;
	}
	p:last-child {
		margin: 0!important;
	}
	.panel-heading {
		font-size: @font-size-base;
		color: @cb-base-font-color;
  	background-color: @panel-heading-bg;
  	.panel-title{
  		.roboto-bold();
  		font-size: @font-size-base;
  	}
	}
	.panel-body {
		font-size: @font-size-small;
		* {
			font-size: @font-size-small;
		}
	}
	.panel-footer {
		.roboto-bold();
		font-size: @font-size-small;
	}
	&.cb-panel-blue {
		.panel-variant(@blue-accent1-corporate-color; @cb-base-font-color; @blue-accent1-corporate-color; @blue-accent1-corporate-color);
		.panel-heading {
			color: @white-color;
		}
	}
	.cb-scrollbar-container{
		margin-bottom: 0;
	}
	h3, h4 {
		.roboto-bold();
	}

	&.cb-plg-no-border {
		.list-group-item {
			border: 0;
		}
	}
}