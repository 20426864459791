//
// Hero Login Module
// --------------------------------------------------

.cb-login-hero{
	position: relative;
	background-color: transparent!important;
	background-position: top;
	color: @cb-base-font-color;
	padding: 48px 0;
	@media (max-width: @screen-sm-max) {
		padding: 24px 0;
	}
	.cb-login-img-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: @gray3-corporate-color;
		margin-bottom: 0;
		// desktop
		@media (min-width: @screen-desktop) {
			height: 336px;
		}
		// oversized
		@media (min-width: @screen-lg-min) {
			height: 408px;
		}
		// tablet
		@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
			height: 288px;
		}
		// mobile
		@media (max-width: @screen-xs-max) {
			height: 384px;

			.container {
				padding: 0;
			}
		}
		.container,
		.row {
			height: inherit;
		}
		.cb-login-img {
			height: inherit;
		  background-position: center center;
		  background-size: auto 100%;
		  background-repeat: no-repeat;
		}
	}

  .cb-block-content {
		.cb-hero-content{
			// desktop
			@media (min-width: @screen-desktop) {
				margin-top: 336px;
			}
			// oversized
			@media (min-width: @screen-lg-min) {
				margin-top: 408px;
			}
			// tablet
			@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
				margin-top: 288px;
			}
			// mobile
			@media (max-width: @screen-xs-max) {
				margin-top: 384px;
			}
			h1, .h1,
			h2, .h2,
			h3, .h3,
			h4, .h4 {
		  	font-size: 2.625rem;
		  	line-height: 1.1428571428571428em;
		  	font-family: @font-family-RobotoSlab;
		  	font-weight: 200;
				color: @cb-base-font-color;
				display: inline-block;
		    border-top-style: solid;
		    border-top-width: .2em;
			  border-top-color: @cb-base-font-color;
		    padding-top: .2em;
			}
			p {
				margin-bottom: 0!important;
			}
		}
  }

  //  login module
  .cb-login-module {
  	@media (max-width: @screen-xs-max) {
  		margin-top: 24px;
  	}
		.cb-login-module-footer {
			p {
				text-align: center;
				width: 100%;
			}
		}
  }
	.cb-cta-card {
		margin-top: 24px;
	}
}


// ------------ Mixin
.cb-login-hero-color (@color1, @color2, @glyph, @bk) {
	.cb-login-img-container {
		background-color: @bk;
	}
	.cb-block-content {
		.cb-hero-content{
			h1, .h1,
			h2, .h2,
			h3, .h3,
			h4, .h4 {
		  	border-top-color: @bk;
			}
		}
	}
	.cb-login-module {
		.cb-login-module-header {
			.cb-glyph-multi {
				min-width: 64px;
				.glyph-multi-bk-color (@color1, @color2, @glyph);
			}
		}
	}
}
// ------------------------------------ corporate
[data-cb-program="corporate"],
.cb-corporate-program,
.cb-blue-corporate-color {
	.cb-login-hero-color(@white-color, @yellow-corporate-color, @blue-accent1-corporate-color, @blue-corporate-color);
}
// ------------------------------------ k-12
[data-cb-program="k-12"],
.cb-k-12-program,
.cb-k-12-color {
	.cb-login-hero-color(@white-color, @black-color, @k-12-color, @k-12-color);
}
// ------------------------------------ access
[data-cb-program="access"],
.cb-access-program,
.cb-access-color {
	.cb-login-hero-color (@white-color, @black-color, @access-color, @access-color);
}
// ------------------------------------ higher-education
[data-cb-program="higher-education"],
.cb-higher-education-program,
.cb-higher-education-color {
	.cb-login-hero-color(@white-color, @black-color, @higher-education-color,@higher-education-color);
}
