//
// Input Groups
// --------------------------------------------------

.input-group {
	.btn-primary,
	.btn-secondary{
  	line-height: inherit;
  	height: @cb-form-control-height;
	}

	.btn-default{
  	line-height: inherit;
  	height: @cb-form-control-height;
	}
	.input-group-addon {
		border-color: @gray4-corporate-color;
		background-color: @cb-form-control-bk-color;
		width: 48px;
		&.primary{
			.gradient(@cb-btn-primary-gradient-start-color, @cb-btn-primary-gradient-end-color);
			color: @cb-btn-primary-font-color;
			border: 1px solid transparent;
		}
		&.secondary{
			color: @black-corporate-color;
		  background-color: transparent;
		  border: 1px solid @black-corporate-color!important;
		}
		&.radio{
			padding: 0 6px;
			label{
				padding-left: 16px;
			}
			input[type="radio"]{
				+ span {
					&::before {
						background: @cb-gray9-color;
					}
				}
			}
		}
		&.checkbox {
			padding: 0 6px;
			label{
				padding-left: 16px;
			}
			input[type="checkbox"]{
				+ span {
					&::before {
						background: @cb-gray9-color;
					}
					&::after {
						top: -19px;
					}
				}
			}
		}
	}
	.input-group-btn {
		.btn {
			margin-top: 0 !important;

		}
	}
}
.input-group-btn > .btn{
	&.btn-secondary {
		&+.btn-primary {
			margin-left: 0 !important;
		}
	}
}