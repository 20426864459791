//
// CB Highlighted Transactions band
// Focus: custom
// --------------------------------------------------

.cb-hi-transactions {
  background-color: @white-color;
  border: 1px solid @gray-corporate-border;
  padding:  18px;
  line-height: 0;
  a {
    color: @cb-base-font-color;
    width: 100%;
    display: inline-block;
    &:hover,
    &:focus {
      color: @cb-base-font-color;
      text-decoration: none;
      outline: 0;
      h2, h3, h4{
        text-decoration: underline;
      }
      p {
        text-decoration: none;
      }
    }
  }
  .cb-hi-transactions-image {
    width: 93px;
    display: table-cell;
    img {
      width: 78px;
      height: 78px;
      background-color: @cb-gray8-color;
    }
  }
  .cb-hi-transactions-content {
    display: table-cell;
    vertical-align: top;
    h2, h3, h4 {
      font-size: 1.125rem;
      line-height: 1.3333em;
      color: @cb-base-font-color;
      margin: 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &.focus{
    .cb-focus();
    outline-offset: -1px;
  }
}

.cb-hi-transactionsband {
  list-style: none;
  width: 100%;
  border-top: 1px solid @gray-corporate-border;
  border-bottom: 1px solid @gray-corporate-border;
  padding: 0;
  margin-bottom: 0;
  display: inline-block;
  line-height: 0;
  .cb-hi-transactions {
    padding: 0;
    padding-bottom: 18px;
    float: left;
    list-style: none;
    width: 33.3333%;
    border: 0;
    a {
      float: left;
      margin-top: 18px;
      padding: 0 18px 0 18px;
      border-right: 1px solid @gray-corporate-border;
    }
   &:last-of-type{
      a {
        border-right: 0;
        padding-bottom: 0;
      }
    }
  }
  .cb-hi-transactions:first-child:nth-last-child(2),
  .cb-hi-transactions:first-child:nth-last-child(2) ~ .cb-hi-transactions {
    &:first-of-type{
      margin-left: 16.6666%
    }
     &:last-of-type{
      margin-right: 16.6666%
    }
  }
  .cb-hi-transactions:first-child:nth-last-child(1),
  .cb-hi-transactions:first-child:nth-last-child(1) ~ .cb-hi-transactions {
      margin-left: 33.3333%;
      a {
        border-right: 1px solid @gray-corporate-border;
        border-left: 1px solid @gray-corporate-border;
      }
  }
  @media (max-width: @screen-xs-max) { // mobile
    .cb-hi-transactions {
      width: 100%;
      margin: 0!important;
      padding-bottom: 0!important;
      a {
        width: calc(100% - 18px);
        border: 0!important;
        padding: 0 0 18px 0;
        margin-left: 18px;
      }

      border-bottom: 1px solid @gray-corporate-border !important;
      &:last-of-type{
        border-bottom: 0 !important;
        margin-bottom: 0;
        a {
          padding-bottom: 18px !important;
        }
      }
    }
  }

  @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { //tablet
    .cb-hi-transactions {
      width: 50%;
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid @gray-corporate-border;
        padding-bottom: 18px;
        margin-bottom: 0;
      }
      &:nth-child(3),
      &:nth-child(4) {
        padding-bottom: 18px;
        margin-bottom: 0;
      }
      &:nth-child(2n+2) {
        a {
          border-right: 0 !important;
        }
      }
    }

    .cb-hi-transactions:first-child:nth-last-child(2),
    .cb-hi-transactions:first-child:nth-last-child(2) ~ .cb-hi-transactions {
      &:first-of-type{
        margin-left: 0
      }
       &:last-of-type{
        margin-right: 0
      }
      border-bottom: 0;
    }
    .cb-hi-transactions:first-child:nth-last-child(1),
    .cb-hi-transactions:first-child:nth-last-child(1) ~ .cb-hi-transactions {
      margin-left: 33.3333%;
      a {
        border-right: 1px solid @gray-corporate-border;
        border-left: 1px solid @gray-corporate-border;
      }
      border-bottom: 0;
    }
    .cb-hi-transactions-content {
      h2, h3, h4 {
        font-size: 0.9375rem;
        line-height: 1.2em;
      }
    }
  }
}

.cb-hi-transactions-accordion {
  clear: both;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 0;
  .cb-hi-transactions-btn{
    text-align: center;
    position: relative;
    float: left;
    width: 100%;
    height: 1px;
    .cb-hi-transactions-see-more {
      position: absolute;
      width: 100px;
      left: calc(50% - 50px);
      top: 0;
      display: inline-block;
      line-height: 2.25em;
      padding: 0 20px;
      background-color: @white-color;
      border:  1px solid @gray-corporate-border;
      text-align: center;
      border-top: 0;
      z-index: 198;
      &::after{
        content: "More +";
      }
      &:focus{
        .cb-focus();
        outline-offset: -1px;
      }
    }
  }
  .cb-hi-transactionsband {
    margin-bottom: 0;
    -webkit-transition: height .5s;
    transition: height .5s;
    overflow: hidden;
  }
  .cb-hi-transactions{
    border-bottom:  0;
    &.cb-no-border-btm{
      border-bottom: 0;
    }
  }
  .even-more {
    border: none;
    width: 100%;
    float: left;
    margin: 0;
    text-align: center;
    padding: 24px 0;
    display: none;
  }

  &.open {
    .even-more {
      display: none;
    }
    .cb-hi-transactionsband {
      overflow: auto;
      overflow-x: hidden;
      height: auto;
    }
    .cb-hi-transactions {
      border-bottom: 1px solid @gray-corporate-border;
      padding-bottom: 18px;
      margin-bottom: 0;
      display: block !important;
    }
    .cb-hi-transactions-see-more {
      &::after{
        content: "Less -";
      }
    }
  }

  @media (max-width: @screen-xs-max) { // mobile
    .cb-hi-transactionsband{
      .cb-hi-transactions {
        padding-bottom: 18px;
        width: 100%;
        a {
          border-bottom: 0 !important;
        }
        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid @gray-corporate-border;
          margin-bottom: 0;
        }
        &:nth-child(3) {
          border-bottom: 0!important;
        }
        &:nth-child(n+4) {
          display: none;
        }
        &:last-child{
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    &.open {
      .cb-hi-transactionsband{
        .cb-hi-transactions {
          &:nth-child(3) {
            border-bottom: 1px solid @gray-corporate-border!important;
          }
        }
      }
    }
  }

  @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { //tablet
    .cb-hi-transactionsband {
      .cb-hi-transactions {
        width: 50%;
        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid @gray-corporate-border;
          padding-bottom: 18px;
          margin-bottom: 0;
        }
        &:nth-child(3),
        &:nth-child(4) {
          padding-bottom: 18px;
          margin-bottom: 0;
        }
        &:nth-child(2n+2) {
          a {
            border-right: 0 !important;
          }
        }
        &:nth-child(n+5) {
          display: none;
        }
        &.cb-hi-transactions-tdb{
          border-bottom: 0;
        }
      }
    }
    .cb-hi-transactions-btn{
      &.cb-hi-transactions-four{
        display: none;
      }
    }
  }

  @media (min-width: @screen-md-min) and (max-width: @screen-md-max), (min-width: @screen-lg-min) { //oversized, Desktop
    .cb-hi-transactionsband {
      .cb-hi-transactions{
        &:nth-child(3n+3) {
          a {
            border-right: 0;
          }
        }
        &:nth-child(n+4) {
          display: none;
        }
        &.cb-hi-transactions-ob{
          border-bottom: 0;
        }
      }
    }
  }
}

// no focus on mouse and touch
[data-cb-input-m="mouse"] {
  .cb-hi-transactions {
    &.focus{
      .cb-no-focus();
    }
  }
}

// Disabled Highlighted Transactions Band Accordion
.cb-hi-transactionsband {
  &[data-cb-element="no-cb"] {
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max), (min-width: @screen-lg-min) {
      .cb-hi-transactions {
        &:nth-child(3n+3) {
          a {
            border-right: 0;
          }
        }

        &:nth-child(1):not(:last-child):not(:nth-last-child(2)):not(:nth-last-child(3)),
        &:nth-child(2):not(:last-child):not(:nth-last-child(2)),
        &:nth-child(3):not(:last-child){
          border-bottom: 1px solid @gray-corporate-border;
        }

        &:nth-child(4):not(:last-child):not(:nth-last-child(2)):not(:nth-last-child(3)),
        &:nth-child(5):not(:last-child):not(:nth-last-child(2)),
        &:nth-child(6):not(:last-child){
          border-bottom: 1px solid @gray-corporate-border;
        }
      }
    }

    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) { //tablet
      .cb-hi-transactions {
        border-bottom: 1px solid @gray-corporate-border;
        a {
          border-right: 1px solid @gray-corporate-border !important;
        }
        &:nth-child(2n) {
          a {
            border-right: 0!important;
          }
        }
        &:nth-last-child(1):nth-child(even),
        &:nth-last-child(2):nth-child(odd),
        &:nth-last-child(1):nth-child(odd){
          border-bottom: 0;
        }
      }
    }
  }
}