//
// CB Photo Gallery
// --------------------------------------------------

// thumbnails
.cb-photo-gallery{
	float: left;
	width: 100%;
	margin-bottom: 24px;
	ul {
		float: left;
		width: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		li {
			list-style: none;
			margin-right: 0;
			margin: 0 6px 0 6px;
			float: left;

      .cb-photo-thumb {
				margin: 48px 0 0 0;
				img {
	      	border: solid 1px @gray4-corporate-color;
					.border-box();
					width: 100%;
					height: auto;
					max-height: 100%;
	      	overflow: hidden;
					opacity: 1;
    			transition: opacity 200ms;
				}
      }
      .cb-photo-thumb-info {
      	h2, .h2,
				h3, .h3,
				h4, .h4,
				h5, .h5 {
					color: @cb-base-font-color;
					.roboto-bold();
					font-size: @font-size-base;
					line-height: @line-height-base;
					margin: 0;
				}
				p {
					display:  none;
					color: @cb-base-font-color;
					&:first-of-type {
						display: block;
						margin-bottom: 0;
					}
				}
      }
      a {
				color: @cb-base-font-color;
				&:hover,
				&:active,
				&.focus {
					* {
						color: @cb-base-font-color;
					}
				}

				&.focus{
					text-decoration: underline;;
					cursor: pointer;

					img {
						opacity: 0.7;
					}
				}
      }

			.cb-photo-details-info {
				display: none;
				visibility: hidden;
			}
		}
		// ------ item width calculation
		&.cb-photo-gallery-5 {
			// 5 items
			.cb-photo-gallery-item(5, 8);
			.cb-photo-thumb {
				height: 120px;
			}
		}
    &.cb-photo-gallery-4 {
    	// 4 items
			.cb-photo-gallery-item(4, 6);
			.cb-photo-thumb {
				height: 120px;
			}
    }
    &.cb-photo-gallery-3 {
    	// 3 items
			.cb-photo-gallery-item(3, 4);
			.cb-photo-thumb {
				height: 120px;
			}
    }
    &.cb-photo-gallery-2 {
    	// 2 items
			.cb-photo-gallery-item(2, 2);
			.cb-photo-thumb {
				height: 144px;
			}
	    &.cb-photo-size-1 {
				.cb-photo-thumb {
					height: 120px;
				}
	    }
	    &.cb-photo-size-2 {
				.cb-photo-thumb {
					height: 96px;
				}
	    }
	    &.cb-photo-size-3 {
				.cb-photo-thumb {
					height: 72px;
				}
	    }
    }
    &.cb-photo-gallery-1{
    	// 1 items
			li {
				width: 100%;
    		margin-left: 0;
    		margin-right: 0;
				.cb-photo-thumb {
					height: 144px;
				}
			}
    }
	}
}

// modal
.cb-photo-gallery-modal {

	// Mobile/tablet layout
	&.cb-photo-gallery-mt {
		.mt-header {
			width: 100%;
			height: 44px;
			background-color: @white-color;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;
			&.gallery-header {
				padding-left: 16px;
				.count-info {
					width: calc(80% - 18px);
			    color: @cb-base-font-color;;
			    font-size: @font-size-small;
			    line-height: 44px;
			    margin-bottom: 0;
					span {
						padding-right: 5px;
						&:not(:last-child) {
							&::after {
								margin-left: 6px;
								display: inline-block;
								content: '/';
								width: 10px;
								height: 10px;
							}
						}
					}
				}
				.close {
					position: absolute;
					top: 0px;
					right: 0;
					.roboto-bold();
					font-size: @font-size-base;
					width: 44px;
					height: 44px;
				}
			}
		}
		&.cb-no-desc {
			.mt-header {
				background-color: @black-corporate-color;
				.count-info {
					color: @white-color;
				}
				.close {
					color: @white-color;
					opacity: 0.9;
				}
			}
		}

		.modal-dialog {
			// mobile
			@media (max-width: @screen-xs-max) {
				width: 100%;
				height: calc(100% - 45px);
				margin: 0;
				margin-top: 45px;
				overflow-y: auto;
			}

			// tablet
			@media (min-width: @screen-sm-min) {
				width: 100%;
				height: calc(100% - 45px);
				margin: 0;
				margin-top: 45px;
				overflow-y: auto;
		  }
		}
	}
	.modal-dialog {
		// base is oversized
		display: flex;

	  // desktop
	  @media (min-width: @screen-md-min) {
			height: calc(100% - 60px);
	    width: calc(@container-md - 60px);
		  max-height: 520px;
		  margin-right: auto;
		  margin-left: auto;
	  }

	  // oversized
	  @media (min-width: @screen-lg-min) {
			height: calc(100% - 60px);
		  width: calc(@container-lg - 60px);
		  max-height: 700px;
		  margin-right: auto;
		  margin-left: auto;
	  }

		.modal-content {
			width: 100%;
			border: 0;

			// -------tablet, mobile
			@media (max-width: @screen-sm-max) {
		    height: 100%;
		    flex-wrap: wrap;
			}
			display: flex;
		}
		.gallery-container {
			display: flex;

			// -------tablet, mobile
			@media (max-width: @screen-sm-max) {
				width: 100%;
			}
			background-color: @black-corporate-color;
			.gallery-navigation {
				// mobile
				@media (max-width: @screen-xs-max) {
					width: 30px;
				}
				width: 44px;
				display: flex;
				justify-content: center;
	    	align-items: center;
	    	span {
			    font-size: 1.25rem;
			    width: 1em;
			    height: 1em;
			    opacity: .5;
			    color: @white-color;
			    transition: opacity 300ms;
	    	}
	    	&:hover,
	    	&.hover{
	    		cursor: pointer;
	    		span {
	    			opacity: 1;
	    		}
	    	}
			}
			.cb-gallery-image {
				// mobile
				@media (max-width: @screen-xs-max) {
					width: calc(100% - 60px);
					&::after{
						padding-top: 80%;
						display: block;
						content: '';
					}
				}

				//tablet
				@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
					width: calc(100% - 88px);
					&::after{
						padding-top: 80%;
						display: block;
						content: '';
					}
				}

				// desktop
				@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
					width: 520px;
				}

				// oversized
				@media (min-width: @screen-lg-min) {
					width: 700px;
				}

		    background-size: contain;
		    background-repeat: no-repeat;
		    background-position: center center;
		    height: inherit;
		    position: relative;
		    &.cb-high-contrast {
		    	text-align: center;
		    	background-image: none !important;
		    	img {
		    		position: absolute;
		    		left: 50%;
					  top: 50%;
					  transform: translate(-50%, -50%);
		    	}
		    }
		    .cb-hit-left,
		    .cb-hit-right {
		    	position: absolute;
		    	width: 50%;
		    	height: 100%;
		    	&:hover {
	    			cursor: pointer;
		    		opacity: 0.3;
		    	}
		    }
		    .cb-hit-left {
		    	left: 0;
		    }
		    .cb-hit-right {
		    	right: 0;
		    }
			}
		}
		.info-container {
			display: flex;
			flex-direction: column;

			// -------tablet, mobile
			@media (max-width: @screen-sm-max) {
				width: 100%;
			}
			width: 358px;
			background-color: @white-color;
			.gallery-header {
				position: relative;
				margin-left: 18px;

				// oversized/desktop
				width: calc(100% - 36px);
				border-bottom: 1px solid @gray4-corporate-color;
  			.border-box();
				.count-info {
					width: calc(80% - 18px);
			    color: @cb-base-font-color;
			    font-size: @font-size-small;
			    line-height: 44px;
			    margin-bottom: 0;
					span {
						padding-right: 5px;
						&:not(:last-child) {
							&::after {
								margin-left: 6px;
								display: inline-block;
								content: '/';
								width: 10px;
								height: 10px;
							}
						}
					}
				}
				.close {
					position: absolute;
					top: 0px;
					right: -18px;
					.roboto-bold();
					font-size: @font-size-base;
					width: 44px;
					height: 44px;
				}
			}
			.gallery-des {
				// -------tablet, mobile
				@media (max-width: @screen-sm-max) {
					width: 100%;
				}
				padding: 12px 18px;
				overflow-y: auto;
				.main-title {
					font-size: 1.188rem;
			    line-height: 30px;
			    margin: 0;
			    padding: 0;
				}
		    .main-info {
    	    color: @cb-base-font-color;
			    font-size: @font-size-small;
			    line-height: 1.4;
			    margin: 0;
			    padding: 0;
		    }
		    // TBD: we may no longer need this one
				.sub-title {
					.roboto-bold();
			    font-size: @font-size-base;
			    line-height: @line-height-base;
			    margin: 0.75em 0 0.375em;
			    margin-top: 30px;
				}
			}
		}
	}
	&.cb-no-desc {
		.modal-content {
			background-color: @black-corporate-color;
		}
		.info-container {
			width: calc(100% - 2px);
			position: absolute;
			top: 0;
			left: 1px;
			background-color: transparent;
			.gallery-header {
				border: 0;
				background-color: transparent;
				.count-info {
					color: @white-color;
				}
				.close {
					color: @white-color;
					opacity: 0.9;
				}
			}
			.gallery-des {
				display: none;
				visibility: hidden;
			}
		}
		.gallery-container {
			width: 100%;
			// mobile
			@media (max-width: @screen-xs-max) {
				.cb-gallery-image {
					width: calc(100% - 60px);
				}
			}

			//tablet
			@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
				.cb-gallery-image {
					width: calc(100% - 88px);
				}
			}

			// desktop
			@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
				.gallery-navigation {
					width: 96px;
				}

				.cb-gallery-image {
					width: calc(100% - 192px) !important;
				}
			}

			// oversized
			@media (min-width: @screen-lg-min) {
				.gallery-navigation {
					width: 96px;
				}

				.cb-gallery-image {
					width: calc(100% - 192px) !important;
				}
			}
		}
	}
	&.cb-single-img{
		.gallery-header {
			height: 44px;
			border-bottom: 0;
			.count-info {
				display: none;
			}
		}
		.gallery-container {
			.gallery-navigation {
				&:hover {
					cursor: default;
				}
				.cb-glyph  {
					display: none;
				}
			}
			.cb-hit-left,
			.cb-hit-right {
				display: none;
			}
			.gallery-navigation {
				outline: 0;
			}
		}
	}
}