//
// $.fn.cbIntPhoneDetector
// --------------------------------------------------

.cb-int-phone-container{
  position: relative;
  width: 100%;
  .flag-dropdown{
    position: absolute;
    top: 0;
    bottom: 0;
    .selected-flag {
      cursor: pointer;
      z-index: 1;
      position: relative;
      width: 52px;
      height: 100%;
      padding: 1px 1px 1px 10px;
      display: inherit;
      .flag {
        position: absolute;
        top: 50%;
        margin-top: -6px;
        .arrow{
          display: block;
          position: relative;
          top: 3px;
          margin-top: -2px;
          left: 26px;
          .cb-glyphs();
          font-size: 0.6875rem;
          font-weight: 700;
          color: @cb-base-font-color;
          &::before{
            display: block;
            content: "\e913";
          }
          &.up {
            &::before{
              content: "\e950";
            }
          }
          &.down {
            &::before{
              content: "\e913";
            }
          }
        }
      }
      &:focus{
        border: 1px solid @input-border-focus;
        border-radius: @cb-form-control-radius;
        outline-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0 0 0 9px;
      }
    }

    .country-list {
      display: none;
      list-style: none;
      position: absolute;
      z-index: 2;
      padding: 10px 0;
      margin: 0 0 0 -1px;
      border: 1px solid @cb-form-input-border-color;
      border-radius: @cb-form-control-radius;
      background-color: @cb-form-control-bk-color;
      .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
      width: 60px;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      left: 1px;
      li{
        height: 25px;
        a {
            opacity: 0.4;
            padding: 5px 10px;
            display: inline-block;
            width: 100%;
            height: 100%;
            &:focus,
            &:hover{
              opacity: 1;
            }
            &:focus {
              .cb-focus();
            }
            &.active{
              opacity: 1;
              background-color: @cb-gray8-color;
            }
          }
        }
      }
    }

  .cb-int-phone{
    position: relative;
    z-index: 0;
    margin: 0;
    padding-left: 55px;
  }

  &.slide-down{
    .cb-int-phone{
      &.active-flag{
        border-bottom-left-radius: 0;
      }
    }
    .country-list {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
  &.slide-up{
    .country-list {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .cb-int-phone{
      &.active-flag{
        border-top-left-radius: 0;
      }
    }
  }
  &.cb-input-focus{
    outline-width: 0;
    .cb-int-phone {
      padding-left: 55px;
      border-color: @input-border-focus;
      border-width: 1px;
    }
    .selected-flag{
      border: 1px solid @gray-corporate-border;
      border-radius: @cb-form-control-radius;
      outline-width: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0 0 0 9px;
      border-right: 1px solid @input-border-focus;
    }
  }
}