//
// Feature Preset
// --------------------------------------------------

// sm: small
// md: medium
// lg: large

// ------------------------------------------ Panel
	.cb-panel {
		padding: 0 0 72px 0;
		&:first-child {
			padding-top: 48px;
		}
		&[class*="cb-panel-grid-"] {
			padding-bottom: 24px;
		}
		@media (max-width: @screen-xs-max) { // mobile
			padding-bottom: 48px;
			&[class*="cb-panel-grid-"] {
				padding-bottom: 0;
			}
		}
		&.cb-panel-gray {
			background-color: @digital-field-gray;
		}
		&.cb-panel-white {
			background-color: @white-color;
		}
	}
	.cb-panel-gray {
		.cb-panel {
			padding-top: 0;
			padding-bottom: 24px;
			@media (max-width: @screen-xs-max) { // mobile
				padding-top: 12px;
				padding-bottom: 12px;
			}
		}
	}
	.cb-panel-title {
		.size23-24();
		.roboto-slab-bold();
		// Program Color
		border-top: 8px solid @blue-corporate-color;
		color: @blue-corporate-color;
		padding: 4px 0 12px 0;
		margin-bottom: 0;
	}
	.cb-panel-desc {
		.size23-24();
		.roboto();
		font-weight: 300;

		// mobile
		@media (max-width: @screen-xs-max) {
			.size19-24();
		}
	}

	.cb-panel-grid-2,
	.cb-panel-grid-3,
	.cb-panel-grid-4 {
		.row {
			display: flex;
    		flex-wrap: wrap;
		}
	}

// ------------------------------------------ Block
	// Normal
	.cb-block-title {
		color: @cb-base-font-color;
		.size19-24();
		.roboto-slab-bold();
		border-top: 1px solid @gray-corporate-border;
		padding-top: 23px;
		margin: 0;
	}
	.cb-block-desc {
		color: @cb-base-font-color;
		.size16-24();
		.roboto();
		margin: 0;
		& + .btn-secondary {
			margin-top: 24px;
		}
	}

	.cb-block-img {
		img {
			width: 100%;
			overflow: hidden;
		}
		margin-bottom: 24px;
		// mobile
		@media (max-width: @screen-xs-max) {
			margin-bottom: 0;
			& + .cb-block-title {
				border-top: 0;
			}
		}
	}

	// Medium
	.cb-md-txt {
		.cb-md-txt();
	}

// ------------------------------------------ Key Benefit
	.cb-key-benefit {
		float: left;
		// default block value
		margin-bottom: 48px;
		.cb-block-img {
			float: left;
			.cb-glyph {
				// Program Color
				color: @cb-base-font-color;
				&.cb-glyph-circular {
					&::after {
						background-color: @cb-base-font-color;
					}
				}
			}
		}
		// always normal txt
		.cb-block-title {
			border-top: 0;
			padding-top: 0;
			float: right;
		}
		.cb-block-desc {
			float: right;
		}
	}
	//  default: medium
	.cb-key-benefit-variations(64px, 16px);
	.cb-key-benefit-panel();
	.cb-key-benefit-size(96px, 24px, lg);
	.cb-key-benefit-size(64px, 16px, md);
	.cb-key-benefit-size(48px, 16px, sm);

// ------------------------------------------ Procedures
	.cb-procedures {
		float: left;
		margin-bottom: 48px;
		.cb-block-img {
			float: left;
			.cb-glyph-numeric {
				// Program Color
				color: @cb-base-font-color;
				border-color: @cb-base-font-color;
			}
		}
		// always normal
		.cb-block-title {
			border-top: 0;
			border-bottom: 1px solid @gray-corporate-border;
			padding-top: 0;
			padding-bottom: 12px;
			margin-bottom: 11px;
			float: right;
		}
		.cb-block-desc {
			float: right;
		}
	}

	//  default: medium
	.cb-procedures-variations(64px, md);

	.cb-procedures-panel ();
	.cb-procedures-size(64px, lg);
	.cb-procedures-size(48px, md);
	.cb-procedures-size(48px, sm);

// ------------------------------------------ Comparison Block
	.cb-comparison-block {
		position: relative;
		margin-bottom: 48px;
		.cb-block-img {
			position: relative;
			margin-bottom: 24px;
		}

		@media (max-width: @screen-xs-max) {
			.cb-block-title {
				padding-top: 0;
				border: 0;
			}
			.cb-block-desc{
				margin-bottom: 24px;
			}
		}
		.cb-comparison-vs {
			font-size: 1.188rem;
		  border-radius: 50%;
		  width: 64px;
		  height: 64px;
		  .roboto-slab-bold();
		  line-height: 64px;
		  text-align: center;
			position: absolute;
			z-index: 2;
		  &::before {
		    content: '-vs-';
		  }
			&.hidden-xs {
				background-color: @white-color;
				// Program Color
				color: @cb-base-font-color;
				top: 50%;
		    transform: translateY(-50%);
				right: -47px; //32+15(grid)
			}
		  &.hidden-sm {
			  width: 48px;
			  height: 48px;
			  line-height: 48px;
			  font-size: @font-size-base;
		    color: @white-color;
		    // Program Color
		    background-color: @cb-base-font-color;
				bottom: -88px; //48 + 16 (border) + 24(margin-bottom)
				left: 50%;
		    transform: translateX(-50%);
		    border: 16px solid @white-color;
		    box-sizing: content-box;
		  }
		}

		// mobile
		@media (max-width: @screen-xs-max) {
			&:not(.cb-comparison-sec) {
				margin-bottom: 0;
			}
			&.cb-comparison-sec{
				// Program Color
				border-top: 2px solid @cb-base-font-color;
				margin-top: 24px;
				padding-top: 56px;
			}
		}
	}

// ------------------------------------------ Testimonial, Full
	blockquote {
		&.cb-testimonial-full,
		&.cb-testimonial-half,
		&.cb-testimonial {
			padding: 0;
			margin: 0;
			border: 0;
			font-size: @font-size-base;
		}
	}
	.cb-testimonial-full {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 100%;
		min-height: 576px;
		display: flex;
		align-items: center;
		margin: 0 -15px 48px -15px;
		.row {
			margin: 0;
		}

		// mobile
		@media (max-width: @screen-xs-max) {
			// min-height: 408px;
			margin: 0 -7.5px 48px -7.5px;
				// height: 408px;
			height: auto;
			min-height: auto;
			.col-xs-12 {
				padding: 48px 24px;
			}
		}
		// Quote Icon
		.cb-block-img {
			float: left;
			margin: 0;
		  .cb-glyph {
		  	font-size: 3rem;
		  	&::after {
				background-color: @white-color;
		  		opacity: 0.6;
		  	}
		  	&::before {
				color: @blue-accent2-corporate-color;
		  	}
		  	//tablet
			@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
				font-size: 2rem;
			}
			//mobile
			@media (max-width: @screen-xs-max) {
		  		font-size: 1.5rem;
		  	}
		  }
		  .cb-glyph-multi {
		  	font-size: 6rem;
			opacity: 0.6;
			background: @white-color;
			&::before,
			&::after {
				color: @blue-accent2-corporate-color;
			}
		  	//tablet
			@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
		  		font-size: 4rem;
			}
			//mobile
			@media (max-width: @screen-xs-max) {
		  		font-size: 3rem;
		  	}
		  }
		}

		// mobile
		@media (max-width: @screen-xs-max) {
			.cb-block-img {
				clear: both;
				// margin-bottom: 24px;
			}
		}
		// Full Quote
		.cb-block-title {
			.size28-48();
			.roboto-slab();
			font-weight: 100;
			color: @white-color;
			border: 0;
			margin-left: 24px;
			margin-bottom: 0;
			padding-top: 0;
			float: left;
			width: calc(100% - 120px); //96 + 24

			// tablet + mobile
			@media (max-width: @screen-sm-max) {
				.size23-24();
			}
			// mobile
			@media (max-width: @screen-xs-max) {
				width: 100%;
				margin-left: 0;
			}
		}
		// Full Citation
		.cb-block-desc {
			.size23-24();
			.roboto-italic();
			color: @white-color;
			margin-top: 48px;
			float: right;
			width: calc(100% - 168px); //96(circle) + 48 + 24
			// tablet + mobile
			@media (max-width: @screen-sm-max) {
				.size19-24();
				margin-top: 24px;
				width: calc(100% - 112px); //64(circle) + 24 + 24
			}

			// mobile
			@media (max-width: @screen-xs-max) {
				width: calc(100% - 24px); //48(circle) + 24 + 24
			}

			// mobile
			// @media (max-width: @screen-xs-max) {
			// 	width: 100%;
			// 	margin-left: 0;
			// }
		}
	}

// ------------------------------------------ Testimonial, Half
	.cb-testimonial-half {
		margin-bottom: 48px;
		.cb-block-img {
			position: relative;
		  img {
		  	z-index: 1;
		  }
		  .cb-glyph {
		  	z-index: 2;
		  	position: absolute;
		  	top: 50%;
		    transform: translateY(-50%);
		    left: calc(50% - 48px);
		  	font-size: 3rem;
			&::after {
				background-color: @white-color;
				opacity: 0.6;
			}
			&::before {
				color: @blue-accent2-corporate-color;
			}
		  }
		  .cb-glyph-multi {
		  	z-index: 2;
		  	position: absolute;
		  	top: 50%;
		    transform: translateY(-50%);
		    left: calc(50% - 48px);
		  	font-size: 6rem;
			opacity: 0.6;
			  
			background: @white-color;
			&::before,
			&::after {
			color: @blue-accent2-corporate-color;
			}
		  }
			// mobile
			@media (max-width: @screen-xs-max) {
				margin-bottom: 24px;
			}
		}
		.cb-block-title {
			.roboto-slab();
			font-weight: 100;
			padding-top: 23px;
			margin-bottom: 24px;
			// oversized
			@media (min-width: @screen-lg-min) {
				.size23-24();
				padding-top: 47px;
			}
		}
		.cb-block-desc {
			.size19-24();
			.roboto-italic();
			color: @cb-base-font-color;
			margin-left: 24px;
		}
	}

// ------------------------------------------ Testimonial
	.cb-testimonial {
		float: left;
		margin-bottom: 48px;
		.cb-block-img {
			float: left;
			.cb-glyph {
				&::after{
					background-color: @cb-base-font-color;
				}
			}
		}
		// always normal
		.cb-block-title {
			.roboto-slab();
			font-weight: 100;
			border-top: 0;
			padding-top: 0;
			float: right;
			margin-bottom: 24px;
		}
		.cb-block-desc {
			float: right;
			.roboto-italic();
		}
	}

	//  default: medium
	.cb-testimonial-variations(48px, 24px);
	.cb-testimonial-panel ();
	.cb-testimonial-size(64px, 24px, lg);
	.cb-testimonial-size(48px, 24px, md);
	.cb-testimonial-size(32px, 16px, sm);

// ------------------------------------------ Media, Full
	.cb-media-full {
		a {
			&:focus {
				.cb-no-focus()
			}
		}
		@media (min-width: @screen-sm-min) { // All breakpoint except mobile
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			margin: 0 -15px 48px -15px;

			.col-sm-12{
				position: relative;
				height: 100%;
				min-height: 576px;
			}
			& > .row {
				margin: 0;
			}
			.cb-block-img {
		  	position: absolute;
		    width: 128px;
		    left: calc(50% - 64px);
		    top: calc(50% - 64px);
				.cb-glyph {
			  		font-size: 4rem;
					&::after{
						// Program Color
						background-color: @blue-corporate-color;
			  			opacity: 0.7;
					}
			  }
			}
			.cb-block-content{
				position: absolute;
				padding: 24px 0;
				bottom: 0;
				left: 0;
			  opacity: 0.9;
				// Program Color
				background-color: @blue-corporate-color;
				.cb-block-title {
					border: 0;
					padding: 0;
					.size36-48 ();
					.roboto-slab();
					color: @white-color;
				}
				.cb-block-desc {
					.size19-24();
					color: @white-color;
				}
			}
		}

		@media (max-width: @screen-xs-max) { // mobile
			background: none !important;
			.cb-block-img {
				top: 0 !important;
				position: relative;
			  img {
			  	z-index: 1;
			  }
			  .cb-glyph {
			  	z-index: 2;
			  	position: absolute;
			  	top: 50%;
			    transform: translateY(-50%);
			    left: calc(50% - 48px);
			  	font-size: 3rem;
			  	&::after {
						// Program Color
						background-color: @blue-corporate-color;
			  		opacity: 0.7;
			  	}
			  }
			}
			.cb-md-txt();

			.cb-block-title {
				margin-bottom: 24px;
			}
		}
	}
	// Accessibility
	[data-cb-input-m="keyboard"] {
		.cb-media-full {
			a {
				&:focus {
					.cb-play {
		    		.cb-focus();
			    }
			  }
	    }
   	}
	}

// ------------------------------------------  Media, Half
	.cb-media-half {
		a {
			&:focus {
				.cb-no-focus()
			}
		}
		.cb-block-img {
			position: relative;
		  img {
		  	z-index: 1;
		  }
		  .cb-glyph {
		  	z-index: 2;
		  	position: absolute;
		  	top: 50%;
		    transform: translateY(-50%);
		    left: calc(50% - 48px);
		  	font-size: 3rem;
		  	&::after {
					// Program Color
					background-color: @blue-corporate-color;
		  		opacity: 0.7;
		  	}
		  }
		}
		.cb-md-txt();

		@media (max-width: @screen-xs-max) {
			.cb-block-title {
				margin-bottom: 24px;
			}
		}
	}
	// Accessibility
	[data-cb-input-m="keyboard"] {
		.cb-media-half {
			a {
				&:focus {
					.cb-play {
		    		.cb-focus();
			    }
			  }
	    }
   	}
	}

// ------------------------------------------  Free Form, Full
	.cb-free-form-full{
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 100%;
		min-height: 576px;
		display: flex;
		align-items: center;
		margin: 0 -15px 48px -15px;
		.row {
			margin: 0;
		}

		// mobile
		@media (max-width: @screen-xs-max) {
	  		min-height: 408px;
		}

		.cb-block-img {
			display: none;
		}
		.cb-block-title {
			.size36-48();
			.roboto-slab();
			border-top: 8px solid @white-color;
			margin: 0;
			padding-top: 12px;
			padding-bottom: 12px;
			color: @white-color;

			// mobile
			@media (max-width: @screen-xs-max) {
				.size23-24();
				border-top: 6px solid @white-color;
			}
		}
		.cb-block-desc {
			.size23-24();
			color: @white-color;
			// mobile
			@media (max-width: @screen-xs-max) {
				.size19-24();
			}
		}
		.btn-secondary {
			border-color: @white-color;
			color: @white-color;
			margin-top: 48px;
		}
	}

// ------------------------------------------  Free Form
	.cb-md-block {
		.cb-free-form {
			.cb-md-txt();
		}
	}
	.cb-free-form {
		float: left;
		margin-bottom: 48px;
		.col-xs-12:not(.col-sm-6) {
			.cb-block-img {
				margin-bottom: 24px;
			}
		}
		@media (max-width: @screen-xs-max) {// mobile
			.cb-block-img {
				margin-bottom: 0 !important;
			}
			.cb-block-title {
				border-top: 0;
				padding-top: 24px;
			}
		}
	}

// ------------------------------------------  Feature List
.cb-feature-list {
	margin-bottom: 48px;
	.cb-block-title {
		.roboto-bold();
		font-size: 1.125rem;
		line-height:  1.3333333333333333em;
		margin: 12px 0 12px 24px;
		font-weight: 400;
		padding: 0;
		border: 0;

  		// mobile
		@media (max-width: @screen-xs-max) {
			margin: 12px 0;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		li {
			padding: 0;
			margin: 0;
			border: 1px solid @cb-base-font-color;
			border-top-width: 0;
			.border-box();
			a {
				display: block;
				position: relative;
				color: @cb-base-font-color;
				font-size: 1.1875rem;
				line-height: 1.263157894736842em;
				padding: 24px 24px 24px 48px;
				&:hover,
				&:focus {
					color: @cb-base-font-color;
				}
				&::after {
					.cb-glyphs();
					font-size: 1rem;
					position: absolute;
					display:  block;
					top: 27px;
					left: 24px;
					color: @cb-base-font-color;
					width: 1rem;
					height: 1rem;
					line-height: 1em;
					content: "\e90b";
				}
			}
			p {
				position: relative;
				font-size: 1.1875rem;
				line-height: 1.263157894736842em;
				padding: 24px 24px 24px 48px;
				margin: 0;
				&::after {
					.cb-glyphs();
					font-size: 1rem;
					position: absolute;
					display:  block;
					top: 27px;
					left: 24px;
					color: @cb-base-font-color;
					width: 1rem;
					height: 1rem;
					line-height: 1em;
					content: "\e90b";
				}
			}
		}
	}
	&.cb-feature-list-no-border {
		ul {
			li {
				border-color: transparent!important;
				border-top-width: 0;
			}
		}
	}
	&.cb-feature-list-row {
		ul {
			li {
				width: 50%;
	
				&:nth-child(1),
				&:nth-child(2)  {
					border-top-width: 1px;
				}
				&:nth-child(even) {
					border-left-width: 0;
				}
				&:nth-last-child(1):nth-child(odd) {
					border-left-width: 1px;
				}
			}
			// mobile
			@media (max-width: @screen-xs-max) {
				flex-direction: column;
				li {
					width: 100%;
					border-width: 1px !important;
					border-top-width: 0 !important;
					
					&:nth-child(1){
						border-top-width: 1px !important;
					}
				}
			}

		}
		
	}
	&.cb-feature-list-column {
		ul {
			flex-direction: column;
			li {
				width: 100%;
	
				&:nth-child(1){
					border-top-width: 1px;
				}
			}
		}
	}
}

// ------------------------------------------  Safari
body[class*=safari] {
	@media (min-width: @screen-sm-min) {
		.cb-free-form{
			.col-sm-6 {
				width: 49%;
			}
		}
		.cb-panel-grid-3,
		.cb-panel-grid-4 {
			.col-sm-6 {
				width: 49%;
			}
		}
	}
	@media (min-width: @screen-md-min) {
		.cb-panel-grid-3,
		.cb-panel-grid-4 {
			.col-md-4 {
				width: 32.33%;
			}
			.col-md-3 {
				width: 24%;
			}
		}
	}
}

// ------------------------------------------  IE 11
.ie11 {
	.cb-testimonial-full {
	  height: 576px;
	  min-height: auto;
  	// mobile
		@media (max-width: @screen-xs-max) {
			  // height: 408px;
			// padding: 48px 24px;
			height: auto;
	 		min-height: auto;
		}
	}
	.cb-media-full {
		@media (min-width: @screen-sm-min) { // All breakpoint except mobile
		  .col-sm-12{
		  	position: relative;
		  	height: 576px;
		  	min-height: auto;
		  }
		}
	}
	.cb-free-form-full{
	  height: 576px;
	  min-height: auto;
		// mobile
		@media (max-width: @screen-xs-max) {
	  	height: 408px;
	  	min-height: auto;
		}
	}
	.cb-free-form {
		float: none;
	}
}

// ------------------------------------------  reset padding for Blocks
.cb-panel {
	.cb-comparison-block,
	.cb-testimonial-full,
	.cb-testimonial-half,
	.cb-media-full,
	.cb-media-half,
	.cb-free-form-full{
		margin-bottom: 0;
	}
	&:not([class*="cb-panel-grid-"]){
		.cb-free-form {
			margin-bottom: 0;
		}
	}
}