//
// Headline Element
//
// --------------------------------------------------

.cb-headline-element {
  h2, h3 {
    float: left;
    font-size: 1.188rem;
    line-height: 1.263157894736842em;
    margin: 0;
    padding: 0;
  }
  a{
    text-transform: uppercase;
    float: left;
    margin-left: 18px;
    font-size: 0.5rem;
    line-height: 3em;
  }
  margin-bottom: 16px;
  width: 100%;
  display: inline-block;
}
