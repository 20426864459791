//
// Event Cards
//
// --------------------------------------------------

// --------------------------------  Card mixins
.vertical-card() {
    .cb-event-title {
        border-top: 1px solid @gray80-color;
        &::before {
            color: @cb-base-font-color;
            background: @white-color;
            font-size: 1rem;
            content: "\e90c";
            border-radius: 50%;
            left: -34px;
            top: 27px;
        }
        &::after{
            display: none;
            height: 0;
            width: 0;
        }
    }
    .cb-event-cards-past {
        .cb-event-title {
            &::before {
                content: "\e95d";
                color: @cb-base-font-color;
                background: @white-color;
                left: -34px;
                top: 27px;
            }
        }
    }
    .cb-event-date {
        text-align: right;
        margin-top: 27px;
    }
    .cb-event-year:not(.hidden) {
        text-align: right;
        &+.cb-event-date {
            margin-top: 3px;
        }
    }
    .cb-event-meta {
        float: right;
        &.cb-multi {
            li {
                float: right;
                width: 100%;
                text-align: right;
            }
        }
    }
    .cb-event-action {
        margin: 12px 0 0 0;
    }

    &>ul,
    &>ol {
        display: block;
        &>li {
            display: flex;
            flex-direction: row;
            width: 100%;
            &.cb-event-key {
                position: relative;
                &::after {
                    content: 'Key Event';
                    font-size: 0.75rem;
                    line-height: 2em;
                    background: @white-color;
                    box-shadow: 0px 0px 24px rgba(0, 0, 0, .05);
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: 0 8px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                &.cb-digital-gray-bg {
                    &::after {
                        background: @cb-base-font-color;
                        color: @white-color;
                    }
                }
                .cb-event-year {
                    margin-top: 24px;
                }
                .cb-event-title {
                    border-top: 0;
                    padding-top: 24px;
                }
                &+ li {
                    .cb-event-title {
                        border-top: 0;
                        padding-top: 24px;
                    }
                    .cb-event-year {
                        margin-top: 24px;
                    }
                } 
            }
            .cb-event-date-info {
                width: 30%;
                padding-left: 0;
                padding-right: 24px;
                margin-bottom: 24px;
                display: block;
            }
            .cb-event-info {
                width: 70%;
                padding: 0 24px 24px 24px;
                border-top: 0;
                border-left: 4px solid @gray80-color;
                margin-top: 0;
            }
            .cb-event-title {
                padding-top: 23px;
            }
            &:nth-of-type(1) {
                .cb-event-title {
                    border-top: 0;
                    padding-top: 24px;
                }
            }
        }
    }
}

.glyph() {
    position: absolute;
    .cb-glyphs();
    display:  block;
    width: 1em;
    height: 1em;
    line-height: 1em;
}

// Program Color
.programColor (@class, @accent) {
    .@{class} {
        
        .cb-event-timeline {
            &.cb-event-cards-vertical {
                &:not(.cb-no-accent) {
                    .cb-event-info {
                        background-color: @accent;
                    }
                }

                // Key Event Updates
                &>ul,
                &>ol {
                    &>li {
                        &.cb-event-key {
                            // background: @accent;
                            background: rgba(255, 255, 255, .125);
                            &::after {
                                background: @white-color;
                                color: @accent;
                            }
                        }
                    }
                }

            }
            @media (max-width: @screen-xs-max) { // mobile
                &.cb-event-cards-horizontal {
                    &:not(.cb-no-accent) {
                        .cb-event-info {
                            background-color: @accent;
                        }
                    }
                }
            }
        }
    }
}

// Glyph colors
.glyphColor (@class, @color) {
    .@{class} {
        .cb-event-cards {
            &.cb-event-timeline {
                &.cb-event-cards-vertical {
                    .cb-event-title {
                        &::before{
                            background: @color;
                        }
                    }
                }
                @media (max-width: @screen-xs-max) { // mobile
                    &.cb-event-cards-horizontal {
                        .cb-event-title {
                            &::before{
                                background: @color;
                            }
                        }
                    }
                }
            }
        }
    }
}

// --------------------------------------------------
.cb-event-cards { 
    ul,
    ol {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            ul,
            ol {
                list-style: none;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
    .cb-event-title {
        .roboto-slab-bold();
        font-size: 1.1875rem;
        line-height: 1.263157894736842em;
        padding-top: 24px;
        margin: 0;
        position: relative;
        
        &::before {
            .glyph();
            bottom: 0;
            right: 0;
            color: @gray80-color;
        }
    }
    .cb-event-desc {
        .roboto();
        font-weight: 200;
        padding: 0;
        margin: 0;
    }
    .cb-event-date {
        .roboto();
        font-weight: 400;
        line-height: 1.125em;
    }
    .cb-event-year {
        .roboto();
        font-weight: 900;
        font-size: 1.75rem;
        line-height: 0.8571428571428571em;
    }
    .cb-event-meta {
        .roboto();
        font-weight: 200;
        line-height: 1.125em;
        &:not(.cb-multi) {
            li {
                float: left;
                &:nth-of-type(1) {
                    padding-right: 6px;
                }
                &:nth-of-type(2) {
                    position: relative;
                    padding-left: 6px;
                    &::before {
                        content: "/";
                        position: absolute;
                        left: -3px;
                    }
                }
            }
        }
    }
    .cb-event-action {
        float: left;
        li {
            float: left;
            a {
                color: @cb-base-font-color;
                &:hover,
                &:focus {
                    color: @cb-base-font-color;
                }
                &.cb-event-cal {
                    padding-left: 24px;
                    position: relative;
                    &::before {
                        .glyph();
                        top: 1px;
                        content: "\e907";
                    }
                }

                &.cb-event-more {
                    padding-right: 24px;
                    position: relative;
                    &::after {
                        .glyph();
                        top: 2px;
                        right: 0;
                        content: "\e916";
                    }
                }
            }
            &:nth-of-type(1) {
                a {
                    margin-right: 16px;
                }
            }
            &:nth-of-type(2) {
                a {
                    padding-left: 16px;
                    border-left: 1px solid @cb-base-font-color;
                }
            }
        }
        &.cb-multi {
            li {
                &:nth-of-type(2) {
                    a {
                        padding-left: 0;
                        border-left: 0;
                    }
                }
            }
        }
    }

    // -------------------- timeline
    &.cb-event-timeline {
        // --------------------------------  Horizontal
        &.cb-event-cards-horizontal {
            .cb-event-title {
                padding-top: 21px;
                &::before {
                    font-size: 1rem;
                    content: "\e958";
                    left: -32px;
                    top: -32px;
                }
                &::after {
                    display: block;
                    border-left: 1px solid @gray80-color;
                    height: 12px;
                    width: 1px;
                    position: absolute;
                    left: -25px;
                    top: -15px;
                    content: " ";
                }
            }
            .cb-event-cards-past {
                .cb-event-title {
                    &::before {
                        color: @gray80-color;
                        content: "\e95d";
                        left: -32px;
                        top: -32px;
                    }
                }
            }
            .cb-event-meta {
                margin-top: auto;
                li {
                    float: left;
                }
            }
    
            .cb-event-date {
                margin-top: auto;
                &+.cb-event-meta {
                    margin-top: 0;
                }
            }
            .cb-event-year:not(.hidden) {
                margin-top: auto;
                &+.cb-event-meta  {
                    margin-top: 0;
                }
            }
            .cb-event-action {
                padding-top: 12px;
                float: left;
            }
        
            &>ul,
            &>ol {
                display: flex;
                &>li {
                    float: left;
                    width: 33.3333%;
                    display: flex;
                    flex-direction: column;
            
                    .cb-event-date-info {
                        padding-left: 16%;
                        display: flex;
                        flex-direction: column;
    
                    }
                    .cb-event-info {
                        padding: 0 24px 24px 16%;
                        border-top: 4px solid @gray80-color;
                        margin-top: 12px;
                    }
                }
            }
            @media (min-width: @screen-sm-min) { // everything except mobile
                &>ul,
                &>ol {
                    &>li {
                        .cb-event-info {
                            background: transparent!important;
                        }
                    }
                }
            }
            @media (max-width: @screen-xs-max) { // mobile
                .vertical-card();
            }
        }
    
        // --------------------------------  Vertical
        &.cb-event-cards-vertical {
            .vertical-card();
        }
    }

    // -------------------- Non-timeline
    &.cb-event-non-timeline {
		& > ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 24px;
			& > li {
                border: 1px solid @gray-corporate-border;
                .border-box();
                background: @white-color;
            }
        }

        // None flex width
        &:not(.cb-layout-grid ) {
            & > ul {
                & > li {
                    margin: 0 15px 24px;
                    // desktop, 3
                    width: calc(33.333% - 30px);
    
                    // oversized, 4
                    @media (min-width: @screen-lg-min) {
                        width: calc(25% - 30px);
                    }
                    // tablet, 2
                    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
                        width: calc(50% - 20px);
                        margin: 0 10px 24px;
                    }
                    // mobile, 1
                    @media (max-width: @screen-xs-max) {
                        width: calc(100% - 15px);
                        margin: 0 7.5px 24px;
                    }
                }
            }

        }

        &.cb-layout-grid {
            & > ul {
                justify-content: flex-start;
                & > li {
                    border: solid 15px transparent;
                    box-sizing: border-box;
                    position: relative;
                    &::before {
                        content: '';
                        border: 1px solid @gray-corporate-border;
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    // tablet
                    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
                        border: solid 10px transparent;
                        margin: 0;
                    }
                    // mobile
                    @media (max-width: @screen-xs-max) {
                        border: solid 7.5px transparent;
                        margin: 0;
                    }
                    border-bottom-width: 12px!important;
                    border-top-width: 12px!important;
                }
            }
        }
        .cb-event-date-info {
            padding: 23px 23px 0 23px;
            line-height: 2em;
            .cb-glyph {
                float: left;
                &.cb-glyph-circular{
                    &::after {
                        background-color: @gray1-corporate-color;
                    }
                }
            }
            .cb-event-date {
                line-height: 1.5em;
                font-weight: 200;
                margin-left: 40px; //32+8
            }
        }
        .cb-event-info {
            padding: 20px 24px 24px 24px;
            .cb-event-title {
                padding-top: 0;
            }
        }
        .cb-event-action {
            padding: 0 24px 24px 24px;
            font-size: 0.875rem;
            line-height: 1.7142857142857142em;
            li {
                &:nth-of-type(1) a {
                    margin-right: 12px;
                }
                &:nth-of-type(2) a {
                    padding-left: 12px;
                }
            }
            .cb-event-cal {
                padding-left: 22px;
            }
            .cb-event-more{
                padding-right: 22px;
            }
            &.cb-multi {
                li {
                    &:nth-of-type(2) {
                        margin-top: 12px;
                    }
                }
            }
        }
    }
}


// ------------------------------------------  Safari
body[class*=safari] {
    // mobile
	@media (max-width: @screen-xs-max) {
        .cb-event-non-timeline  {
            &.cb-layout-grid{
                .col-xs-6 {
                    width: 49%;
                }
            }
        }
    }
    // tablet
	@media (min-width: @screen-sm-min) {
        .cb-event-non-timeline  {
            &.cb-layout-grid{
                .col-sm-6 {
                    width: 49%;
                }
                .col-sm-4 {
                    width: 32.33333333%;
                }
                .col-sm-3 {
                    width: 24%;
                }
                .col-sm-2 {
                    width: 15.66666667%;
                }
            }
        }
    }
    // desktop
	@media (min-width: @screen-md-min) {
        .cb-event-non-timeline  {
            &.cb-layout-grid{
                .col-md-6 {
                  width: 49%;
                }
                .col-md-4 {
                    width: 32.33%;
                }
                .col-md-3 {
                    width: 24%;
                }
            }
		}
    }
    
	// oversized
	@media (min-width: @screen-lg-min) {
        .cb-event-non-timeline  {
            &.cb-layout-grid{
                .col-lg-6 {
                  width: 49%;
                }
                .col-lg-4 {
                    width: 32.33%;
                }
                .col-lg-3 {
                    width: 24%;
                }
            }
		}
    }
}

.cb-white-color {
    .cb-event-cards {
        &.cb-event-timeline {
            .cb-event-action {
                a {
                    color: @white-color;
                    &:hover,
                    &:focus {
                        color: @white-color;
                    }
                }
                &:not(.cb-multi) {
                    li {
                        &:nth-of-type(2) {
                            a {
                                border-left: 1px solid @white-color;
                            }
                        }
                    }
                }
            }
            &.cb-event-cards-vertical {
                .cb-event-title {
                    &::before {
                        color: @gray80-color;
                        background: @cb-base-font-color;
                    }
                }
            }
            &.cb-event-cards-horizontal {
                .cb-event-title {
                    &::before {
                        color: @gray80-color;
                    }
                }
        
                @media (max-width: @screen-xs-max) { // mobile
                    .cb-event-title {
                        &::before {
                            color: @gray80-color;
                            background: @cb-base-font-color;
                        }
                    }
                }
            }
        }
        &.cb-event-non-timeline {
            * {
                color: @cb-base-font-color!important;
            }
        }
    }
}

// Glyph color
.glyphColor(cb-gray1-corporate-bg, @gray1-corporate-color);
.glyphColor(cb-blue-corporate-bg, @blue-corporate-color);
.glyphColor(cb-accent1-blue-corporate-bg, @blue-accent1-corporate-color);
.glyphColor(k-12-program-bg, @blue-accent1-corporate-color);
.glyphColor(cb-k-12-program-bg, @blue-accent1-corporate-color);
.glyphColor(higher-education-program-bg, @higher-education-color);
.glyphColor(cb-higher-education-program-bg, @higher-education-color);
.glyphColor(access-program-bg, @access-color);
.glyphColor(cb-access-program-bg, @access-color);

// Program accent
.programColor(cb-blue-corporate-bg, @blue-accent1-corporate-color);

.programColor(higher-education-program-bg, @higher-education-accent-color);
.programColor(cb-higher-education-program-bg, @higher-education-accent-color);

.programColor(access-program-bg, @access-accent-color);
.programColor(cb-access-program-bg, @access-accent-color);

// K-12
.programColor(cb-accent1-blue-corporate-bg, @k-12-color);
