//
// CB Series Toggle
// --------------------------------------------------

.cb-series-toggle {

  &.dropdown {
    background-color: @gray1-corporate-color;
    height: 36px;
    padding: 6px 16px;
    margin-bottom: 12px;

    .dropdown-toggle {
      color: @white-color;
      &:hover,
      &:active,
      &:focus  {
        color: @white-color;
        border-bottom: 3px solid @white-color;
      }

      &.cb-selected-plan {
        i:first-of-type {
          display: inline-block;
        }
        i:last-of-type {
          display: none;
        }
      }
    }

    &.open {
      .dropdown-toggle.cb-selected-plan {
        i:first-of-type {
          display: none;
        }
        i:last-of-type {
          display: inline-block;
        }
      }
    }
  }
}
