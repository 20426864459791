//
// CB Buttons
// --------------------------------------------------

.btn {
  line-height: 24px;
  .roboto-slab-bold();
  border-radius: 0;
  .font-smoothing();
  .border-box();
  .box-shadow(none);
}

.btn-lg{
  .cb-button-size(@cb-btn-padding-large-vertical, @cb-btn-padding-large-horizontal, @cb-btn-font-size-large);
  &.btn-primary {
    padding-top: @cb-btn-primary-padding-large-vertical;
    padding-bottom: @cb-btn-primary-padding-large-vertical;
  }
}

.btn-sm {
  .cb-button-size(@cb-btn-padding-small-vertical, @cb-btn-padding-small-horizontal,  @cb-btn-font-size-small);
}

.btn-xs {
  .cb-button-size(@cb-btn-padding-xsmall-vertical, @cb-btn-padding-xsmall-horizontal,  @cb-btn-font-size-small);
  line-height: 18px !important;
}

.btn-primary {
  background-color: @cb-btn-primary-bk-color;
  color: @cb-btn-primary-font-color;
  .cb-disabled-button(@cb-btn-primary-disabled-bk-color, @cb-btn-primary-disabled-border-color,@cb-btn-primary-disabled-font-color);
  .cb-hover-button(@cb-btn-primary-hover-bk-color,@cb-btn-primary-hover-border-color,@cb-btn-primary-hover-font-color);
}

.btn-secondary{
  color: @black-corporate-color;
  background-color: transparent;
  border-color: @black-corporate-color;
  &.btn-lg {
    @vertical-large: calc(@cb-btn-padding-large-vertical - 1px);
    @horizontal-large: calc(@cb-btn-padding-large-horizontal - 1px);
    .cb-button-size(@vertical-large, @horizontal-large, @cb-btn-font-size-large);
  }
  &.btn-sm {
    @vertical-small: calc(@cb-btn-padding-small-vertical - 1px);
    @horizontal-small: calc(@cb-btn-padding-small-horizontal - 1px);
    .cb-button-size(@vertical-small, @horizontal-small, @cb-btn-font-size-small);
  }
  .cb-hover-button(transparent, @black-corporate-color, @black-corporate-color);
  .cb-disabled-button(transform, @cb-gray-light-border-color, @cb-btn-primary-disabled-font-color);
  .cb-focus-button();
}

.btn-secondary-light{
  color: @white-color;
  background-color: transparent;
  border-color: @white-color;
  &.btn-lg {
    @vertical-large: calc(@cb-btn-padding-large-vertical - 1px);
    @horizontal-large: calc(@cb-btn-padding-large-horizontal - 1px);
    .cb-button-size(@vertical-large, @horizontal-large, @cb-btn-font-size-large);
  }
  &.btn-sm {
    @vertical-small: calc(@cb-btn-padding-small-vertical - 1px);
    @horizontal-small: calc(@cb-btn-padding-small-horizontal - 1px);
    .cb-button-size(@vertical-small, @horizontal-small, @cb-btn-font-size-small);
  }
  .cb-hover-button(transparent, @white-color, @white-color);
  .cb-disabled-button(transform, @cb-gray-light-border-color, @cb-btn-primary-disabled-font-color);
  .cb-focus-button();
}

.btn-secondary-blue{
  color: @white-color;
  background-color: @cb-btn-blue;
  border-color: @cb-btn-blue;
  &.btn-lg {
    @vertical-large: calc(@cb-btn-padding-large-vertical - 1px);
    @horizontal-large: calc(@cb-btn-padding-large-horizontal - 1px);
    .cb-button-size(@vertical-large, @horizontal-large, @cb-btn-font-size-large);
  }
  &.btn-sm {
    @vertical-small: calc(@cb-btn-padding-small-vertical - 1px);
    @horizontal-small: calc(@cb-btn-padding-small-horizontal - 1px);
    .cb-button-size(@vertical-small, @horizontal-small, @cb-btn-font-size-small);
  }
  .cb-hover-button(@cb-btn-blue, @cb-btn-blue, @white-color);
  .cb-disabled-button(@cb-btn-primary-disabled-bk-color, @cb-btn-primary-disabled-border-color,@cb-btn-primary-disabled-font-color);
  .cb-focus-button();
}
.btn-secondary-group {
  .btn-secondary {
    border-color: transparent !important;
    color: @cb-blue-link-color;
    padding-right: 0;
    padding-left: 0;
  }
}

.btn {
  .cb-glyph {
    font-size: @font-size-small;
  }
  &.btn-lg {
    .cb-glyph {
      font-size: @font-size-large;
    }
  }
}

fieldset[disabled]{
  .btn-primary {
    background-image:none;
    color: @cb-btn-primary-disabled-font-color;
    background-color: @cb-btn-primary-disabled-bk-color;
    border-color: @cb-btn-primary-disabled-border-color;
    .box-shadow(none);
    background-image:none;
    text-shadow: none;
  }
}

.btn-primary{
  &.btn{
    border: 0;
  }
  .cb-focus-button();
}

.btn-default{
  color: @cb-btn-default-font-color;
  line-height: 22px;
  background-color: @cb-btn-default-bk-color;
  border-color: @cb-btn-default-border-color;
  transition: background-color 300ms ease-out;
  .cb-hover-button(@cb-btn-default-hover-disabled-bk-color, @cb-btn-default-hover-disabled-bk-color, @cb-btn-default-hover-disabled-font-color);
  .cb-disabled-button(transform, @cb-gray-light-border-color, @cb-btn-primary-disabled-font-color);
  .cb-focus-button();
}

.btn-tag{
  .roboto-slab();
  color: @cb-btn-tags-font-color;
  line-height: 22px;
  background-color: @cb-btn-tags-bk-color;
  border-color: @cb-btn-tags-border-color;
  transition: background-color 300ms ease-out;
  .cb-hover-button(@cb-btn-tags-hover-disabled-bk-color, @cb-btn-tags-border-color, @cb-btn-tags-hover-disabled-font-color);
  .cb-disabled-button(transform, @cb-gray-light-border-color, @cb-btn-primary-disabled-font-color);
  .cb-focus-button();
}

.btn-minimal{
  color: @black-corporate-color;
  background-color: transparent;
  border-color: @black-corporate-color;
  &.btn-lg {
    @vertical-large: calc(@cb-btn-padding-large-vertical - 1px);
    @horizontal-large: calc(@cb-btn-padding-large-horizontal - 1px);
    .cb-button-size(@vertical-large, @horizontal-large, @cb-btn-font-size-large);
  }
  &.btn-sm {
    @vertical-small: calc(@cb-btn-padding-small-vertical - 1px);
    @horizontal-small: calc(@cb-btn-padding-small-horizontal - 1px);
    .cb-button-size(@vertical-small, @horizontal-small, @cb-btn-font-size-small);
  }
  .cb-hover-button(transparent, @black-corporate-color, @black-corporate-color);
  .cb-disabled-button(transform, @cb-gray-light-border-color, @cb-btn-primary-disabled-font-color);
  .cb-focus-button();
}

.btn-light-minimal{
  color: @white-color;
  background-color: transparent;
  border-color: @white-color;
  &.btn-lg {
    @vertical-large: calc(@cb-btn-padding-large-vertical - 1px);
    @horizontal-large: calc(@cb-btn-padding-large-horizontal - 1px);
    .cb-button-size(@vertical-large, @horizontal-large, @cb-btn-font-size-large);
  }
  &.btn-sm {
    @vertical-small: calc(@cb-btn-padding-small-vertical - 1px);
    @horizontal-small: calc(@cb-btn-padding-small-horizontal - 1px);
    .cb-button-size(@vertical-small, @horizontal-small, @cb-btn-font-size-small);
  }
  .cb-hover-button(transparent, @white-color, @white-color);
  .cb-disabled-button(transform, @cb-gray-light-border-color, @cb-btn-primary-disabled-font-color);
  .cb-focus-button();
}

.btn {
  &:hover {
    text-decoration: underline;
  }
  &.cb-btn-fluid{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  // mobile
  @media (max-width: @screen-xs-max) {
    .cb-btn-fluid (xs);
  }

  // Tablet
  @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
    .cb-btn-fluid (sm);
  }

  // desktop
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .cb-btn-fluid (md);
  }

  // oversized
  @media (min-width: @screen-lg-min) {
    .cb-btn-fluid (lg);
  }
}

.cb-primary-secondary {
  .btn {
    line-height: 24px !important;
  }
}

// ---------- toggle button

.cb-toggle-btn {
  display: inline-block;
  font-size: 0;
  .radio-inline {
    padding: 0;
    margin: 0;
    width: 48px;
    height: 48px;
    .border-box();
    input[type="radio"] {
      margin: 0;
      +span:not(.cb-glyph) {
        position: absolute;
        top: 0;
        z-index: 2;
        background-color: @white-color;
        border: 1px solid @black-corporate-color;
        border-right-width: 0;
        margin: 0;
        display: block;
        width: 48px;
        height: 48px;
        font-size: 0;
        &+.cb-glyph {
          font-size: @font-size-base;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 3;
          transform: translate(-50%, -50%);
        }
      }
      &:hover,
      &:focus{
        outline: 0;
        &+ span:not(.cb-glyph) {
          background-color: @cb-btn-blue-rollover;
          color: @white-color;
          +.cb-glyph {
            color: @white-color;
          }
          &:after {
            border: 0!important;
          }
        }
      }
      &:checked,
      &:active,
      &.checked {
        outline: 0;
        &+ span:not(.cb-glyph) {
          background-color: @cb-btn-blue;
          color: @white-color;
          +.cb-glyph {
            color: @white-color;
          }
        }
      }

      // matching secondary disabled button style
      &[disabled],
      &.disabled,
      fieldset[disabled] & {
        &+ span:not(.cb-glyph) {
          background-color: @cb-btn-primary-disabled-bk-color;
          border-color: @cb-gray-light-border-color,;
          color: @cb-btn-primary-disabled-font-color;
          +.cb-glyph {
            color: @cb-btn-primary-disabled-font-color;
          }
        }
      }
    }
    &+.radio-inline {
      margin: 0;
    }
    &:last-of-type {
      input[type="radio"] {
        &+span:not(.cb-glyph) {
          border-right-width: 1px;
        }
      }
    }
    span {
      &:last-of-type:not(.cb-glyph) {
        .roboto-bold();
        font-size: 0.75rem !important;
        line-height: 4em;
        text-align: center;
        &::before {
          content: "";
        }
      }
    }
  }
}


// ---------- Bootstrap adjustments
.btn-group > .btn{
  &.btn-primary {
    &+.btn-primary {
      border-left: 1px solid @white-color;
      margin-left: 0;
    }
  }
}