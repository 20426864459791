//
// Media Object
// --------------------------------------------------

.media,
.media-list {
	border: 0;
	border-top: 4px solid @cb-base-font-color;
  .border-box();
  padding: 20px 0 24px;
	.cb-media-icon{
	  display: table-cell;
	  vertical-align: top;
	  a {
		  text-align: center;
		  background-color: @cb-gray7-color;
		  display: block;
		  position: relative;
	  }
	}
	.media-heading{
		.roboto-bold();
		color: @cb-base-font-color;
		margin-bottom: 0;
	}
	p{
		.roboto();
		font-size: @font-size-small;
		line-height: @line-height-small-base;
		color: @cb-base-font-color;
		margin-bottom: 0;
	}
	.cb-media-content{
		.roboto();
		font-size: 0.75rem;
		line-height: 2em;
		* {
			.roboto();
			font-size: 0.75rem;
			line-height: 2em;
		}
	}
	&.cb-media-sm{
		.cb-media-icon{
			padding-top: 4px;
			padding-right: 12px;
			a {
				width: 16px;
				height: 16px;
			}
		}
	}
	&.cb-media-md{
		.cb-media-icon{
			padding-right: 16px;
			a {
				width: 32px;
				height: 32px;
			}
		}
	}
	&.cb-media-lg{
		padding: 20px 0 24px;
		.cb-media-icon{
			padding-right: 16px;
			a {
				width: 64px;
				height: 64px;
			}
		}
	}
}
.media{
	margin-top: 0;
	&.cb-media-resources {
		border-top: 4px solid @blue-corporate-color;
		.media-heading {
			color: @cb-blue-link-color;
			display: block;
			&:hover{
				color: @cb-blue-link-color;
			}
		}
		.cb-media-info{
			display: inline-block;
			span{
				.roboto-bold();
				&:nth-child(2) {
					&::before {
						content: '';
						border-left: 1px solid @cb-base-font-color;
						padding-right: 5px;
					}
				}
			}
		}
	}
}
.media-list{
	padding: 0 !important;
	.media{
		margin-top: 0;
		border-top: 0;
    border-bottom: 1px solid @cb-gray7-color;
  	.border-box();
		&:last-child {
		  border-bottom: 0;
		}
	}
}