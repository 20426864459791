//
// Bootstrap Variables
// --------------------------------------------------

@cb-image-path-aws: '//mango.collegeboard.org/cbmango1/prod/ui/all/1/';
@cb-image-path: '../images/';
@cb-font-path: '../fonts/';

//== override the font file name from bootstrap
@icon-font-name: '@{gv-font-glyphicons-halflings-regular}';
// @cb-icons-font-svg-id: 'cb_icons';
// @cb-icons-multi-font-svg-id: 'cb_icons_multi';
// @cb-icons-logo-font-svg-id: 'cb_icons_logo';

//== Media queries breakpoints

//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs: 480px;

//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min: @screen-xs;

//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone: @screen-xs-min;

// Small screen / tablet

//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm: 768px;

@screen-sm-min: @screen-sm;

//** Deprecated `@screen-tablet` as of v3.0.1

@screen-tablet: @screen-sm-min;

// Medium screen / desktop

//** Deprecated `@screen-md` as of v3.0.1

@screen-md: 1024px;

@screen-md-min: @screen-md;

//** Deprecated `@screen-desktop` as of v3.0.1

@screen-desktop: @screen-md-min;

// Large screen / wide desktop

//** Deprecated `@screen-lg` as of v3.0.1

@screen-lg: 1250px;

@screen-lg-min: @screen-lg;

//** Deprecated `@screen-lg-desktop` as of v3.0.1

@screen-lg-desktop: @screen-lg-min;

// So media queries don't overlap when required, provide a maximum

@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);


//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width: @cb-grid-gutter-width-xs;


//== Container sizes
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
@container-tablet: 752px;

//** For `@screen-sm-min` and up.
@container-sm: @container-tablet;

// Medium screen / desktop
@container-desktop: 990px;

//** For `@screen-md-min` and up.
@container-md: @container-desktop;

// Large screen / wide desktop
@container-large-desktop: 1206px;

//** For `@screen-lg-min` and up.
@container-lg: @container-large-desktop;


//color Palette
@link-hover-color: @cb-blue-link-color;//@cb-blue-dark-color;


// ---------

@popover-border-color: @gray-corporate-border;
@popover-title-bg: @digital-field-gray;
@state-success-border: #3c763d;

//Fonts
@text-color: @cb-base-font-color;
@input-color: @cb-base-font-color;
@input-color-placeholder: #737373;

@font-size-base: 1rem; //16px;
@font-size-small: 0.875rem; //14px;
@font-size-large: 1.125rem; //18px

//** Unit-less `line-height` for use in components like buttons.

@line-height-base: 1.5em; // 16/24px
@line-height-small: 1.286em; // 14/18px
@line-height-small-base: 1.7142857142857142em; // 14/24px
@line-height-large-base: 1.3333333333333333em;


//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed: unit((@line-height-base), em);



@font-size-popOver: 0.875rem;
@font-size-tooltip: 0.875rem;


//** By default, this inherits from the `<body>`.

@headings-font-family: inherit;
@headings-font-weight: 500;
@headings-line-height: 1.1;
@headings-color: inherit;

//Buttons
@btn-font-weight: 400;

//Forms

//** Border color for inputs on focus
@input-border-focus: @blue-accent1-corporate-color;
@input-border-radius: 0;

@state-danger-text: @cb-red-color;
@state-danger-bg: @cb-form-control-bk-color;
@state-danger-border: @cb-red-color;

@state-warning-text: @cb-orange-color;
@state-warning-bg: @cb-form-control-bk-color;
@state-warning-border:@cb-orange-color;

//----------------
// used in bootstrap
@link-color: @cb-blue-link-color;

// font family variables (not including font-face)

@font-family-base: 	@font-family-Roboto;

@font-size-h1: 1.75rem;
@font-size-h2: 1.4375rem;
@font-size-h3: 1.1875rem;
@font-size-h4: 1rem;
@font-size-h5: 1rem;
@font-size-h6: 0.875rem;


@tooltip-bg: @cb-blue-link-color;
@tooltip-opacity: 1;


@table-border-color: @gray-corporate-border;
// @table-border-color: @gray-corporate-border;

//Background color used for `.table-striped`.
@table-bg-accent: @digital-field-gray;

@table-cell-padding : 10px;

//Pagination
@pagination-active-bg: @cb-blue-link-color;
@pagination-color: @cb-blue-link-color;


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

@alert-font-size: 0.875rem;
@alert-success-text:             @cb-base-font-color;
@alert-success-bg:               @white-color;
@alert-success-border:           #1d9582;
@alert-success-header:         	 @alert-success-border;
@alert-success-glyph:          	 "\e90b";

@alert-warning-text:             @cb-base-font-color;
@alert-warning-bg:               @white-color;
@alert-warning-border:           @cb-orange-dark-color;
@alert-warning-header:         	 @cb-base-font-color;
@alert-warning-glyph:          		"\e919";

@alert-danger-text:              @cb-red-color;
@alert-danger-bg:                @white-color;
@alert-danger-border:            @cb-red-color;
@alert-danger-header:          	 @cb-red-color;
@alert-danger-glyph:          	 "\e918";

// List group
@list-group-border-radius: 0;
@list-group-border: @gray-corporate-border;
@list-group-link-color: @cb-base-font-color;
@list-group-link-bk: @digital-field-gray;
@list-group-link-color: @cb-base-font-color;

@badge-bg: @blue-accent1-corporate-color;

// Panels
@panel-default-border: @gray-corporate-border;
@panel-inner-border: @gray-corporate-border;
@panel-border-radius: 0;

@panel-heading-padding: 15px;
@panel-heading-bg: @digital-field-gray;
@panel-footer-bg: @digital-field-gray;

//Nav
@nav-tabs-active-link-hover-color: @gray1-corporate-color;

// Modal
@modal-content-border-color: rgba(0,0,0,.1);
//** Modal content border color **for IE8**
@modal-content-fallback-border-color: @gray80-color;

@modal-spacing: 30px;
@modal-spacing-mobile: 15px;

@modal-inner-padding-header: @modal-spacing @modal-spacing calc(@modal-spacing/2) @modal-spacing;
@modal-inner-padding-body: calc(@modal-spacing/2) @modal-spacing 0 @modal-spacing;
@modal-inner-padding-footer: @modal-spacing;

@modal-inner-padding-header-mobile: @modal-spacing-mobile @modal-spacing-mobile 0 @modal-spacing-mobile;
@modal-inner-padding-body-mobile: @modal-spacing-mobile @modal-spacing-mobile 0 @modal-spacing-mobile;
@modal-inner-padding-footer-mobile: @modal-spacing-mobile;

// dropdon
@zindex-dropdown: 899;