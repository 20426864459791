//
// Modals
// --------------------------------------------------


.modal-content {
  border-radius: 0;
  .cb-shadow();
  .modal-header {
  	border-bottom: 0;
    padding: @modal-inner-padding-header;
    min-height: 30px;
    .h2, h3, h4, h5{
      .roboto-bold();
    }

    @media (max-width: @screen-sm-min) {
      padding: @modal-inner-padding-header-mobile;
    }
    .close {
      margin-top: -9px;
      color: @gray1-corporate-color;
      opacity: 1;
      &:hover,
      &:focus {
        color: @cb-base-font-color;
        opacity: 1;
      }
    }
  }
  .modal-body {
    padding: @modal-inner-padding-body;

    @media (max-width: @screen-sm-min) {
      padding: @modal-inner-padding-body-mobile;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
  .modal-footer {
    border-top: 0;
    padding: @modal-inner-padding-footer;

    @media (max-width: @screen-sm-min) {
      padding: @modal-inner-padding-footer-mobile;
    }
    .btn{
      margin-top: 0;
      &.btn-sm{
        line-height: 18px;
      }
    }
  }
  &.cb-no-header{
    .modal-body{
      margin-top: 0;
      padding-top: @modal-spacing;

      @media (max-width: @screen-sm-min) {
        padding-top: @modal-spacing-mobile;
      }
    }
  }

  &.cb-no-footer{
    .modal-body{
      margin-bottom: @modal-spacing;

      @media (max-width: @screen-sm-min) {
        margin-bottom: @modal-spacing-mobile;
      }
    }
  }

  button.close {
    width: 32px;
    height: 32px;
  }
}

.cb-modal-dialog{
  .modal-content{
    height: inherit;
  }
}

.modal {
  &.cb-modal-animation{
    .modal-dialog{
      animation: modalout .6s;
      -webkit-animation: modalout .6s;
    }
    &.fade .modal-dialog {
      -webkit-transition: none;
       -moz-transition: none;
         -o-transition: none;
            transition: none;
    }
    &.in .modal-dialog {
      -webkit-transition: none;
       -moz-transition: none;
         -o-transition: none;
            transition: none;
    }
    &.in .modal-dialog{
      animation: modalin .4s;
      -webkit-animation: modalin .4s;
    }
  }
}

.cb-alert-modal,
.alert-modal {
  .modal-body{
    margin: 0!important;
    padding: 0!important;
    .alert{
      margin: 0;
    }
  }
}

@keyframes modalin{
  from {
    -ms-transform: scale(0.8,0.8);
    transform: scale(0.8,0.8);
    opacity: 0;
  }
  to {
    -ms-transform: scale(1,1);
    transform: scale(1,1);
    opacity: 1;
  }
}
@keyframes modalout{
  from {
    -ms-transform: scale(1,1);
    transform: scale(1,1);
    opacity: 1;
  }
  to {
    -ms-transform: scale(0.3,0.3);
    transform: scale(0.3,0.3);
    opacity: 0;
  }
}

// FF issue focus on keyboard
[data-cb-input-m="keyboard"] {
  .modal {
    .modal-header {
      .close {
        &:focus{
          .cb-focus();
        }
      }
    }
  }
}