.table-vertical-align(@alignment) {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        .cell-vertical-align(@alignment);
      }
    }
  }
}

.cell-vertical-align(@alignment) {
  vertical-align: @alignment !important;
}
